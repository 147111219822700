import {Component, Input} from "@angular/core";
import {
  internalWaterfallOf,
  WaterfallInternal,
  WaterfallRecord
} from "@app/pages/dataset-details/components/waterfall-table/waterfall-table.model";

@Component({
  selector: 'app-waterfall-table',
  templateUrl: './waterfall-table.component.html',
  styleUrl: './waterfall-table.component.scss',
})
export class WaterfallTableComponent {
  private _start?: WaterfallRecord;
  private _end?: WaterfallRecord;
  private _split?: WaterfallRecord[];

  widthOfReferenceMax = 500;
  totalWidth: number = 0;

  internalWaterfall?: WaterfallInternal;

  @Input()
  showPercentages: boolean = false;

  @Input()
  set start(start: WaterfallRecord) {
    this._start = start;
    this.refresh();
  }

  @Input()
  set split(split: WaterfallRecord[]) {
    this._split = split;
    this.refresh();
  }

  @Input()
  set end(end: WaterfallRecord) {
    this._end = end;
    this.refresh();
  }

  private refresh(): void {
    if(this._start && this._end && this._split !== undefined) {
      this.internalWaterfall = internalWaterfallOf(this._start, this._split, this._end);
      this.totalWidth = this.widthOfReferenceMax * this.internalWaterfall.transitionMax / this.internalWaterfall.referenceMax;
    } else {
      this.internalWaterfall = undefined;
    }
  }




  protected readonly Math = Math;
}
