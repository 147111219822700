import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-chips-group',
  templateUrl: './chips-group.component.html',
  styleUrl: './chips-group.component.scss',
})
export class ChipsGroupComponent<T> {
  @Input()
  items!: Array<T>;

  @Input()
  groupName!: string;

  @Input()
  displayField!: keyof T;

  @Output()
  removed = new EventEmitter<T>();

  onRemoved(item: T): void {
    this.removed.emit(item);
  }
}
