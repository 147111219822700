<div class="sidebar">
  <div fxLayout="column" fxLayoutAlign="space-around stretch">
    <div *ngIf="geographyFacet !== undefined && geographyFacet.tree.length > 0">
      <mat-expansion-panel [expanded]="true" class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title><h3>Geography</h3></mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          <app-facet-tree [facet]="geographyFacet"
                          [selection]="searchRequest.filters.geographies"
                          facetName="geographies"
                          (changed)="selectionChange.emit($event)"
          ></app-facet-tree>
        </p>
      </mat-expansion-panel>
    </div>
    <div *ngIf="isicFacet !== undefined && isicFacet.tree.length > 0">
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title><h3>Economic Activity (ISIC)</h3></mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          <app-facet-tree [facet]="isicFacet"
                          [selection]="searchRequest.filters.isics"
                          facetName="isics"
                          (changed)="selectionChange.emit( $event)"
          ></app-facet-tree>
        </p>
      </mat-expansion-panel>
    </div>
    <div *ngIf="cpcFacet !== undefined && cpcFacet.tree.length > 0">
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title><h3>Product Category (CPC)</h3></mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          <app-facet-tree [facet]="cpcFacet"
                          [selection]="searchRequest.filters.cpcs"
                          facetName="cpcs"
                          (changed)="selectionChange.emit($event)"
          ></app-facet-tree>
        </p>
      </mat-expansion-panel>
    </div>
  </div>
</div>

