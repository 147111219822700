import {Method} from "@app/model/dataset/method.model";
import {patchState, signalStore, withMethods, withState} from "@ngrx/signals";
import {inject} from "@angular/core";
import {rxMethod} from "@ngrx/signals/rxjs-interop";
import {pipe, switchMap} from "rxjs";
import {tap} from "rxjs/operators";
import {ClassificationService} from "@app/modules/reference-data/classification/services/classification.service";
import {Classification} from "@app/modules/reference-data/classification/models/classification.model";

type DomainState = {
  methods: Array<Method>,
  activityCategories: {
    flat: Array<Classification>,
    labels: Map<string,string>
  }
}

const initialState: DomainState = {
  methods: [],
  activityCategories: {
    flat: [],
    labels: new Map()
  }
}

export const DomainStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((
    store,
    classificationService = inject(ClassificationService),

  ) =>
    ({
      loadActivityCategories: rxMethod<void>(
        pipe(switchMap(() => {
          return classificationService.findActivityCategoryClassifications().pipe(tap((flat) => {
            const update = {
              activityCategories: {
                flat: flat,
                labels: classificationService.labelMapOf(flat)
              }
            }
            patchState(store, update);
          }))})
        )
      ),
    }))
);


