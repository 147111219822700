<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="search-bar">
    <mat-form-field fxFlex class="search-field" color="primary">
      <mat-label>Search datasets</mat-label>
      <input formControlName="searchTerm" type="text" matInput (blur)="onSubmit()" />
      <button type="submit" matSuffix mat-icon-button aria-label="Search datasets">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <div fxLayoutAlign="start center">
      <mat-icon
        (click)="openSearchHelpDialog()"
        class="search-help"
        matTooltip="Search engine tips"
      >info_outline
      </mat-icon>
    </div>

    <div fxFlex fxLayoutAlign="end center">
      <mat-slide-toggle
        color="primary"
        class="show-details"
        [checked]="defaultShowResultsDetails"
        (change)="onShowDetailsChange($event)"
      >Show details
      </mat-slide-toggle>
    </div>
  </div>
</form>
