import {patchState, signalStore, withComputed, withMethods, withState} from '@ngrx/signals';
import {
  DatasetSearchRequest,
  DatasetSearchResponse,
  FacetTree, NO_FILTERS
} from "@app/model/dataset/dataset-search.model";
import {computed, inject} from "@angular/core";
import {
  BucketKey,
  CriteriaCategory,
  CRITERIA_CATEGORIES,
  DatasetSearchDropDowns, CriteriaValue
} from "@app/pages/datasets-search/datasets-search.model";
import {ChipGroup} from "@app/modules/ui/components/chips-container/chips-container.model";
import {DatasetSearchMapperService} from "@app/services/datasets/dataset-search-mapper.service";
import {DatasetDropdownService} from "@app/services/datasets/dataset-dropdown-service";
import {DatasetSearchService} from "@app/services/datasets/dataset-search.service";
import {rxMethod} from "@ngrx/signals/rxjs-interop";
import {map, pipe, switchMap} from "rxjs";
import {tap} from "rxjs/operators";
import {DatabaseService} from "@app/modules/reference-data/database/services/database.service";
import {ParamMap} from "@angular/router";
import {
  DatasetSearchFormMapperService
} from "@app/services/datasets/dataset-search-form/dataset-search-form-mapper.service";

type DatasetSearchState = {
  defaultSearchRequestReady: boolean,
  defaultSearchRequest: DatasetSearchRequest,
  searchRequest: DatasetSearchRequest,
  searchResponse: DatasetSearchResponse,
  responseLoading: boolean,
  facets: CriteriaCategory<FacetTree>,
  rawDropDowns: DatasetSearchDropDowns,
  labelMaps: CriteriaCategory<Map<string, string>>,
  dropDowns: DatasetSearchDropDowns,
  chipGroups: ChipGroup[]
};

const initialState: DatasetSearchState = {
  defaultSearchRequestReady: false,
  defaultSearchRequest: {
    searchTerm: '',
    filters: NO_FILTERS
  },
  searchRequest: {
    searchTerm: '',
    filters: NO_FILTERS
  },
  searchResponse: {
    results: [],
    facets: {},
    totalDocCount: 0,
    maxDocCount: 0,
  },
  responseLoading: false,
  rawDropDowns: {
    databases: [],
    geographies: [],
    activityTypes: [],
    units: [],
    isics: [],
    cpcs: []
  },
  labelMaps: {
    databases: new Map(),
    geographies: new Map(),
    activityTypes: new Map(),
    units: new Map(),
    isics: new Map(),
    cpcs: new Map()
  },
  dropDowns: {
    databases: [],
    geographies: [],
    activityTypes: [],
    units: [],
    isics: [],
    cpcs: []
  },
  facets: {
    databases: {
      key: BucketKey.DATABASE,
      tree: []
    },
    geographies: {
      key: BucketKey.GEOGRAPHY,
      tree: []
    },
    activityTypes: {
      key: BucketKey.ACTIVITY_TYPE,
      tree: []
    },
    units: {
      key: BucketKey.UNIT,
      tree: []
    },
    isics: {
      key: BucketKey.ISIC,
      tree: []
    },
    cpcs: {
      key: BucketKey.CPC,
      tree: []
    }
  },
  chipGroups: []
};

export const DatasetSearchStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(
    (
      store,
      datasetSearchMapperService=inject(DatasetSearchMapperService),
    ) => (
    {
      facets: computed(() => datasetSearchMapperService.enrichFacetsWithLabels(store.searchResponse().facets, store.rawDropDowns())),
      dropDowns: computed(() => datasetSearchMapperService.enrichDropdownsWithFacets(store.rawDropDowns(), store.searchResponse().facets)),
      chipGroups: computed(() => {
        const labelMaps = store.labelMaps();
        const searchRequest = store.searchRequest();
        const selectedGroups = CRITERIA_CATEGORIES.filter(criteria => searchRequest.filters[criteria].length > 0);
        return selectedGroups.map((criteria) => datasetSearchMapperService.makeChipGroup(criteria, searchRequest.filters[criteria], labelMaps[criteria]))
      })
    }
  )),
  withMethods(
    (
      store,
      dropdownService=inject(DatasetDropdownService),
      datasetSearchService=inject(DatasetSearchService),
      databaseService = inject(DatabaseService),
      datasetSearchMapperService=inject(DatasetSearchMapperService),
      datasetSearchFormMapperService = inject(DatasetSearchFormMapperService)
    ) => (
    {
      loadDefaultFilters: rxMethod<void>(
        pipe(switchMap(() =>
          databaseService.findDefaultDatabase().pipe(map(dbs => dbs.map(db => db.key)),tap((defaultDatabases) => {
            const currentDefaultSearchRequest = store.defaultSearchRequest();
            const defaultSearchRequest = {
              searchTerm: currentDefaultSearchRequest.searchTerm,
              filters: {
                ...currentDefaultSearchRequest.filters,
                databases: defaultDatabases.map(db => ({ param: "database", value: db} satisfies CriteriaValue))
              }
            };
            patchState(store, {
              defaultSearchRequestReady: true,
              defaultSearchRequest
            })
          })))
        )
      ),
      loadDropdowns: rxMethod<void>(
        pipe(switchMap(() =>
          dropdownService.getDropdowns().pipe(tap((rawDropDowns) => {
            const labelMaps = datasetSearchMapperService.makeLabelMaps(rawDropDowns);
            patchState(store, {rawDropDowns, labelMaps})
          })))
        )
      ),
      updateQuery: rxMethod<ParamMap>(
        pipe(
          tap((queryParams) => {
            const searchRequest = datasetSearchFormMapperService.fromQueryParams(queryParams)
            patchState(store,{searchRequest, responseLoading: true})
          }),
          switchMap((paramMap) => {
            return datasetSearchService.searchDatasets(paramMap).pipe(
              tap((searchResponse) => {
                patchState(store, {searchResponse, responseLoading: false})
              })
            );
          })
        )
      )
    }
  ))
);
