import {Injectable} from "@angular/core";
import {Dataset, ImpactAssessmentSample, ImpactIndicator} from "@app/model/dataset/dataset.model";
import {Compared, ContributionSplit, FilterType, ImpactSplit} from "@app/services/datasets/compare/compare.model";
import {Contribution, ContributionTypeName} from "@app/model/dataset/contribution.model";

@Injectable({
  providedIn: 'root',
})
export class CompareService {
  constructor() {
  }

  mapToCompared(dataset: Dataset, methodName: string, indicatorName: string, familyType : ContributionTypeName, filterType: FilterType): Compared | undefined {
    const assessmentGroups = dataset.referenceProduct.assessmentGroups.find(assessment => assessment.name === methodName);
    if(!assessmentGroups) {
      return undefined;
    }
    const contrib =
      assessmentGroups.contributions
      .find(contribution => contribution.indicator.name == indicatorName);
    if (!contrib) {
      return undefined;
    }

    return {
      reference: this.mapToDatasetReference(dataset),
      impact: this.mapContributionToImpactSplit(indicatorName, familyType, contrib, filterType)
    };
  }

  mapToImpactSplit(dataset: Dataset, indicatorName: string, familyType : ContributionTypeName, filterType: FilterType): ImpactSplit | undefined {

    const contrib = dataset.referenceProduct.assessmentGroups
      .flatMap(method => method.contributions)
      .find(contribution => contribution.indicator.name == indicatorName)
    ;
    if (!contrib) {
      return undefined;
    }

    return this.mapContributionToImpactSplit(indicatorName, familyType, contrib, filterType);
  }

  private mapContributionToImpactSplit(indicatorName: string, familyType : ContributionTypeName, contrib: Contribution, filterType: FilterType) {
    return {
      name: indicatorName,
      unit: contrib.indicator.unit,
      amount: contrib.amount,
      split: this.mapToContributionSplit(contrib, familyType, filterType)
    };
  }

  private mapToDatasetReference(dataset: Dataset) {
    return {
      id: dataset.id,
      name: dataset.referenceProduct.name,
      databaseName: dataset.description.database.name,
      databaseVersion: dataset.description.database.version
    };
  }

  private mapToContributionSplit(contrib: Contribution, familyType: ContributionTypeName, filterType : FilterType): ContributionSplit[] {
    const innerFamilies = contrib.innerFamilies
      .find(family => family.type === familyType);
    if(innerFamilies === undefined) {
      return [];
    }
    return innerFamilies
      .innerContributions
      .flatMap(c => this.filterContribution(c, filterType))
      .map(c => {
          return {
            name: c.name,
            amount: c.amount || 0
          }
        }
      );
  }

  private filterContribution(contribution : Contribution, filterType: FilterType): Contribution[] {
    switch (filterType) {
      case 'LEAVES': return this.getLeaves(contribution);
      case 'FIRST_LEVEL': return this.getFirstLevel(contribution);
    }
  }

  private getFirstLevel(contribution : Contribution): Contribution[] {
    return [contribution];
  }

  private getLeaves(contribution : Contribution): Contribution[] {
    const inner = contribution.innerContributions;
    if (inner.length > 0) {
      return inner.flatMap(c => this.getLeaves(c));
    }
    return [contribution];
  }

  public getMaxSampleAmount(dataset: Dataset): number {
    const relatedDatasets = dataset.history.equivalentVersions;
    relatedDatasets.concat(dataset.history.parents, dataset.history.children);
    return relatedDatasets.reduce((p, c) => Math.max(this.getAssessmentSampleAmount(c.impactIndicatorSamples), p), 0);
  }

  public getAssessmentSampleAmount(samples: ImpactAssessmentSample[]):number {
    return this.getAssessmentSample(samples)?.amount || 0;
  }

  public getAssessmentSample(samples: ImpactAssessmentSample[]):ImpactIndicator | undefined {
    return samples.find(() => true)?.indicator;
  }
}
