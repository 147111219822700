import { NgModule } from '@angular/core';
import {DatasetsTogglesComponent} from "@app/shared/datasets-toggles/datasets-toggles.component";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {FormsModule} from "@angular/forms";
import {AsyncPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {FlexLayoutModule, FlexModule} from "@ngbracket/ngx-layout";
import {AppComponent} from "@app/shared/app/app.component";
import {FooterComponent} from "@app/shared/footer/footer.component";
import {ToolbarComponent} from "@app/shared/toolbar/toolbar.component";
import {MatToolbar} from "@angular/material/toolbar";
import {MatIcon} from "@angular/material/icon";
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {MatAnchor, MatButton, MatMiniFabButton} from "@angular/material/button";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatBadge} from "@angular/material/badge";
import {MatTooltip} from "@angular/material/tooltip";
import {MatDivider} from "@angular/material/divider";
import {DatasetWarningComponent} from "@app/shared/dataset-warning/dataset-warning.component";
import {UiModule} from "@app/modules/ui/ui.module";
import {AnnouncementDialogComponent} from "@app/shared/announcement/announcement-dialog.component";
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {AnnouncementComponent} from "@app/shared/announcement/announcement.component";
import {AnnouncementMessageComponent} from "@app/shared/announcement/announcement-message.component";

@NgModule({
  imports: [
    MatRadioGroup,
    MatRadioButton,
    MatSlideToggle,
    FormsModule,
    NgIf,
    FlexModule,
    MatToolbar,
    MatIcon,
    AsyncPipe,
    RouterLink,
    MatButton,
    RouterLinkActive,
    MatAnchor,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatMiniFabButton,
    MatBadge,
    MatTooltip,
    MatDivider,
    RouterOutlet,
    NgClass,
    UiModule,
    NgForOf,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatDialogClose,
    FlexLayoutModule
  ],
  declarations: [
    AppComponent,
    FooterComponent,
    ToolbarComponent,
    DatasetsTogglesComponent,
    DatasetWarningComponent,
    AnnouncementMessageComponent,
    AnnouncementDialogComponent,
    AnnouncementComponent
  ],
  exports: [
    AppComponent,
    FooterComponent,
    ToolbarComponent,
    DatasetsTogglesComponent,
    DatasetWarningComponent,
    AnnouncementMessageComponent,
    AnnouncementDialogComponent,
    AnnouncementComponent
  ],
})
export class SharedModule {}
