import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {
  Classification,
  GroupedClassification,
} from '@app/modules/reference-data/classification/models/classification.model';
import {Dataset} from "@app/model/dataset/dataset.model";

@Component({
  selector: 'app-info-tab[dataset]',
  templateUrl: './info-tab.component.html',
  styleUrl: './info-tab.component.scss',
})
export class InfoTabComponent implements OnChanges {
  @Input()
  dataset!: Dataset;
  groupedClassifications: GroupedClassification[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if ('dataset' in changes) {
      this.groupedClassifications = this.getGroupedClassifications(
        this.dataset.description.classifications,
      );
    }
  }

  private getGroupedClassifications(
    classificationMap: Map<string,Classification[]> | undefined,
  ): GroupedClassification[] {
    if (classificationMap === undefined) {
      return [];
    }
    const groupedClassifications: GroupedClassification[] = [];

    for (const [system, classifications] of classificationMap) {
      groupedClassifications.push({
        system,
        values:
          classifications
            .map((c) => c.displayDescription)
            .sort()
            .join(', ') ?? '',
      });
    }

    return groupedClassifications.sort((a, b) =>
      a.system > b.system ? 1 : -1,
    );
  }
}
