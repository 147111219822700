import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ContributionTypeLabelPipe} from "@app/services/contribution/pipes/contribution-type-label.pipe";

@NgModule({
  declarations: [ContributionTypeLabelPipe],
  imports: [CommonModule],
  exports: [ContributionTypeLabelPipe],
})
export class ContributionModule {}
