<div class="header">
  <h2>Compare dataset emissions</h2>
  <mat-slide-toggle class="percentage"
                    color="primary"
                    [(ngModel)] ="showPercentages"
  >Show percentages
  </mat-slide-toggle>
</div>
<table class="other" *ngIf="_otherDataset as otherDataset">
  <tbody>
  <tr>
    <td class="label">Baseline</td>
    <td>
        {{ otherDataset.referenceProduct.name }}
    </td>
  </tr>
  <tr>
    <td class="label">Database</td>
    <td>
      {{ otherDataset.description.database.name }}
      {{ otherDataset.description.database.version }}
    </td>
  </tr>
  </tbody>
</table>

<div *ngIf="!endRecord">
  No {{ selectedIndicator }} contribution data is available for target dataset
</div>

<div *ngIf="!startRecord">
  No {{ selectedIndicator }} contribution data is available for current dataset
</div>

<app-waterfall-table *ngIf="startRecord && endRecord"
  [showPercentages]="showPercentages"
  [start]="startRecord"
  [split]="splitRecords"
  [end]="endRecord"
></app-waterfall-table>
