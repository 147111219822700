import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AbstractControl, NonNullableFormBuilder, ValidationErrors, ValidatorFn, Validators,} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {Database} from '@app/modules/reference-data/database/models/database.model';
import {DatabaseService} from '@app/modules/reference-data/database/services/database.service';
import {AppState} from '@app/store';
import {Store} from '@ngrx/store';
import {catchError, of, take} from 'rxjs';
import * as datasetActions from '@app/store/basket/basket-dataset.actions';
import {FieldDto} from '@app/api/__generated__/model/fieldDto';
import {RumService} from "@app/modules/rum/rum.service";
import {DatasetService} from "@app/services/datasets/dataset.service";

@Component({
  selector: 'app-basket-import-dialog',
  templateUrl: './basket-import-dialog.component.html',
  styleUrl: './basket-import-dialog.component.scss',
})
export class BasketImportDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<BasketImportDialogComponent>,
    private fb: NonNullableFormBuilder,
    private datasetService: DatasetService,
    private databaseService: DatabaseService,
    private store: Store<AppState>,
    private rumService: RumService,
  ) {
  }

  databaseValidator: ValidatorFn = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    if (control.get('listType')?.value === 'NAME') {
      return Validators.required(control.get('database')!);
    }
    return null;
  };

  dialogDataFormGroup = this.fb.group(
    {
      listType: this.fb.control('ID', [Validators.required]),
      list: this.fb.control('', [Validators.required]),
      database: this.fb.control(''),
    },
    {validators: this.databaseValidator},
  );

  isFetchingDatasets = false;

  databases: Database[] = [];

  returnedError = false;

  ngOnInit(): void {
    this.databaseService
      .findSortedDatabases()
      .pipe(take(1))
      .subscribe((db) => (this.databases = db));
  }

  onImport(): void {
    const elements = this.dialogDataFormGroup.value.list?.split('\n').filter(e => e.trim().length > 0) ?? [];
    const ids =
      this.dialogDataFormGroup.value.listType === 'ID' ? elements : undefined;
    const names =
      this.dialogDataFormGroup.value.listType === 'NAME' ? elements : undefined;
    const database =
      this.dialogDataFormGroup.value.listType === 'NAME'
        ? this.dialogDataFormGroup.value.database
        : undefined;
    this.isFetchingDatasets = true;
    this.dialogDataFormGroup.disable();
    this.datasetService
      .getDatasets(ids, names, database, [
        FieldDto.Description,
        FieldDto.Impacts,
      ])
      .pipe(
        take(1),
        catchError((err: HttpErrorResponse) => {
          this.returnedError = true;
          this.dialogDataFormGroup.enable();
          if (err.status === 400) {
            this.dialogDataFormGroup.controls.list.setErrors({
              wrongInput: true,
            });
          } else {
            this.dialogDataFormGroup.controls.list.setErrors({
              otherError: true,
            });
          }
          return of([]);
        }),
      )
      .subscribe((datasets) => {
        this.isFetchingDatasets = false;
        if (this.returnedError) {
          this.returnedError = false;
        } else if (datasets.length === 0) {
          this.dialogDataFormGroup.enable();
          this.dialogDataFormGroup.controls.list.setErrors({
            noResult: true,
          });
        } else {
          this.rumService.triggerBasketImportEvent(datasets);
          this.store.dispatch(datasetActions.addAllDatasets({datasets}));
          this.dialogRef.close(true);
        }
      });
  }
}
