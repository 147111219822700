import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingPageComponent} from "@app/pages/landing-page/pages/landing-page/landing-page.component";
import {FirstLoginPageComponent} from "@app/pages/landing-page/pages/first-login-page/first-login-page.component";
import {AccessDeniedComponent} from "@app/pages/landing-page/pages/access-denied.component";
import {GetCustomComponent} from "@app/pages/landing-page/pages/get-custom/get-custom.component";
import {getCustomGuard} from "@app/pages/landing-page/guards/get-custom.guard";
import {Auth0Guard} from "@app/modules/auth/components/guard/auth0.guard";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LandingPageComponent,
    data: {
      showBasket: false,
      menuButtons: [],
    },
    canActivate: [Auth0Guard],
  },
  {
    path: 'first-login',
    pathMatch: 'full',
    component: FirstLoginPageComponent,
    data: {
      showBasket: false,
      menuButtons: [],
    },
    canActivate: [],
  },
  {
    path: 'access-denied',
    pathMatch: 'full',
    component: AccessDeniedComponent,
    data: {
      showBasket: false,
      menuButtons: [],
    },
    canActivate: [],
  },
  {
    path: 'get-custom',
    pathMatch: 'full',
    component: GetCustomComponent,
    data: {
      showBasket: false,
      menuButtons: [],
    },
    canActivate: [getCustomGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class LandingPageRoutingModule {}
