import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss',
})
export class ProgressBarComponent {
  progressWidth: string = "0%";
  offsetWidth: string | undefined;

  @Input()
  set offset(ratio: number) {
    this.offsetWidth = ratio * 100 + '%';
  }


  @Input()
  set progress(ratio: number) {
    this.progressWidth = ratio * 100 + '%';
  }
}
