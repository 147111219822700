import {Injectable} from '@angular/core';
import {MethodIndicator} from "@app/model/dataset/method.model";
import {
  Contribution,
  contributionsTreeLeaves,
  ContributionTypeName
} from "@app/model/dataset/contribution.model";

@Injectable({
  providedIn: 'root',
})
export class ContributionService {


  findContribution(
    contributions: Contribution[],
    indicator: MethodIndicator,
    name: string
  ): Contribution | undefined {
    contributions = this.findContributions(contributions, indicator.id, { name });
    return contributions.length > 0 ? contributions[0] : undefined;
  }

  findContributions(
    contributions: Contribution[],
    indicatorId: string,
    options: {
      type?: ContributionTypeName;
      name?: string;
    } = {}
  ): Contribution[] {
    contributions = contributions.filter(
      (c) => c.indicator.id === indicatorId
    );
    if (options.type !== undefined) {
      contributions = contributions.filter((c) => c.type === options.type);
    }
    if (options.name !== undefined) {
      contributions = contributions.filter((c) => c.name === options.name);
    }
    return contributions;
  }

  totalContributions(
    contributions: Contribution[],
    indicator: MethodIndicator,
    type: ContributionTypeName
  ): Array<Contribution> {
    return (contributionsTreeLeaves(type) ?? []).map((contributionNode) => {
      const foundContributions = this.findContributions(
        contributions,
        indicator.id,
        { type, name: contributionNode.name }
      );
      return {
        ...contributionNode,
        indicator,
        amount: this.sumContributions(foundContributions),
      };
    });
  }


  private sumContributions(contributions: Contribution[]): number | undefined {
    if (contributions.length === 0) {
      return undefined;
    }
    return contributions
      .map((c) => c.amount)
      .reduce(
        (a, b) => (a !== undefined && b !== undefined ? a + b : undefined),
        0
      );
  }

}
