<table>
  <colgroup>
    <col />
    <col [style.width]="'calc('+ totalWidth + 'px + 5em)'" />
  </colgroup>
  <tbody *ngIf="internalWaterfall as w">
    <tr>
      <th><span>{{ w.start.label }}</span></th>
      <td class="reference">
        <div [style.width]="widthOfReferenceMax + 'px'">
          <app-progress-bar
            [progress]="w.start.ratio"
            [offset]="w.start.offsetRatio"
          >
            <span>{{ w.start.absolute | number }}</span>
            <span *ngIf="showPercentages">
                ({{ Math.round(w.start.percentage) }} %)
            </span>
          </app-progress-bar>
        </div>
      </td>
      <td class="label"></td>
    </tr>

    <tr *ngFor="let record of w.split" [class]="record.changeType">
      <th></th>
      <td [class]="record.changeType"
          >
        <div [style.width]="widthOfReferenceMax + 'px'">
          <app-progress-bar
            [progress]="record.ratio"
            [offset]="record.offsetRatio"
          >
            <span *ngIf="showPercentages">
               <ng-container *ngIf="record.absolute > 0">+</ng-container>{{ Math.round(record.percentage) }} %
            </span>
            <span *ngIf="!showPercentages">
                <ng-container *ngIf="record.absolute > 0">+</ng-container>{{ record.absolute | displayAmount }}
            </span>
          </app-progress-bar>
        </div>
      </td>
      <td><span>{{ record.label }}</span></td>
    </tr>

    <tr>
      <th><span>{{ w.end.label }}</span></th>
      <td class="reference">
        <div [style.width]="widthOfReferenceMax + 'px'">
          <app-progress-bar
            [progress]="w.end.ratio"
            [offset]="w.end.offsetRatio"
          >
            <span>{{ w.end.absolute | displayAmount }}</span>
            <span *ngIf="!showPercentages">
              (<ng-container *ngIf="w.end.absolute - w.start.absolute > 0">+</ng-container>{{ w.end.absolute - w.start.absolute | displayAmount }})
            </span>
            <span *ngIf="showPercentages">
                ({{ w.endRelativeDifference }})
            </span>
          </app-progress-bar>
        </div>
      </td>
      <td class="label"></td>
    </tr>

  </tbody>
</table>
