import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {FormControl, UntypedFormControl} from "@angular/forms";
import {BehaviorSubject, combineLatest, map, Observable, Subject} from "rxjs";
import {DropDownMenuGroup, DropDownMenuItem} from "@app/modules/ui/components/drop-down-menu/drop-down-menu.model";
import {takeUntil} from "rxjs/operators";
import {MatFormFieldAppearance} from "@angular/material/form-field";

@Component({
  selector:
    'app-drop-down-menu',
  templateUrl: './drop-down-menu.component.html',
  styleUrl: './drop-down-menu.component.scss',
})
export class DropDownMenuComponent implements OnInit, OnDestroy {
  @Input()
  label!: string;

  @Input()
  multiple: boolean = true;

  @Input()
  set menuItems(newItems: DropDownMenuItem[]) {
    this.menuItems$.next(newItems)
  }

  @Input()
  set selection(newSelection: string[] | string) {
    this.selectControl.setValue(newSelection);
  }

  @Input()
  groups?: DropDownMenuGroup[];

  @Output()
  changed = new EventEmitter<string[]>;

  @Input()
  appearance?: MatFormFieldAppearance;

  menuItems$: BehaviorSubject<DropDownMenuItem[]> = new BehaviorSubject([] as DropDownMenuItem[]);
  userSearch$: BehaviorSubject<string> = new BehaviorSubject("");

  onDestroy$ = new Subject<void>();
  filteredGroups$: Observable<DropDownMenuGroupInternal[]> = new Subject();

  searchControl: FormControl = new UntypedFormControl();
  selectControl: FormControl = new UntypedFormControl();

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(search => this.userSearch$.next(search as string));
    this.filteredGroups$ = combineLatest([this.menuItems$, this.userSearch$])
      .pipe(
        takeUntil(this.onDestroy$),
        map(([menuItems, userSearch]) => {
          const filteredItems = menuItems
            .filter(item => matches(item.search, userSearch as string | undefined));
          return groupItems(this.groups, filteredItems);
        })
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onOpenChange():void {
      this.changed.emit(this.selectControl.value as string[]);
  }
}

function groupItems(groups: DropDownMenuGroup[] | undefined, menuItems: DropDownMenuItem[]): DropDownMenuGroupInternal[] {
  if(groups) {
    return groups.map(g => { return {
      ...g,
      items: menuItems.filter(i => i.group == g.key)
    }}).filter(g => g.items.length > 0)
  } else {
    return [ { items: menuItems } ]
  }
}

function matches(value:string, search: string | undefined):boolean {
  if(!search) {
    return true; // Match everything if no search
  }
  const lvalue = value.toLowerCase();
  const lsearch = search.toLowerCase();
  return lvalue.includes(lsearch);
}

interface DropDownMenuGroupInternal {
  key?: string,
  label?: string,
  items: DropDownMenuItem[]
}
