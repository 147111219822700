import { ParentDto } from '@app/api/__generated__/model/parentDto';
import {Parent} from "@app/model/dataset/parent.model";

export class ParentMapperService {
  from(parentDtos: Array<ParentDto>): Array<Parent> {
    const parents: Array<Parent> = new Array<Parent>();

    const sortParent = (a: ParentDto, b: ParentDto) => {
      return a.name.localeCompare(b.name);
    };

    parentDtos.sort(sortParent).forEach((dto) => {
      parents.push(this.mapParent(dto));
    });

    return parents;
  }

  mapParent(dto: ParentDto): Parent {
    return {
      datasetId: dto.datasetId ,
      name: dto.name,
      navigable: dto.navigable,
    } as Parent;
  }
}
