import {Component, inject} from '@angular/core';
import { PageComponent } from '@app/modules/core/components/page-component';
import {DatasetSearchStore} from "@app/store/search.store";

@Component({
  templateUrl: './landing-page.component.html',
})
export class LandingPageComponent implements PageComponent {
  readonly pageName = 'landing-page';
  readonly store = inject(DatasetSearchStore);

  constructor() {
    this.store.loadDefaultFilters();
    this.store.loadDropdowns();
  }
}
