export type WaterfallRecord = {
  label: string,
  amount: number
}

export type WaterfallInternalRecord = {
  label: string,
  absolute: number,
  percentage: number,
  ratio: number,
  offsetRatio: number,
  changeType: string
}

export type WaterfallInternal = {
  start: WaterfallInternalRecord;
  split: WaterfallInternalRecord[];
  end: WaterfallInternalRecord;
  referenceMax: number;
  transitionMax: number;
  endRelativeDifference: string;
}

export function internalWaterfallOf(start: WaterfallRecord, split: WaterfallRecord[], end: WaterfallRecord): WaterfallInternal {
    const referenceMax = Math.max(start.amount, end.amount);

    let transitionMax = referenceMax;
    const baseline = start.amount;
    const startRecord = internalRecordOf(start, baseline, referenceMax, 0);
    const splitRecords = [];
    let currentOffset = start.amount;
    for(const s of split) {
      const newOffset = currentOffset + s.amount;
      transitionMax = Math.max(transitionMax, currentOffset);
      const splitRecord = internalRecordOf(s, baseline, referenceMax, s.amount < 0 ? newOffset : currentOffset);
      splitRecords.push(splitRecord);
      currentOffset = newOffset;
    }
    const endRecord = internalRecordOf(end, baseline, referenceMax, 0);

    return {
      start: startRecord,
      split: splitRecords,
      end: endRecord,
      referenceMax: referenceMax,
      transitionMax: transitionMax,
      endRelativeDifference: endRelativeDifference(endRecord)
    };
}

function endRelativeDifference(endRecord: WaterfallInternalRecord): string {
  const relativePercentage = endRecord.percentage  - 100;
  const stringPercentage = relativePercentage.toFixed(0) + "%";
  return (relativePercentage > 0) ? "+" + stringPercentage : "" + stringPercentage;
}

function internalRecordOf(absoluteRecord: WaterfallRecord, baseline: number, referenceMax: number, offset: number): WaterfallInternalRecord {
  const ratio = Math.abs(absoluteRecord.amount) / referenceMax;
  const percentage = 100 * absoluteRecord.amount / baseline;
  let changeType:string;
  if (Math.abs(percentage) < 0.5) {
    changeType = 'small-value';
  } else {
    changeType = absoluteRecord.amount > 0 ? 'regression' : 'improvement';
  }
  return {
    label: absoluteRecord.label,
    absolute: absoluteRecord.amount,
    percentage: 100 * absoluteRecord.amount / baseline,
    ratio: ratio,
    offsetRatio: offset / referenceMax,
    changeType: changeType,
  };
}


