<mat-form-field class="select-autocomplete" [appearance]="appearance || 'outline'">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [multiple]="multiple"
    [formControl]="selectControl"
    panelClass="select-panel-width-fix"
    (openedChange)="onOpenChange()"
  >
    <mat-option>
      <ngx-mat-select-search
        [formControl]="searchControl"
        placeholderLabel="Search"
        noEntriesFoundLabel="No results"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-optgroup [class]="groups ? '': 'drop-down-hidden-group'" *ngFor="let group of (filteredGroups$ | async) " [label]="group.label || ''">
      <mat-option *ngFor="let item of group.items"
          [value]="item.value"
          [style]="item.style"
        >{{ item.label + (item.count ? " ("+item.count+")":"") }}
        </mat-option>
      </mat-optgroup>
  </mat-select>
</mat-form-field>
