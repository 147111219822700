import { CompartmentDto } from '@app/api/__generated__/model/compartmentDto';
import { SubCompartmentDto } from '@app/api/__generated__/model/subCompartmentDto';
import { COMPARTMENTS } from './compartment.model';
import {FilterOption} from "@app/modules/ui/legacy/filters-group/filters-group.model";

export class HierarchicCompartment {
  compartment: CompartmentDto;
  subCompartments: SubCompartmentDto[];

  constructor(
    compartment: CompartmentDto,
    subCompartments: SubCompartmentDto[]
  ) {
    this.compartment = compartment;
    this.subCompartments = subCompartments;
  }

  get getFilterOption(): FilterOption {
    return {
      key: this.compartment,
      value: COMPARTMENTS.get(this.compartment) ?? this.compartment,
    };
  }
}
export const getDistinctSubCompartmentsFromHierarchicCompartments = (
  hc: HierarchicCompartment[]
): SubCompartmentDto[] => [...new Set(hc.flatMap((hc) => hc.subCompartments))];
