import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {LandingPageComponent} from './pages/landing-page/landing-page.component';
import {MaterialModule} from '@app/modules/material/material.module';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  LandingPageModelSectionComponent
} from './components/landing-page-model-section/landing-page-model-section.component';
import {
  DatabaseFilterComponent
} from './components/landing-page-search-section/database-filter/database-filter.component';
import {FilterComponent} from './components/landing-page-search-section/filter/filter.component';
import {
  LandingPageWhatsNewSectionComponent
} from './components/landing-page-whats-new-section/landing-page-whats-new-section.component';
import {
  LandingPageAboutSectionComponent
} from './components/landing-page-about-section/landing-page-about-section.component';
import {UiModule} from '@app/modules/ui/ui.module';
import {
  LandingPageSectionContentTwoColumnsComponent
} from './components/landing-page-section-content-two-columns.component';
import {AccessDeniedComponent} from "@app/pages/landing-page/pages/access-denied.component";
import {FirstLoginPageComponent} from "@app/pages/landing-page/pages/first-login-page/first-login-page.component";
import {
  LandingPageSearchSectionComponent
} from "@app/pages/landing-page/components/landing-page-search-section/landing-page-search-section.component";
import {LandingPageSectionComponent} from "@app/pages/landing-page/components/landing-page-section.component";
import {
  GeographyFilterComponent
} from "@app/pages/landing-page/components/landing-page-search-section/geography-filter/geography-filter.component";
import {
  LandingPageSectionContentComponent
} from "@app/pages/landing-page/components/landing-page-section-content.component";
import {GetCustomComponent} from "@app/pages/landing-page/pages/get-custom/get-custom.component";
import {LandingPageRoutingModule} from "@app/pages/landing-page/landing-page-routing.module";
import {PagesModule} from "@app/pages/pages.module";

@NgModule({
  declarations: [
    LandingPageComponent,
    AccessDeniedComponent,
    FirstLoginPageComponent,
    LandingPageSearchSectionComponent,
    LandingPageSectionComponent,
    LandingPageModelSectionComponent,
    DatabaseFilterComponent,
    GeographyFilterComponent,
    FilterComponent,
    LandingPageWhatsNewSectionComponent,
    LandingPageAboutSectionComponent,
    LandingPageSectionContentComponent,
    LandingPageSectionContentTwoColumnsComponent,
    GetCustomComponent,
  ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgOptimizedImage,
        LandingPageRoutingModule,
        UiModule,
        PagesModule,
    ],
})
export class LandingPageModule {}
