import {inject, NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {environment} from "@env/environment";
import {
  CharacterizationFactorsSearchComponent
} from "@app/pages/characterization-factors/characterization-factors-search.component";
import {DatasetsSearchComponent} from "@app/pages/datasets-search/datasets-search.component";
import {ViewBasketComponent} from "@app/pages/view-basket/view-basket.component";
import {DatasetDetailComponent} from "@app/pages/dataset-details/dataset-detail.component";
import {Auth0Guard} from "@app/modules/auth/components/guard/auth0.guard";
import {DomainStore} from "@app/store/domain.store";

const routes: Routes = [
  {
    path: 'characterization-factors',
    component: CharacterizationFactorsSearchComponent,
    data: {
      showBasket: false,
      menuButtons: environment.defaultRoutes,
    },
    canActivate: [Auth0Guard],
  },

  {
    path: 'datasets',
    component: DatasetsSearchComponent,
    data: {
      showBasket: true,
      menuButtons: environment.defaultRoutes
    },
    canActivate: [Auth0Guard],
  },
  {
    path: 'datasets/:datasetId',
    component: DatasetDetailComponent,
    data: {
      showBasket: true,
      menuButtons: environment.defaultRoutes,
    },
    canActivate: [Auth0Guard],
  },
  {
    path: 'dataset/:datasetId',
    redirectTo: 'datasets/:datasetId',
  },

  {
    path: 'basket',
    component: ViewBasketComponent,
    data: {
      showBasket: true,
      menuButtons: environment.defaultRoutes,
    },
    canActivate: [Auth0Guard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class PagesRoutingModule {
  readonly domainStore = inject(DomainStore);

  constructor() {
    this.domainStore.loadActivityCategories();
  }
}
