import { Pipe, PipeTransform } from '@angular/core';
import { SubCompartmentDto } from '@app/api/__generated__/model/subCompartmentDto';
import { SUB_COMPARTMENTS } from '@app/modules/reference-data/substance/substance/models/sub-compartment.model';

@Pipe({
  name: 'subCompartmentLabel',
})
export class SubCompartmentLabelPipe implements PipeTransform {
  transform(value: SubCompartmentDto | undefined): string {
    return value !== undefined ? SUB_COMPARTMENTS.get(value) ?? value : '';
  }
}
