import { createAction, props } from '@ngrx/store';
import { BasketExportOptions } from '@app/model/basket/basket.model';
import {DatasetSearchResult} from "@app/model/dataset/dataset-search.model";

export const addDataset = createAction(
  '[Basket] Add dataset',
  props<{ dataset: DatasetSearchResult }>()
);

export const addAllDatasets = createAction(
  '[Basket] Add all datasets',
  props<{ datasets: DatasetSearchResult[] }>()
);

export const removeDataset = createAction(
  '[Basket] Remove dataset',
  props<{ dataset: DatasetSearchResult }>()
);

export const removeAllDatasets = createAction(
  '[Basket] Remove all datasest',
  props<{ datasets: DatasetSearchResult[] }>()
);

export const emptyBasket = createAction('[Basket] Empty basket');

export const exportBasket = createAction(
  '[Basket] Export basket',
  props<{ options: BasketExportOptions }>()
);
