import { Injectable } from '@angular/core';
import { ContributionDto } from '@app/api/__generated__/model/contributionDto';
import { AbstractMapper } from '@app/modules/core/mappers/abstract-mapper';
import { MethodMapperService } from '@app/modules/reference-data/method/mappers/method-mapper.service';

import { ContributionFamilyDto } from '@app/api/__generated__/model/contributionFamilyDto';
import {Contribution, ContributionFamily, ContributionTypeName} from "@app/model/dataset/contribution.model";


@Injectable({
  providedIn: 'root',
})
export class ContributionMapperService extends AbstractMapper<
  ContributionDto,
  Contribution
> {
  constructor(private readonly methodMapper: MethodMapperService) {
    super();
  }

  from(dto: ContributionDto): Contribution {
    return {
      name: dto.name,
      displayName: dto.displayName,
      type: dto.type as ContributionTypeName,
      amount: dto.amount,
      indicator: this.methodMapper.mapIndicator(dto.indicator, dto.indicator.method),
      innerContributions: this.fromList(dto.innerContributions),
      innerFamilies: this.fromInnerFamilies(dto.innerFamilies),
    };
  }

  /**
   * @deprecated exist until we re-factorize exchange tab
   */
  flattenFromList(dtoList: ContributionDto[] | undefined): Contribution[] {
    if (dtoList === undefined) {
      throw new Error('dtoList is undefined');
    }
    return dtoList
      .map((c) => this.flattenContribution(c))
      .flatMap((dto) => this.fromList(dto));
  }

  private flattenContribution(c: ContributionDto): ContributionDto[] {
    return [
      c,
      ...this.flattenInnerContributions(c.innerContributions),
      ...c.innerFamilies.flatMap((cf) =>
        this.flattenInnerContributions(cf.innerContributions)
      ),
    ];
  }

  private flattenInnerContributions(innerContributions: ContributionDto[]) {
    return innerContributions.flatMap((dto) => this.flattenContribution(dto));
  }

  private fromInnerFamilies(
    innerFamilies: ContributionFamilyDto[] | undefined
  ): ContributionFamily[] {
    if (innerFamilies == undefined) {
      return [];
    }
    return innerFamilies.map((cf) => ({
      type: cf.type as ContributionTypeName,
      innerContributions: this.fromList(cf.innerContributions),
    }));
  }
}
