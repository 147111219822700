import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener,} from '@angular/material/tree';
import {FacetItemFlatNode, FacetItemNode, FacetTreeState} from "@app/modules/ui/components/facet-tree/facet-tree.model";
import {FacetTree} from "@app/model/dataset/dataset-search.model";
import {RumService} from "@app/modules/rum/rum.service";
import {
  criteriaKey,
  CriteriaName,
  CriteriaValue,
  FilterSelectionChange
} from "@app/pages/datasets-search/datasets-search.model";

/**
 * @title Tree with checkboxes
 */
@Component({
  selector: 'app-facet-tree',
  templateUrl: './facet-tree.component.html',
  styleUrl: './facet-tree.component.scss'
})
export class FacetTreeComponent {
  private _facetName: CriteriaName | undefined = undefined;
  private facetTreeState = new FacetTreeState();

  @Input()
  set facet(facet: FacetTree) {
    this.dataSource.data = facet.tree
    this.facetTreeState.setFacet(facet);
  }

  @Input()
  set selection(values: CriteriaValue[]) {
    this.facetTreeState.setSelection(values);
  }

  @Input()
  set facetName(facetName: CriteriaName) {
    this._facetName = facetName;
  }

  @Output()
  changed = new EventEmitter<FilterSelectionChange>

  valueNodeMap = new Map<string, FacetItemFlatNode>();

  treeControl: FlatTreeControl<FacetItemFlatNode>;
  treeFlattener: MatTreeFlattener<FacetItemNode, FacetItemFlatNode>;
  dataSource: MatTreeFlatDataSource<FacetItemNode, FacetItemFlatNode>;

  constructor(private rumService: RumService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<FacetItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
  }

  getLevel: (node: FacetItemFlatNode) => number = (node: FacetItemFlatNode) => {
    return node.level;
  };

  isExpandable: (node: FacetItemFlatNode) => boolean = (node: FacetItemFlatNode) => {
    return node.expandable;
  };

  getChildren = (node: FacetItemNode): FacetItemNode[] => {
    return node.children;
  };

  transformer: (node: FacetItemNode, level: number) => FacetItemFlatNode = (node: FacetItemNode, level: number) => {
    const flatNode: Partial<FacetItemFlatNode> =
      this.valueNodeMap.has(criteriaKey(node.item))
        ? this.valueNodeMap.get(criteriaKey(node.item))!
        : {};
    flatNode.item = node.item;
    flatNode.label = node.label;
    flatNode.percent = node.percent;
    flatNode.count = node.count;
    flatNode.level = level;
    flatNode.expandable = node.children.length > 0;
    const completeFlatNode = flatNode as FacetItemFlatNode;
    this.valueNodeMap.set(criteriaKey(node.item), completeFlatNode);
    return completeFlatNode;
  };

  isPartiallySelected(node: FacetItemFlatNode): boolean {
    return this.facetTreeState.isPartiallySelected(node.item);
  }

  onCheckToggle(node: FacetItemFlatNode): void {
    if (this._facetName) {
      const updatedSelection = this.facetTreeState.toggleItem(node.item);
      const selectionChange = {field: this._facetName, selection: updatedSelection} satisfies  FilterSelectionChange;
      this.rumService.triggerFacetUseEvent(selectionChange);
      this.changed.emit(selectionChange);
    }
  }

  isSelected(node: FacetItemFlatNode): boolean {
    return this.facetTreeState.isSelected(node.item);
  }
}
