import {CommonModule, DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '@app/modules/material/material.module';
import {ReferenceDataModule} from '@app/modules/reference-data/reference-data.module';
import {UiModule} from '@app/modules/ui/ui.module';
import {
  ContributionValueComponent
} from './components/exchange-tab/exchange-table/contribution-value/contribution-value.component';
import {
  ExchangeDetailsSubtotalComponent
} from './components/exchange-tab/exchange-table/exchange-details-subtotal/exchange-details-subtotal.component';
import {
  ExchangeCommentComponent
} from './components/exchange-tab/exchange-table/exchange-comment/exchange-comment.component';
import {
  ExchangeCommentToggleComponent
} from './components/exchange-tab/exchange-table/exchange-comment-toggle/exchange-comment-toggle.component';
import {
  ExchangeLabelWarningComponent
} from './components/exchange-tab/exchange-table/exchange-label-warning/exchange-label-warning.component';
import {
  ExchangeWarningComponent
} from './components/exchange-tab/exchange-table/exchange-warning/exchange-warning.component';
import {NgStringPipesModule} from 'ngx-pipes';
import {FlexModule} from "@ngbracket/ngx-layout";
import {SharedModule} from "@app/shared/shared.module";
import {
  ExchangeLineHeaderComponent
} from "@app/pages/dataset-details/exchange/components/exchange-line-header/exchange-line-header.component";
import {
  ExchangeLineRecordComponent
} from "@app/pages/dataset-details/exchange/components/exchange-line-record/exchange-line-record.component";
import {ExchangeTabComponent} from "@app/pages/dataset-details/exchange/components/exchange-tab/exchange-tab.component";
import {
  ExchangeTableComponent
} from "@app/pages/dataset-details/exchange/components/exchange-tab/exchange-table/exchange-table.component";
import {
  ContributionNameFilterComponent
} from "@app/pages/dataset-details/exchange/components/exchange-tab/contribution-name-filter/contribution-name-filter.component";
import {
  GroupFilterComponent
} from "@app/pages/dataset-details/exchange/components/exchange-tab/group-filter/group-filter.component";
import {
  IndicatorFilterComponent
} from "@app/pages/dataset-details/exchange/components/exchange-tab/indicator-filter/indicator-filter.component";
import {
  ContributionTypeFilterComponent
} from "@app/pages/dataset-details/exchange/components/exchange-tab/contribution-type-filter/contribution-type-filter.component";
import {
  ContributionFiltersComponent
} from "@app/pages/dataset-details/exchange/components/exchange-tab/contribution-filters/contribution-filters.component";
import {SankeyModule} from "@app/pages/dataset-details/exchange/modules/sankey/sankey.module";
import {ContributionModule} from "@app/services/contribution/contribution.module";
import {ExchangeMapperService} from "@app/pages/dataset-details/exchange/mappers/exchange-mapper.service";


@NgModule({
  declarations: [
    ExchangeLineHeaderComponent,
    ExchangeLineRecordComponent,
    ExchangeTabComponent,
    ExchangeTableComponent,
    ContributionValueComponent,
    ExchangeDetailsSubtotalComponent,
    ExchangeCommentComponent,
    ExchangeCommentToggleComponent,
    ExchangeLabelWarningComponent,
    ExchangeWarningComponent,
    ContributionNameFilterComponent,
    GroupFilterComponent,
    IndicatorFilterComponent,
    ContributionTypeFilterComponent,
    ContributionFiltersComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ReferenceDataModule,
    SankeyModule,
    UiModule,
    NgStringPipesModule,
    ReactiveFormsModule,
    ContributionModule,
    FlexModule,
    FormsModule,
    SharedModule
  ],
  providers: [ExchangeMapperService, DecimalPipe],
  exports: [ExchangeTabComponent, ContributionValueComponent, ExchangeLineHeaderComponent, ExchangeLineRecordComponent, ExchangeDetailsSubtotalComponent],
})
export class ExchangeModule {}
