import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/modules/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import {SelectMultipleComponent} from "@app/modules/ui/legacy/select/select-multiple.component";
import {SelectSearchComponent} from "@app/modules/ui/legacy/select/select-search.component";

@NgModule({
  declarations: [SelectMultipleComponent, SelectSearchComponent],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule],
  exports: [SelectSearchComponent, SelectMultipleComponent],
})
export class SelectModule {}
