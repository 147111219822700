<ng-container *ngFor="let record of records">
  <tr>
    <td *ngIf="!record.notDataset" class="label" colspan="2">
      <app-copiable-id
        size="small"
        [copiableId]="record.id"
        [copiableName]="record.name"
        tooltipPosition="above"
      ><a
        *ngIf="record.id && record.navigable"
        routerLinkActive="active"
        [routerLink]="['/datasets', record.id]"
      >{{ record.name }}</a>
        <ng-template *ngIf="record.id && !record.navigable"
        >{{ record.name }} (no background data available)
        </ng-template>
      </app-copiable-id>
      <app-exchange-label-warning
        [warnings]="record.warnings"
      ></app-exchange-label-warning>
    </td>
    <td *ngIf="record.notDataset" class="label">
      <app-copiable-id
        size="small"
        [copiableId]="record.id"
        tooltipPosition="right"
      >{{ record.name }}
      </app-copiable-id>
    </td>
    <td *ngIf="record.compartment !== undefined">
      {{ record.compartment | compartmentLabel }}
    </td>
    <td *ngIf="record.subCompartment !== undefined">
      {{ record.subCompartment | subCompartmentLabel }}
    </td>
    <td [class.greyed-info]="options.isGreyed" *ngIf="record.allocation || record.allocation === 0">
      {{ record.allocation | number : "1.1-2" }}%
    </td>
    <td *ngIf="!record.allocation && !record.notDataset && record.allocation !== 0">
    </td>
    <td [class.greyed-info]="options.isGreyed" >{{ record.amount | displayAmount }}</td>
    <td [class.greyed-info]="options.isGreyed" >{{ record.unit | formatUnit }}</td>
    <ng-container *ngIf="getDisplayedAssessment(record).length > 0; else emptyAssessment">
      <td
        *ngFor="let assessment of getDisplayedAssessment(record)"
        [ngClass]="['contribution']"
      >
        <app-contribution-value
          [amount]="assessment.amount"
          [unit]="options.unit"
          [referenceAmount]="options.totalAssessment"
          [showPercentages]="options.showPercentages"
          [showBar]="record.showBar"
        ></app-contribution-value>
      </td>
    </ng-container>

    <ng-template #emptyAssessment>
      <td></td>
    </ng-template>
    <td
      exchangeCommentToggle
      [id]="record.id"
      [comment]="record.comment"
      [isExpanded]="options.expandedCommentId === record.id"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></td>
    <td class="warn">
      <app-exchange-warning
        [warnings]="record.warnings"
      ></app-exchange-warning>
    </td>
  </tr>
  <tr
    *ngIf="options.expandedCommentId === record.id"
    exchangeComment
    [comment]="record.comment"
    [totalNbColumns]="2"
  ></tr>
</ng-container>
