<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node
    fxLayout="row"
    fxLayoutAlign="start center"

    matTreeNodePadding
    matTreeNodePaddingIndent="20"

    matTooltip="{{node.label}} ({{node.count}})"
    matTooltipPosition="right"

    *matTreeNodeDef="let node">
    <!-- Toggle -->
    <span class="toggle">
      <mat-icon class="mat-icon-rtl-mirror" *ngIf="isExpandable(node)" matTreeNodeToggle>
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </span>

    <!-- Checkbox -->
    <mat-checkbox
      class="checkbox"

      [checked]="isSelected(node)"
      [indeterminate]="isPartiallySelected(node)"
      (change)="onCheckToggle(node)"
    >
    </mat-checkbox>

    <!-- Label -->
    <label (click)="onCheckToggle(node)"
      class="label">
        {{node.label}}
    </label>

    <!-- Progress bar -->
    <app-progress-bar
      class="progress"
      fxHide.lt-lg

      [progress]="node.percent">
      {{node.count}}
    </app-progress-bar>
  </mat-tree-node>
</mat-tree>
