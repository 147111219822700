import { Injectable } from '@angular/core';
import { AbstractMapper } from '@app/modules/core/mappers/abstract-mapper';
import { CharacterizationFactorSearchResultDto } from '@app/api/__generated__/model/characterizationFactorSearchResultDto';
import { CharacterizationFactorSearchResult } from '@app/model/characterization-factors/characterization-factor-search.model';
import { MethodMapperService } from '@app/modules/reference-data/method/mappers/method-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class CharacterizationFactorSearchResultMapperService extends AbstractMapper<
  CharacterizationFactorSearchResultDto,
  CharacterizationFactorSearchResult
> {
  constructor(private methodMapperService: MethodMapperService) {
    super();
  }

  from(
    dto: CharacterizationFactorSearchResultDto
  ): CharacterizationFactorSearchResult {
    const {
      id,
      indicator,
      substance,
      compartment,
      subCompartment,
      characterisationFactor,
      unit,
    } = dto;
    return {
      id,
      indicator: this.methodMapperService.mapIndicator(
        indicator,
        indicator.method
      ),
      substance,
      compartment,
      subCompartment,
      characterisationFactor,
      unit,
    };
  }
}
