/* eslint-disable @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unused-vars */
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TreeDto } from '@app/api/__generated__/model/treeDto';
import * as d3 from 'd3';
import * as d3Sankey from 'd3-sankey';
import { Router } from '@angular/router';
import {Link, SankeyLink, SankeyNode, Node} from "@app/pages/dataset-details/exchange/modules/sankey/model/sankey.model";
import {targetNode} from "@app/pages/dataset-details/exchange/modules/sankey/utils/sankey.utils";

@Directive({
  selector: 'svg[appSankey][viewBox]',
})
export class SankeyDirective implements OnChanges {
  @Input()
  appSankey: TreeDto | undefined;

  @Input()
  formatNodeName = (name: string, maxCharacters: number): string => name;

  private readonly COLOR_SCALE = d3.scaleOrdinal(d3.schemePastel1);

  private readonly SANKEY_NODE_WIDTH = 30;
  private readonly SANKEY_NODE_PADDING = 10;

  private readonly SANKEY_TOP_PADDING = 10;
  private readonly SANKEY_BOTTOM_PADDING = 10;
  private readonly SANKEY_LEFT_PADDING = 0;
  private readonly SANKEY_RIGHT_PADDING = 0;

  get viewBoxWidth(): number {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return this.el.nativeElement.viewBox.baseVal.width as number;
  }

  get viewBoxHeight(): number {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return this.el.nativeElement.viewBox.baseVal.height as number;
  }

  constructor(private router: Router, private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('appSankey' in changes) {
      this.drawSankey(this.appSankey);
    }
  }

  private drawSankey(tree: TreeDto | undefined): void {
    const graph = this.toSankeyGraph(tree);

    const svg = d3.select(this.el.nativeElement);

    let links = svg.select<SVGGElement>('g.links');
    if (links.empty()) {
      links = svg.append('g').classed('links', true);
    }

    let nodes = svg.select<SVGGElement>('g.nodes');
    if (nodes.empty()) {
      nodes = svg.append('g').classed('nodes', true);
    }

    this.joinLinks(links, nodes, graph);
    this.joinNodes(links, nodes, graph);
  }

  private toSankeyGraph(
    tree: TreeDto | undefined
  ): d3Sankey.SankeyGraph<Node, Link> {
    const graph: d3Sankey.SankeyGraph<Node, Link> = {
      nodes: (tree?.nodes ?? []).map((item) => ({
        ...item,
      })),
      links: (tree?.links ?? []).map((item) => ({
        ...item,
        pathLength: 0,
      })),
    };

    const sankey = d3Sankey
      .sankey()
      .nodeWidth(this.SANKEY_NODE_WIDTH)
      .nodePadding(this.SANKEY_NODE_PADDING)
      .extent([
        [this.SANKEY_LEFT_PADDING, this.SANKEY_TOP_PADDING],
        [
          this.viewBoxWidth - this.SANKEY_RIGHT_PADDING,
          this.viewBoxHeight - this.SANKEY_BOTTOM_PADDING,
        ],
      ]);

    sankey(graph);

    return graph;
  }

  private joinLinks(
    links: d3.Selection<SVGGElement, unknown, null, undefined>,
    nodes: d3.Selection<SVGGElement, unknown, null, undefined>,
    graph: d3Sankey.SankeyGraph<Node, Link>
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const link = links.selectAll('g').data(graph.links);

    const newLink = link
      .enter()
      .append('g')
      .attr('id', (d: SankeyLink) => SankeyDirective.linkId(d))
      .classed('link', true)
      .classed(
        'navigable',
        (d: SankeyLink) => targetNode(d).keyId !== undefined
      )
      .on('click', function (this: SVGGElement, e: unknown, d: SankeyLink) {
        self.navigateToDataset(targetNode(d).keyId);
      })
      .on('mouseover', function (this: SVGGElement, e: unknown, d: SankeyLink) {
        const node = nodes.select(`#${SankeyDirective.nodeId(targetNode(d))}`);
        node.classed('mouseover', true);
      })
      .on('mouseout', function (this: SVGGElement, e: unknown, d: SankeyLink) {
        const node = nodes.select(`#${SankeyDirective.nodeId(targetNode(d))}`);
        node.classed('mouseover', false);
      });

    newLink
      .append('path')
      .attr('id', (d) => SankeyDirective.pathId(d))
      .attr('fill', 'none')
      .attr('stroke', (d) => '#ece9e9')
      .attr('d', d3Sankey.sankeyLinkHorizontal())
      .attr('stroke-width', (d) => Math.max(1, d.width ?? 0))
      .each(function (d: SankeyLink) {
        d.pathLength = this.getTotalLength();
      });

    newLink
      .append('text')
      .attr(
        'font-size',
        (d: SankeyLink) =>
          `${SankeyDirective.getFontSizeFromLength(d.width!)}px`
      )
      .append('textPath')
      .attr('href', (d: SankeyLink) => `#${SankeyDirective.pathId(d)}`)
      .attr('lengthAdjust', 'spacingAndGlyphs')
      .attr('startOffset', '100%')
      .attr('alignment-baseline', 'central')
      .attr('text-anchor', 'end')
      .text((d: SankeyLink) => {
        const maxCharacters = SankeyDirective.getMaxCharactersFromLength(
          d.pathLength
        );
        return (
          this.formatNodeName(targetNode(d).name, maxCharacters) + '\u00A0'
        );
      });

    newLink.append('title').text((d: SankeyLink) => {
      return SankeyDirective.formatTooltipText(targetNode(d).name, d.value);
    });

    link.exit().remove();
  }

  private joinNodes(
    links: d3.Selection<SVGGElement, unknown, null, undefined>,
    nodes: d3.Selection<SVGGElement, unknown, null, undefined>,
    graph: d3Sankey.SankeyGraph<Node, Link>
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const node = nodes.selectAll('g').data(graph.nodes);

    const newNode = node
      .enter()
      .append('g')
      .attr('id', (d: SankeyNode) => `${SankeyDirective.nodeId(d)}`)
      .classed('node', true)
      .classed('navigable', (d: SankeyNode) => d.keyId !== undefined)
      .on('click', function (this: SVGGElement, e: unknown, d: SankeyNode) {
        self.navigateToDataset(d.keyId);
      })
      .on('mouseover', function (this: SVGGElement, e: unknown, d: SankeyNode) {
        d.targetLinks?.forEach((d: SankeyLink) => {
          const link = links.select(`#${SankeyDirective.linkId(d)}`);
          link.classed('mouseover', true);
        });
      })
      .on('mouseout', function (this: SVGGElement, e: unknown, d: SankeyNode) {
        d.targetLinks?.forEach((d: SankeyLink) => {
          const link = links.select(`#${SankeyDirective.linkId(d)}`);
          link.classed('mouseover', false);
        });
      });

    newNode
      .append('rect')
      .attr('x', (d: SankeyNode) => d.x0!)
      .attr('y', (d: SankeyNode) => d.y0!)
      .attr('height', (d: SankeyNode) => d.y1! - d.y0!)
      .attr('width', (d: SankeyNode) => d.x1! - d.x0!)
      .attr('fill', (d: SankeyNode) => this.getNodeColor(d.name))
      .attr('keyId', (d: SankeyNode) => d.keyId ?? null);

    newNode
      .append('text')
      .attr(
        'font-size',
        (d: SankeyNode) =>
          `${SankeyDirective.getFontSizeFromLength(d.y1! - d.y0!)}px`
      )
      .attr('fill', (d: SankeyNode) => this.getNodeColor(d.name))
      .attr('x', (d: SankeyNode) => d.x0! + (d.x1! - d.x0!) / 2)
      .attr('y', (d: SankeyNode) => d.y0! + (d.y1! - d.y0!) / 2)
      .attr('dominant-baseline', 'middle')
      .attr('text-anchor', 'middle')
      .text((d: SankeyNode) => SankeyDirective.formatNodeValue(d.value!));

    newNode
      .append('title')
      .text((d: SankeyNode) =>
        SankeyDirective.formatTooltipText(d.name, d.value!)
      );

    node.exit().remove();
  }

  private static nodeId(d: SankeyNode): string {
    return `node-${d.nodeId}`;
  }

  private static linkId(d: SankeyLink): string {
    return `link-${d.index!}`;
  }

  private static pathId(d: SankeyLink): string {
    return `path-${d.index!}`;
  }
  private static getMaxCharactersFromLength(lengthInPx: number): number {
    return lengthInPx / 4.5;
  }

  private static getFontSizeFromLength(lengthInPx: number): number {
    return Math.min(Math.round(0.01111 * lengthInPx + 7.889), 10);
  }

  private static formatNodeValue(value: number): string {
    return d3.format(',.1%')(value);
  }

  private static formatTooltipText(name: string, value: number): string {
    return `${name} ${SankeyDirective.formatNodeValue(value)}`;
  }

  private getNodeColor(name: string): string {
    return this.COLOR_SCALE(name.replace(/ .*/, ''));
  }

  private navigateToDataset(keyId: string | undefined): void {
    if (keyId !== undefined) {
      void this.router.navigate(['/datasets', keyId]);
    }
  }
}
