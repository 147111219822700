import { IndicatorGroup } from '@app/modules/reference-data/indicator-group/models/indicator-group.model';
import {DatasetSearchResult} from "@app/model/dataset/dataset-search.model";

export class BasketExportDialogService {
  findExistingGroupDefinitions(
    datasets: DatasetSearchResult[],
    indicatorGroups: IndicatorGroup[]
  ): IndicatorGroup[] {
    const listOfGroups: string[] = datasets.flatMap((d) => d.groups);
    return indicatorGroups.filter((group) =>
      Array.from(new Set(listOfGroups)).includes(group.name)
    );
  }
}
