import { Component, Input, OnInit } from '@angular/core';
import { DatasetDescription } from '@app/model/dataset/dataset.model';
import {getWarnings} from "@app/services/datasets/dataset.utils";

@Component({
  selector: 'app-dataset-warning[datasetDescription]',
  templateUrl: './dataset-warning.component.html',
  styleUrl: './dataset-warning.component.scss',
})
export class DatasetWarningComponent implements OnInit {
  @Input()
  datasetDescription!: DatasetDescription;

  @Input()
  displayText = true;

  useDummy = false;
  isDummy = false;
  isAcs = false;
  useAcs = false;

  ngOnInit(): void {
    const warnings = getWarnings(this.datasetDescription).map(
      (c) => c.description,
    );
    this.useDummy = warnings.includes('USE_DUMMY');
    this.isDummy = warnings.includes('DUMMY');
    this.isAcs = warnings.includes('AGROFORESTRY_CARBON_SEQUESTRATION');
    this.useAcs = warnings.includes('USE_AGROFORESTRY_CARBON_SEQUESTRATION');
  }
}
