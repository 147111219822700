<ng-container *ngIf="dataset$ | async as dataset">
  <app-dataset-detail-header [dataset]="dataset"></app-dataset-detail-header>
  <mat-tab-group
    id="dataset-detail-tabs"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="onTabChange($event, dataset)"
  >
    <mat-tab label="Dataset information">
      <app-info-tab [dataset]="dataset" class="tab-content"></app-info-tab>
    </mat-tab>
    <mat-tab label="Impact factors (LCIA)">
      <app-impact-factors-tab
        [dataset]="dataset"
        class="tab-content"
      ></app-impact-factors-tab>
    </mat-tab>
    <mat-tab label="Exchanges" >
      <app-exchanges-tab
        [dataset]="dataset"
        (contributionSelectionChange)="onContributionSelectionChange($event)"
        class="tab-content"
      ></app-exchanges-tab>
    </mat-tab>
    <ng-container *ngIf="true">
      <mat-tab label="Used by">
        <app-parent-tab
          [dataset]="dataset"
          class="tab-content"
        ></app-parent-tab>
      </mat-tab>
    </ng-container>
    <ng-container *ngIf="dataset.history.hasHistory">
      <mat-tab label="Dataset history">
        <app-history-tab
          [dataset]="dataset"
          class="tab-content"
        ></app-history-tab>
      </mat-tab>
    </ng-container>
    <ng-container *ngIf="hasEmbeddedProducts(dataset)">
      <mat-tab label="Embedded product">
        <app-embedded-product-tab
          [dataset]="dataset"
          class="tab-content"
        ></app-embedded-product-tab>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</ng-container>
