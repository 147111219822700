import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ChildActivationEnd, NavigationEnd, Params, Router} from '@angular/router';
import * as datasetBasket from '@app/store/basket/basket-dataset.selectors';
import {AppState} from '@app/store';
import {environment} from '@env/environment';
import {Store} from '@ngrx/store';
import {EMPTY, Observable, Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {AuthUserService} from '@app/modules/auth/services/auth-user.service';
import {BasketDialogService} from "@app/services/basket/basket-dialog.service";
import {UserStore} from "@app/store/user.store";

type RouteData = { showBasket: boolean; menuButtons: ButtonData[] };
type ButtonData = { linkTo: string; label: string, type: 'ABSOLUTE' | 'RELATIVE', allowNonCustom: boolean };

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent implements OnInit, OnDestroy {
  readonly userStore = inject(UserStore);

  status$: Observable<{
    showBasket: boolean;
    menuButtons: ButtonData[];
  }> = EMPTY;
  paramMemory = new Map<string,Params>();

  private onDestroy$ = new Subject<void>();

  basketSize = 0;
  showBanner = environment.name !== 'prod';

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private userService: AuthUserService,
    private basketDialogService: BasketDialogService,
  ) {
  }

  ngOnInit(): void {
    this.store
      .select(datasetBasket.selectTotal)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((datasetTotal) => (this.basketSize = datasetTotal));

    const navigationEndEvent = this.router.events.pipe(filter((event => event instanceof NavigationEnd))) as Observable<NavigationEnd>;
    const routeData$ = navigationEndEvent.pipe(
      map(() => this.router.routerState.snapshot.root.firstChild?.data as RouteData)
    );

    const childActivationEnd = this.router.events.pipe(filter((event => event instanceof ChildActivationEnd))) as Observable<ChildActivationEnd>;
    childActivationEnd
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((childActivationEnd) => {
        if(this.router.routerState.snapshot.root.firstChild) {
          const location = "/" + this.router.routerState.snapshot.root.firstChild.url.join("/");
          this.paramMemory.set(location, childActivationEnd.snapshot.queryParams);
        }
      });

    this.status$ = routeData$.pipe(
      map((routeData) => ({
        showBasket: routeData?.showBasket,
        menuButtons: routeData?.menuButtons,
      })),
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  openGeneralFeedback(): void {
    window.open(
      `https://forms.office.com/Pages/ResponsePage.aspx?id=Cy_7LSFNaEKVWXKSYUTJGEfjhiZlTnVFoiwUcrhq3kdUM1BPUU5GS0ZKVjlWTzBMSUtRTDlBNzhQNy4u&raa449f86be354e6698b9cd281d0bb1c3=${this.userStore.userInfo().company}`,
    );
  }

  openSearchFeedback(): void {
    window.open(
      `https://forms.office.com/Pages/ResponsePage.aspx?id=Cy_7LSFNaEKVWXKSYUTJGEfjhiZlTnVFoiwUcrhq3kdUOVFURVlXSEhJWkFHRUpMTDAxM1VMOVlaSC4u&rdc32fb05cd4041b78a763005339ddc26=${this.userStore.userInfo().company}`,
    );
  }

  openChangelog(): void {
    if(this.userStore.userInfo().isExternal) {
      window.open('https://prod-public-docs-bucket.s3.eu-west-1.amazonaws.com/eqosphere_external_changelog.pdf');
    } else {
      window.open('https://bcgcloudeur.sharepoint.com/:w:/s/Q-Communities/EWJMf5yksO1Jk-g56lnmwQgBnTdQJN6gET2BSGvE75J8PA?e=TzPtgR');
    }
  }

  logout(): void {
    this.userService.logout();
  }

  changePwd(): void {
    this.userService.changePassword();
  }

  openImportDialog(): void {
    this.basketDialogService
      .openImportDialog()
      .afterClosed()
      .subscribe((success: boolean | undefined) => {
        if (success === true) {
          void this.router.navigate(['basket']);
        }
      });
  }

  openExportDialog(): void {
    this.basketDialogService.openExportDialog();
  }

  openUpgradeDialog(): void {
    this.basketDialogService
      .openUpgradeDialog()
      .afterClosed()
      .subscribe((success: boolean | undefined) => {
        if (success === true) {
          void this.router.navigate(['basket']);
        }
      });
  }

  protected readonly JSON = JSON;
}
