import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RumService } from '@app/modules/rum/rum.service';

import {map, tap} from 'rxjs/operators';
import {BasketApiService} from "@app/services/basket/basket-api.service";
import {BasketExportOptions} from "@app/model/basket/basket.model";

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  constructor(
    private basketApiService: BasketApiService,
    private rumService: RumService
  ) {}

  exportBasketAsExcel(options: BasketExportOptions): Observable<{
    filename: string;
    blob: Blob;
  }> {
    return this.basketApiService.exportBasketAsXlsx(options).pipe(
      map((content) => ({
        filename: this.generateExcelExportFilename(options.groups),
        blob: new Blob([content], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      })),
      tap(() => this.rumService.triggerBasketExportEvent(options)),
    );
  }

  private generateExcelExportFilename(groups: string[]): string {
    return groups.length === 0
      ? 'basket_export.xlsx'
      : groups.join('_') + '_export.xlsx';
  }
}
