import { Dataset } from '@app/model/dataset/dataset.model';
import {CriteriaValue} from "@app/pages/datasets-search/datasets-search.model";

export interface Classification {
  system: string;
  code: string;
  description: string;
  criteria: CriteriaValue;
  displayDescription: string;
}

export interface GroupedClassification {
  system: string;
  values: string;
}

function findClassificationOf(
  systemName: string,
  dataset: Dataset | undefined,
): Classification | undefined {
  return dataset?.description.classifications?.get(systemName)?.at(0);
}

export function findCategoryOf(
  dataset: Dataset | undefined,
): Classification | undefined {
  return findClassificationOf('Activity category', dataset);
}

export function findCpcOf(
  dataset: Dataset | undefined,
): Classification | undefined {
  return findClassificationOf('CPC', dataset);
}

export function findIsicOf(
  dataset: Dataset | undefined,
): Classification | undefined {
  return findClassificationOf('ISIC', dataset);
}

export function groupClassifications(
  classifications: Classification[] | undefined
): Map<string, Classification[]> {
  const classificationMap: Map<string, Classification[]> = new Map<
    string,
    Classification[]
  >();
  if(classifications !== undefined) {
    for (const classification of classifications) {
      if (!classificationMap.has(classification.system)) {
        classificationMap.set(classification.system, []);
      }
      classificationMap.get(classification.system)!.push(classification);
    }
  }
  return classificationMap;
}
