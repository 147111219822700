import {Component, EventEmitter, Input, Output} from '@angular/core';
import {defaultSectionOptions, SectionOptions} from "@app/model/dataset/dataset.model";
import {Assessment, ExchangeRecord} from "@app/model/dataset/exchange.model";

@Component({
  selector: '[exchange-line-record][records][options]',
  templateUrl: 'exchange-line-record.component.html',
  styleUrl: 'exchange-line-record.component.scss',
})
export class ExchangeLineRecordComponent {
  @Input() records!: Array<ExchangeRecord>;
  @Input() options : SectionOptions = defaultSectionOptions;
  @Output() expandedCommentIdChange = new EventEmitter<string>();


  onExpandedCommentIdChange(expandedId: string): void {
    this.expandedCommentIdChange.emit(expandedId);
  }

  getDisplayedAssessment(record: ExchangeRecord): Assessment[] {
    return record.assessments.filter(a => a.key ==this.options.displayedKey);
  }
}


