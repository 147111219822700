import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {EmbeddedProductType, toLabel} from "@app/model/dataset/embedded.product.model";

@Pipe({
  name: 'embeddedTypeLabel',
})
export class EmbeddedTypeLabelPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(productType: EmbeddedProductType): string {
    return toLabel(productType);
  }
}
