import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  BasketImportDialogComponent
} from "@app/pages/view-basket/components/basket-import-dialog/basket-import-dialog.component";
import {
  UpgradeDatasetsDialogComponent
} from "@app/pages/view-basket/components/update-datasets-dialog/upgrade-datasets-dialog.component";
import {
  BasketExportDialogComponent
} from "@app/pages/view-basket/components/basket-export-dialog/basket-export-dialog.component";

@Injectable({
  providedIn: 'root',
})
export class BasketDialogService {
  constructor(private dialog: MatDialog) {}

  openImportDialog(): MatDialogRef<unknown> {
    return this.dialog.open(BasketImportDialogComponent, {
      width: '50%',
    });
  }

  openUpgradeDialog(): MatDialogRef<unknown> {
    return this.dialog.open(UpgradeDatasetsDialogComponent, { width: '50%' });
  }

  openExportDialog(): MatDialogRef<unknown> {
    return this.dialog.open(BasketExportDialogComponent);
  }
}
