import { Pipe, PipeTransform } from '@angular/core';
import { UNIT_SEARCH_REPLACE_DICTIONARY } from '@app/modules/reference-data/unit/models/format-unit.model';

@Pipe({
  name: 'formatUnit',
})
export class FormatUnitPipe implements PipeTransform {
  transform(value: string | undefined): string | undefined {
    if (value === undefined) return undefined;
    for (const replacement of UNIT_SEARCH_REPLACE_DICTIONARY) {
      value = value.replaceAll(replacement.search, replacement.replace);
    }
    return value;
  }
}
