import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClassificationApiService } from '@app/modules/reference-data/classification/api/classification-api.service';
import { map } from 'rxjs/operators';
import { Classification } from '../models/classification.model';
import { ClassificationMapperService } from '@app/modules/reference-data/classification/mappers/classification-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class ClassificationService {
  constructor(
    private classificationApiService: ClassificationApiService,
    private mapper: ClassificationMapperService
  ) {}

  public findIsicClassifications(): Observable<Classification[]> {
    return this.classificationApiService
      .getClassifications('ISIC')
      .pipe(
        map((classifications) => this.mapper.fromList(classifications.results))
      );
  }

  public findCpcClassifications(): Observable<Classification[]> {
    return this.classificationApiService
      .getClassifications('CPC')
      .pipe(
        map((classifications) => this.mapper.fromList(classifications.results))
      );
  }

  public findActivityCategoryClassifications(): Observable<Classification[]> {
    return this.classificationApiService
      .getClassifications('ACTIVITY_CATEGORY')
      .pipe(
        map((classifications) => this.mapper.fromList(classifications.results))
      );
  }

  public labelMapOf(classifications: Classification[]): Map<string, string> {
    return new Map(classifications.map(c => [ c.code, c.displayDescription ]));
  }
}
