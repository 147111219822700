import { AppState } from '@app/store';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import {
  CharacterizationFactorSearchState,
  searchFeatureKey,
} from './characterization-factor-search.reducer';
import {
  CharacterizationFactorSearchQuery,
  CharacterizationFactorSearchResult,
  CharacterizationFactorsSearchFiltersOptions,
} from '@app/model/characterization-factors/characterization-factor-search.model';

export const selectSearchState = createFeatureSelector<
  AppState,
  CharacterizationFactorSearchState
>(searchFeatureKey);

export const selectResults = (): MemoizedSelector<
  AppState,
  CharacterizationFactorSearchResult[]
> =>
  createSelector(
    selectSearchState,
    (state: CharacterizationFactorSearchState) => state.results
  );

export const selectForm = (): MemoizedSelector<
  AppState,
  CharacterizationFactorSearchQuery
> =>
  createSelector(
    selectSearchState,
    (state: CharacterizationFactorSearchState) => state.form
  );

export const selectFiltersOptions = (): MemoizedSelector<
  AppState,
  CharacterizationFactorsSearchFiltersOptions
> =>
  createSelector(
    selectSearchState,
    (state: CharacterizationFactorSearchState) => state.filtersOptions
  );

export const selectSelectedFilters = createSelector(
  selectSearchState,
  (state: CharacterizationFactorSearchState) => state.form.filters
);

export const selectIndicatorGroups = createSelector(
  selectSearchState,
  (state: CharacterizationFactorSearchState) => state.indicatorGroups
);

export const selectCompartments = createSelector(
  selectSearchState,
  (state: CharacterizationFactorSearchState) => state.compartments
);
