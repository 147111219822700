import { Injectable } from '@angular/core';
import { Filter } from '@app/modules/ui/legacy/filters-group/filters-group.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FiltersGroupService<K extends string> {
  toFormGroup(filters: Array<Filter<K>>): UntypedFormGroup {
    const group: { [key: string]: UntypedFormControl } = {};
    filters.forEach((filter) => {
      group[filter.key] = new UntypedFormControl(
        filter.value$.value.map((item) => item.key)
      );
    });
    return new UntypedFormGroup(group);
  }
}
