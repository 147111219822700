<ng-container *ngIf="searchResponse.results.length > 0; else noResults">
  <div class="results" [class.loading]="isLoading">
    <section class="results-all">
      <mat-checkbox
        color="primary"
        [(ngModel)]="areAllResultsInBasket"
        [matTooltip]="
          areAllResultsInBasket
            ? 'Remove all displayed datasets'
            : 'Select all displayed datasets'
        "
      >
      </mat-checkbox>
      <i class="results-amount">
        {{ resultAmountDisplay }}
      </i>
    </section>
    <section class="result" *ngFor="let dataset of searchResponse.results">
      <mat-checkbox
        color="primary"
        [checked]="basketIds.has(dataset.id)"
        (change)="onDatasetToggled(dataset)"
        [matTooltip]="
          basketIds.has(dataset.id) ? 'Remove from basket' : 'Add to basket'
        "
      >
      </mat-checkbox>
      <app-dataset-summary
        [dataset]="dataset"
        [showDetails]="showResultsDetails"
        [showGeneralComment]="showResultsDetails"
      ></app-dataset-summary>
    </section>
  </div>
</ng-container>

<ng-template #noResults>
  <div class="no-results" *ngIf="!isLoading">
    <p fxLayoutAlign="start center">
      <mat-icon class="disabled-text-color">search_off</mat-icon>
      Your search did not match any datasets.
    </p>
  </div>
  <div *ngIf="searchResponse.suggestion as suggestion" class="no-results">
    Instead of <b>{{ suggestion.originalText }}</b
    >, did you mean ?
    <mat-chip-listbox>
      <mat-chip-option
        *ngFor="let suggestionOption of suggestion.suggestions"
        [removable]="false"
        [selectable]="true"
        (click)="
          onSuggestionSelection({
            originalText: suggestion.originalText,
            suggestion: suggestionOption
          })
        "
        >{{ suggestionOption }}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</ng-template>
