import { Pipe, PipeTransform } from '@angular/core';
import { CompartmentDto } from '@app/api/__generated__/model/compartmentDto';
import { COMPARTMENTS } from '@app/modules/reference-data/substance/substance/models/compartment.model';

@Pipe({
  name: 'compartmentLabel',
})
export class CompartmentLabelPipe implements PipeTransform {
  transform(value: CompartmentDto | undefined): string {
    return value !== undefined ? COMPARTMENTS.get(value) ?? value : '';
  }
}
