import {Component, inject} from '@angular/core';
import { PageComponent } from '@app/modules/core/components/page-component';
import {UserStore} from "@app/store/user.store";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  readonly userStore = inject(UserStore);

  constructor() {
    this.userStore.loadUser();
  }

  pageName = '';

  onActivate($event: PageComponent): void {
    this.pageName = $event.pageName;
  }
}
