<app-landing-page-section>
  <app-landing-page-section-content>
    <header class="mat-headline-4">Access</header>
    <div class="search-explore">
      <mat-form-field class="query" appearance="fill">
        <mat-label>Search datasets</mat-label>
        <input #q type="text" matInput (keydown.enter)="onSearch(q.value)" />
        <button matSuffix mat-icon-button (click)="onSearch(q.value)">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
      <div class="explore">
        <button mat-raised-button (click)="onSearch('')">Explore</button>
      </div>
    </div>

    <div class="search-filters">
      <app-datasets-search-dropdown
        criteriaCategory="databases"
        appearance="fill"
        [groups]="DATABASE_GROUPS"
        [menuItems]="dropdowns.databases"
        [selection]="databases"
        (changed)="onDatabaseSelectionChange($event)"
      >
      </app-datasets-search-dropdown>
      <app-datasets-search-dropdown
        criteriaCategory="geographies"
        appearance="fill"
        [menuItems]="dropdowns.geographies"
        [selection]="geographies"
        (changed)="onGeographySelectionChange($event)"
      >
      </app-datasets-search-dropdown>
    </div>

    <div class="bottom-bar">
      <div class="suggestions dark-theme">
        Try:
        <button
          *ngFor="let suggestion of suggestions"
          mat-stroked-button
          (click)="onSearch(suggestion)"
        >
          {{ suggestion }}
        </button>
      </div>
    </div>
  </app-landing-page-section-content>
</app-landing-page-section>
