import {Component, Input} from "@angular/core";
import {Announcement} from "@app/services/announcement/announcement.model";

@Component({
  selector: 'app-announcement-message',
  templateUrl: './announcement-message.component.html',
  styleUrl: "./announcement-message.component.scss"
})
export class AnnouncementMessageComponent {
  @Input()
  announcement! : Announcement;
  registerUrl = 'https://register.gotowebinar.com/register/113786705213256798?utm_campaign=12958959-Digital%20Solutions%202025&utm_medium=email&_hsenc=p2ANqtz-_idkqabYwBDh3g88O8SYEpfuFE4-eAij3b0RuqIaSr_D_uk6NVQ1DIUG-D-IA8ewkxCqWfnkg_br4H6S8thWQ8GS0IuR1WY2usddwKXb8ce7wUJ1Y&_hsmi=105905513&utm_content=105905513&utm_source=hs_email';
}
