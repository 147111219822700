<mat-dialog-content>
  <div class="announcement" fxFlex>
    <h2>🚀 Join the eQosphere User Webinar! 🌍</h2>
    <div>
      <p>Discover the latest platform updates, explore practical use cases, and see how eQosphere can help you optimize your sustainability strategy.</p>
      <p>📅 Tuesday, April 8, 2025 | 🕒 4:00PM CEST | ⏳ 60min</p>
      <p>🔗 <a href="https://register.gotowebinar.com/register/113786705213256798?utm_campaign=12958959-Digital%20Solutions%202025&utm_medium=email&_hsenc=p2ANqtz-9GKnddqBjTYhmgcne3qt5QqAtCF4w_8kG3fHwKidI1nx5B-LQqSTn3oemX54bXvruYnZzxZevc-dd3QtEK2hNM41ARO6MGcri6TU3uUf035NeK6zQ&_hsmi=2&utm_content=2&utm_source=hs_email">Register Now</a></p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxFlex fxLayoutAlign="end center" >
    <button mat-button mat-dialog-close>Close</button>
  </div>
</mat-dialog-actions>
