/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DatasetSearchResultsDto} from '@app/api/__generated__/model/datasetSearchResultsDto';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {DatasetsSearchServiceInterface} from '@app/api/__generated__/api/datasetsSearch.service';

@Injectable({
  providedIn: 'root',
})
export class DatasetSearchApiService implements DatasetsSearchServiceInterface {
  private readonly datasetsSearchUrl = environment.apiUrl + '/datasets-search';

  constructor(private httpClient: HttpClient) {}

  searchDatasets(
    q?: string,
    geography?: Array<string>,
    geoContinent?: Array<string>,
    geoCountry?: Array<string>,
    geoProvince?: Array<string>,
    database?: Array<string>,
    activityType?: Array<string>,
    unit?: Array<string>,
    isicSection?: Array<string>,
    isicDivision?: Array<string>,
    isicGroup?: Array<string>,
    isicCode?: Array<string>,
    cpcSection?: Array<string>,
    cpcDivision?: Array<string>,
    cpcGroup?: Array<string>,
    cpcMainClass?: Array<string>,
    cpcSubClass?: Array<string>,
  ): Observable<DatasetSearchResultsDto> {
    let queryParams = new HttpParams();

    if (q !== undefined) queryParams = queryParams.set('q', q);

    queryParams = fillParam(queryParams, 'geography', geography);
    queryParams = fillParam(queryParams, 'geoContinent', geoContinent);
    queryParams = fillParam(queryParams, 'geoCountry', geoCountry);
    queryParams = fillParam(queryParams, 'geoProvince', geoProvince);
    queryParams = fillParam(queryParams, 'database', database);
    queryParams = fillParam(queryParams, 'activityType', activityType);
    queryParams = fillParam(queryParams, 'unit', unit);
    queryParams = fillParam(queryParams, 'isicSection', isicSection);
    queryParams = fillParam(queryParams, 'isicDivision', isicDivision);
    queryParams = fillParam(queryParams, 'isicGroup', isicGroup);
    queryParams = fillParam(queryParams, 'isicCode', isicCode);
    queryParams = fillParam(queryParams, 'cpcSection', cpcSection);
    queryParams = fillParam(queryParams, 'cpcDivision', cpcDivision);
    queryParams = fillParam(queryParams, 'cpcGroup', cpcGroup);
    queryParams = fillParam(queryParams, 'cpcMainClass', cpcMainClass);
    queryParams = fillParam(queryParams, 'cpcSubClass', cpcSubClass);

    const httpOptions = { params: queryParams };

    return this.httpClient.get<DatasetSearchResultsDto>(
      this.datasetsSearchUrl,
      httpOptions,
    );
  }
}

function fillParam(queryParams: HttpParams, field: string, values: string[] | undefined) {
  if (values !== undefined) {
    for (const value of values) {
      queryParams = queryParams.append(field, value);
    }
  }
  return queryParams;
}
