import {Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import {
  CriteriaValue,
  DatasetSearchDropDowns,
  FilterSelectionChange
} from "@app/pages/datasets-search/datasets-search.model";
import {DATABASE_GROUPS} from "@app/model/dataset/dataset-search.model";
import {
  DatasetSearchFormMapperService
} from "@app/services/datasets/dataset-search-form/dataset-search-form-mapper.service";

@Component({
  selector: 'app-landing-page-search-section',
  templateUrl: './landing-page-search-section.component.html',
  styleUrl: './landing-page-search-section.component.scss',
})
export class LandingPageSearchSectionComponent {
  protected readonly DATABASE_GROUPS = DATABASE_GROUPS;

  @Input()
  dropdowns!: DatasetSearchDropDowns;

  @Input()
  databases: CriteriaValue[] = [];

  @Input()
  geographies: CriteriaValue[] = [];

  suggestions = [
    'cotton',
    'wheat',
    'cocoa',
    'electricity',
    'heat',
    'transport',
    'milk',
    'silk',
  ];

  constructor(
    private router: Router,
    private datasetSearchFormMapperService: DatasetSearchFormMapperService
  ) {
  }

  onSearch(q: string): void {
    const params = this.datasetSearchFormMapperService.toQueryParams({
      searchTerm: q,
      filters: {
        databases: this.databases,
        geographies: this.geographies,
        activityTypes: [],
        units: [],
        isics: [],
        cpcs: []
      }
    })
    void this.router.navigate(['datasets'], {
      queryParams: params
    });
  }

  onDatabaseSelectionChange($event: FilterSelectionChange): void {
    this.databases = $event.selection;
  }

  onGeographySelectionChange($event: FilterSelectionChange): void {
    this.geographies = $event.selection;
  }
}
