import { Injectable } from '@angular/core';
import { GeographyDto } from '@app/api/__generated__/model/geographyDto';
import { AbstractMapper } from '@app/modules/core/mappers/abstract-mapper';
import { GeographyWithDisplayName } from '@app/model/dataset/dataset-search.model';
import { DisplayGeographyPipe } from '@app/modules/reference-data/geography/pipes/display-geography.pipe';
import { Geography } from '../models/geography.model';
import {CriteriaValue} from "@app/pages/datasets-search/datasets-search.model";

@Injectable({
  providedIn: 'root',
})
export class GeographyMapperService extends AbstractMapper<
  GeographyDto,
  Geography
> {
  constructor(private displayGeographyPipe: DisplayGeographyPipe) {
    super();
  }

  from(dto: GeographyDto | undefined): Geography {
    if (dto === undefined || dto === null) {
      return {} as Geography;
    }
    const criteria = criteriaOf(dto);
    const { name, shortName } = dto;
    return { name, shortName, criteria } satisfies Geography;
  }

  toGeographiesWithDisplayName(
    geographies: Geography[]
  ): GeographyWithDisplayName[] {
    return geographies.map((geography) => {
      return {
        ...geography,
        displayName: this.displayGeographyPipe.transform(geography),
      };
    });
  }
}


function criteriaOf(dto: GeographyDto): CriteriaValue {
  if(dto.region?.province) {
    return { param: "geoProvince", value: dto.region?.province }
  }
  if(dto.region?.country) {
    return { param: "geoCountry", value: dto.region?.country }
  }
  if(dto.region?.continent) {
    return { param: "geoCountry", value: dto.region?.continent }
  }
  return { param: "geography", value: dto.shortName }
}
