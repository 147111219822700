import {MethodIndicator} from "@app/model/dataset/method.model";
import {FilterOption} from "@app/modules/ui/legacy/filters-group/filters-group.model";

export interface IndicatorGroup {
  name: string;
  indicators: MethodIndicator[];
  position: number;
  status: IndicatorGroupStatus;
}

export enum IndicatorGroupStatus {
  LIVE = 'Default',
  OBSOLETE = 'Obsolete',
}

export function getFilterOption(group: IndicatorGroup): FilterOption {
  return {
    key: group.name,
    value: group.name,
    group: group.status,
  };
}
