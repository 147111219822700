import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';

import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '@app/modules/material/material.module';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {TextToggleComponent} from './components/text-toggle/text-toggle.component';
import {WarnComponent} from './components/warn/warn.component';
import {IsYesOrNoPipe} from './pipes/is-yes-or-no.pipe';
import {FiltersGroupModule} from '@app/modules/ui/legacy/filters-group/filters-group.module';
import {SelectModule} from '@app/modules/ui/legacy/select/select.module';
import {DefaultPipe} from './default.pipe';
import {AmountPipe} from '@app/modules/ui/pipes/amount.pipe';
import {CopiableIdComponent} from './components/copiable-id/copiable-id.component';
import {EmbeddedTypeLabelPipe} from '@app/modules/ui/pipes/embedded-product-label.pipe';
import {NgStringPipesModule} from 'ngx-pipes';
import {ProgressBarComponent} from "@app/modules/ui/components/progress-bar/progress-bar.component";
import {DropDownMenuComponent} from "@app/modules/ui/components/drop-down-menu/drop-down-menu.component";
import {FacetTreeComponent} from "@app/modules/ui/components/facet-tree/facet-tree.component";

@NgModule({
  imports: [
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    NgStringPipesModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    TextToggleComponent,
    IsYesOrNoPipe,
    ConfirmDialogComponent,
    WarnComponent,
    DefaultPipe,
    AmountPipe,
    CopiableIdComponent,
    ProgressBarComponent,
    EmbeddedTypeLabelPipe,
    DropDownMenuComponent,
    FacetTreeComponent
  ],
  exports: [
    TextToggleComponent,
    IsYesOrNoPipe,
    WarnComponent,
    FiltersGroupModule,
    SelectModule,
    DefaultPipe,
    AmountPipe,
    CopiableIdComponent,
    ProgressBarComponent,
    EmbeddedTypeLabelPipe,
    DropDownMenuComponent,
    FacetTreeComponent
  ],
})
export class UiModule {}
