import {Component, inject, Input, OnInit} from "@angular/core";
import {AnnouncementService} from "@app/services/announcement/announcement.service";
import {Announcement} from "@app/services/announcement/announcement.model";
import {AnnouncementDialogComponent} from "@app/shared/announcement/announcement-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html'
})
export class AnnouncementComponent implements OnInit{
  dialog = inject(MatDialog);

  @Input()
  announcements: Announcement[] = [];
  protected unseen: number = 0;

  constructor(private announcementService: AnnouncementService) {
  }

  ngOnInit(): void {
    this.unseen = this.announcements.filter(a => !this.announcementService.isSeen(a)).length;
    if(this.unseen > 0) {
        this.showAnnouncements();
    }
  }

  protected showAnnouncements(): void {
    const dialogRef = this.dialog.open(AnnouncementDialogComponent, { data: this.announcements });
    dialogRef.afterClosed().subscribe(() => {
      this.announcementService.markSeen(this.announcements);
      this.unseen = 0;
    });
  }
}
