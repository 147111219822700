import {NgModule} from '@angular/core';
import {PageNotFoundComponent} from "@app/pages/page-not-found/page-not-found.component";
import {
  CharacterizationFactorsSearchComponent
} from "@app/pages/characterization-factors/characterization-factors-search.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FiltersGroupModule} from "@app/modules/ui/legacy/filters-group/filters-group.module";
import {UiModule} from "@app/modules/ui/ui.module";
import {FlexLayoutModule, FlexModule} from "@ngbracket/ngx-layout";

import {AsyncPipe, CommonModule, NgIf} from "@angular/common";
import {PagesRoutingModule} from "@app/pages/pages-routing.module";
import {DatasetsSearchComponent} from "@app/pages/datasets-search/datasets-search.component";
import {DatasetSummaryComponent} from "@app/pages/datasets-search/components/dataset-summary/dataset-summary.component";
import {
  SearchTipsDialogComponent
} from "@app/pages/datasets-search/components/search-tips-dialog/search-tips-dialog.component";
import {
  DatasetsSearchResultsComponent
} from "@app/pages/datasets-search/components/datasets-search-results/datasets-search-results.component";
import {
  DatasetsSearchFormComponent
} from "@app/pages/datasets-search/components/datasets-search-form/datasets-search-form.component";
import {
  DatasetsSearchSidebarComponent
} from './datasets-search/components/datasets-search-sidebar/datasets-search-sidebar.component';
import {RouterModule} from "@angular/router";
import {MaterialModule} from "@app/modules/material/material.module";
import {ReferenceDataModule} from "@app/modules/reference-data/reference-data.module";
import {NgStringPipesModule} from "ngx-pipes";
import {ViewBasketComponent} from "@app/pages/view-basket/view-basket.component";
import {
  BasketDatasetTabComponent
} from "@app/pages/view-basket/components/basket-dataset-tab/basket-dataset-tab.component";
import {
  BasketImportDialogComponent
} from "@app/pages/view-basket/components/basket-import-dialog/basket-import-dialog.component";
import {
  BasketExportDialogComponent
} from "@app/pages/view-basket/components/basket-export-dialog/basket-export-dialog.component";
import {
  UpgradeDatasetsDialogComponent
} from "@app/pages/view-basket/components/update-datasets-dialog/upgrade-datasets-dialog.component";
import {SharedModule} from "@app/shared/shared.module";
import {
  DatasetsSearchViewComponent
} from "@app/pages/datasets-search/components/datasets-search-view/datasets-search-view.component";
import {
  ImpactFactorSampleListComponent
} from "@app/pages/dataset-details/components/impact-factor-sample-list/impact-factor-sample-list.component";
import {ParentTabComponent} from "@app/pages/dataset-details/components/parent-tab/parent-tab.component";
import {DatasetDetailComponent} from "@app/pages/dataset-details/dataset-detail.component";
import {
  DatasetDetailHeaderComponent
} from "@app/pages/dataset-details/components/dataset-detail-header/dataset-detail-header.component";
import {HistoryTabComponent} from "@app/pages/dataset-details/components/history/history-tab/history-tab.component";
import {ExchangeModule} from "@app/pages/dataset-details/exchange/exchange.module";
import {
  HistoryTableComponent
} from "@app/pages/dataset-details/components/history/history-table/history-table.component";
import {
  ContributionTypeLabelPipe
} from "@app/pages/dataset-details/exchange/components/exchange-tab/contribution-type-filter/contribution-type-label.pipe";
import {InfoTabComponent} from "@app/pages/dataset-details/components/info-tab/info-tab.component";
import {
  ImpactFactorsTabComponent
} from "@app/pages/dataset-details/components/impact-factors-tab/impact-factors-tab.component";
import {
  EmbeddedProductTabComponent
} from "@app/pages/dataset-details/embedded-product/components/embedded-product-tab/embedded-product-tab.component";
import {
  ImpactFactorsTableComponent
} from "@app/pages/dataset-details/components/impact-factors-tab/impact-factors-table/impact-factors-table.component";
import {LabelTechnologyLevelPipe} from "@app/pages/dataset-details/pipes/label-technology-level.pipe";
import {
  EmbeddedProductExchangesComponent
} from "@app/pages/dataset-details/embedded-product/components/embedded-product-tab/embedded-product-exchanges.component";
import {CdkTableModule} from '@angular/cdk/table';
import {ParentMapperService} from "@app/services/datasets/parent/parent-mapper.service";
import {
  DatasetsSearchDropdownComponent
} from "@app/pages/datasets-search/components/datasets-search-dropdown/datasets-search-dropdown.component";
import {
  DatasetCompareViewComponent
} from "@app/pages/dataset-details/components/compare-view/dataset-compare-view.component";
import {WaterfallTableComponent} from "@app/pages/dataset-details/components/waterfall-table/waterfall-table.component";

@NgModule({
  imports: [
    ReactiveFormsModule,
    FiltersGroupModule,
    MaterialModule,
    UiModule,
    ReferenceDataModule,
    FlexModule,
    NgIf,
    PagesRoutingModule,
    AsyncPipe,
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    FormsModule,
    ReferenceDataModule,
    UiModule,
    NgStringPipesModule,
    ReactiveFormsModule,
    SharedModule,
    ExchangeModule,
    CdkTableModule,
    MaterialModule
  ],
  declarations: [
    PageNotFoundComponent,
    CharacterizationFactorsSearchComponent,
    DatasetSummaryComponent,
    SearchTipsDialogComponent,
    DatasetsSearchResultsComponent,
    DatasetsSearchFormComponent,
    DatasetsSearchSidebarComponent,
    DatasetsSearchDropdownComponent,
    DatasetsSearchComponent,
    ViewBasketComponent,
    BasketExportDialogComponent,
    BasketDatasetTabComponent,
    BasketImportDialogComponent,
    UpgradeDatasetsDialogComponent,
    DatasetsSearchViewComponent,
    ImpactFactorSampleListComponent,
    ParentTabComponent,
    DatasetDetailComponent,
    DatasetDetailHeaderComponent,
    HistoryTabComponent,
    HistoryTableComponent,
    ContributionTypeLabelPipe,
    LabelTechnologyLevelPipe,
    InfoTabComponent,
    ImpactFactorsTableComponent,
    ImpactFactorsTabComponent,
    EmbeddedProductTabComponent,
    EmbeddedProductExchangesComponent,
    DatasetCompareViewComponent,
    WaterfallTableComponent,
    DatasetCompareViewComponent
  ],
  exports: [
    PageNotFoundComponent,
    CharacterizationFactorsSearchComponent,
    DatasetsSearchComponent,
    DatasetSummaryComponent,
    ViewBasketComponent,
    BasketExportDialogComponent,
    BasketDatasetTabComponent,
    BasketImportDialogComponent,
    UpgradeDatasetsDialogComponent,
    ParentTabComponent,
    DatasetDetailComponent,
    DatasetDetailHeaderComponent,
    HistoryTabComponent,
    HistoryTableComponent,
    ContributionTypeLabelPipe,
    LabelTechnologyLevelPipe,
    InfoTabComponent,
    ImpactFactorsTableComponent,
    ImpactFactorsTabComponent,
    EmbeddedProductTabComponent,
    EmbeddedProductExchangesComponent,
    DatasetsSearchDropdownComponent,
    DatasetCompareViewComponent,
    WaterfallTableComponent
  ],
  providers: [ParentMapperService],
})
export class PagesModule {
}
