import { Injectable } from '@angular/core';
import { AbstractMapper } from '@app/modules/core/mappers/abstract-mapper';
import { ClassificationDto } from '@app/api/__generated__/model/classificationDto';
import { Classification } from '@app/modules/reference-data/classification/models/classification.model';
import {CriteriaValue} from "@app/pages/datasets-search/datasets-search.model";

@Injectable({
  providedIn: 'root',
})
export class ClassificationMapperService extends AbstractMapper<
  ClassificationDto,
  Classification
> {
  constructor() {
    super();
  }

  from(dto: ClassificationDto | undefined): Classification {
    if (dto === undefined) {
      throw new Error('classification dto is undefined');
    }
    const { system, code, description } = dto;
    const criteria = criteriaOf(dto);
    return {
      system: this.displaySystem(system),
      displayDescription: this.displayDescription(dto),
      code,
      description,
      criteria
    } as Classification;
  }

  private displaySystem(system: string): string {
    switch (system) {
      case 'ACTIVITY_CATEGORY':
        return 'Activity category';
      case 'PRODUCTION':
        return 'Production';
      case 'AGRICULTURAL':
        return 'Agricultural';
      default:
        return system;
    }
  }

  private displayDescription(dto: ClassificationDto) {
    switch (dto.system) {
      case 'ACTIVITY_CATEGORY':
        // Code for ACTIVITY_CATEGORY doesn't bring value
        return dto.description;
      default:
        return dto.code + ' - ' + dto.description;
    }
  }
}

function criteriaOf(dto: ClassificationDto): CriteriaValue | undefined {
  switch (dto.system) {
    case "ISIC":
      if (!dto.hierarchy || dto.hierarchy?.length == 0) {
        return {param: "isicSection", value: dto.code}
      }
      if (dto.hierarchy?.length == 1) {
        return {param: "isicDivision", value: dto.code}
      }
      if (dto.hierarchy?.length == 2) {
        return {param: "isicGroup", value: dto.code}
      }
      return {param: "isicCode", value: dto.code}
    case "CPC":
      if (!dto.hierarchy || dto.hierarchy?.length == 0) {
        return {param: "cpcSection", value: dto.code}
      }
      if (dto.hierarchy?.length == 1) {
        return {param: "cpcDivision", value: dto.code}
      }
      if (dto.hierarchy?.length == 2) {
        return {param: "cpcGroup", value: dto.code}
      }
      if (dto.hierarchy?.length == 3) {
        return {param: "cpcMainClass", value: dto.code}
      }
      return {param: "cpcSubClass", value: dto.code}
    case "ACTIVITY_CATEGORY":
      return {param: "activityType", value: dto.code}
    default:
      return undefined;
  }
}
