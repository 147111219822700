<ng-content></ng-content>
<button
  *ngIf="copiableId !== undefined"
  class="copy-id"
  [class.mat-large-icon-button]="size === 'large'"
  [class.mat-small-icon-button]="size === 'small'"
  mat-icon-button
  matTooltip="Copy UUID to clipboard"
  [matTooltipPosition]="tooltipPosition"
  (click)="copyIdToClipboard()"
>
<mat-icon svgIcon="copy-id"></mat-icon>
</button>
<button
  *ngIf="copiableName !== undefined"
  class="copy-id"
  [class.mat-large-icon-button]="size === 'large'"
  [class.mat-small-icon-button]="size === 'small'"
  mat-icon-button
  matTooltip="Copy name to clipboard"
  [matTooltipPosition]="tooltipPosition"
  (click)="copyNameToClipboard()"
>
  <mat-icon>content_copy</mat-icon>
</button>
