import {Component, OnDestroy, OnInit, inject} from "@angular/core";
import {PageComponent} from "@app/modules/core/components/page-component";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {combineLatest, Observable, Subject} from "rxjs";
import {RumService} from "@app/modules/rum/rum.service";
import {DatasetSearchStore} from "@app/store/search.store"
import {
  DatasetSearchFormMapperService
} from "@app/services/datasets/dataset-search-form/dataset-search-form-mapper.service";
import {DatasetSearchRequestPatch} from "@app/model/dataset/dataset-search.model";
import {toObservable} from "@angular/core/rxjs-interop";
import {isBlank} from "@app/modules/core/utils/string-utils";

@Component({
  templateUrl: './datasets-search.component.html'
})
export class DatasetsSearchComponent implements PageComponent, OnInit, OnDestroy {
  readonly pageName = 'datasets-search';
  readonly datasetSearchStore = inject(DatasetSearchStore);

  onDestroy$ = new Subject<void>();
  private defaultRequestReady$: Observable<boolean>;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private datasetSearchFormMapperService: DatasetSearchFormMapperService,
    private rumService: RumService
  ) {
    this.datasetSearchStore.loadDefaultFilters();
    this.datasetSearchStore.loadDropdowns();

    this.defaultRequestReady$ = toObservable(this.datasetSearchStore.defaultSearchRequestReady);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    const datasetSearchStore = this.datasetSearchStore;

    combineLatest([this.activatedRoute.queryParamMap, this.defaultRequestReady$]).pipe(
      takeUntil(this.onDestroy$),
    )
      .subscribe(([params, ready]) => {
        if(ready) {
          if(blankQuery(params)) {
            this.redirectWithInitialFilters()
          } else {
            datasetSearchStore.updateQuery(params);
          }
        }
      });
  }

  onSearchRequestUpdate(requestUpdate: DatasetSearchRequestPatch): void {
    const originalRequest = this.datasetSearchStore.searchRequest();
    const newRequest = this.datasetSearchFormMapperService.patchRequest(originalRequest, requestUpdate);
    this.rumService.triggerSearchEvent(newRequest);
    const queryParams = this.datasetSearchFormMapperService.toQueryParams(newRequest);
    void this.router.navigate(['datasets'], {
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  private redirectWithInitialFilters() {
    void this.router.navigate(['datasets'], {
      queryParams: this.datasetSearchFormMapperService.toQueryParams(this.datasetSearchStore.defaultSearchRequest()),
    });
  }
}

function blankQuery(params: ParamMap): boolean {
  return params.keys.length === 0 ||
    (params.keys.length === 1 && params.has("q") && isBlank(params.get("q")!));
}

