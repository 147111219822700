import {Injectable} from '@angular/core';
import {ParamMap} from '@angular/router';
import {
  DatasetQueryParams,
  DatasetSearchFilters,
  DatasetSearchRequest,
  DatasetSearchRequestPatch
} from "@app/model/dataset/dataset-search.model";
import {CriteriaParam, CriteriaValue} from "@app/pages/datasets-search/datasets-search.model";

@Injectable({
  providedIn: 'root',
})
export class DatasetSearchFormMapperService {
  fromQueryParams(queryParams: ParamMap): DatasetSearchRequest {
    return {
      searchTerm: queryParams.get('q') ?? '',
      filters: {
        databases: criteriaFromParams(queryParams, ['database']),
        geographies: criteriaFromParams(queryParams, ['geography', 'geoContinent', 'geoCountry', 'geoProvince']),
        activityTypes: criteriaFromParams(queryParams, ['activityType']),
        units: criteriaFromParams(queryParams, ['unit']),
        isics: criteriaFromParams(queryParams, ['isicCode', 'isicGroup', 'isicDivision', 'isicSection']),
        cpcs: criteriaFromParams(queryParams, ['cpcSubClass', "cpcMainClass", "cpcGroup", "cpcDivision", "cpcSection"]),
      },
    };
  }

  toQueryParams(form: DatasetSearchRequest): DatasetQueryParams {
    return {
      q: form.searchTerm,
      database: form.filters?.databases.map(x => x.value),
      geography: form.filters?.geographies.filter(x => x.param === 'geography').map(x => x.value),
      geoContinent: form.filters?.geographies.filter(x => x.param === 'geoContinent').map(x => x.value),
      geoCountry: form.filters?.geographies.filter(x => x.param === 'geoCountry').map(x => x.value),
      geoProvince: form.filters?.geographies.filter(x => x.param === 'geoProvince').map(x => x.value),
      activityType: form.filters?.activityTypes.map(x => x.value),
      unit: form.filters?.units.map(x => x.value),
      isicCode: form.filters?.isics.filter(x => x.param === 'isicCode').map(x => x.value),
      isicGroup: form.filters?.isics.filter(x => x.param === 'isicGroup').map(x => x.value),
      isicDivision: form.filters?.isics.filter(x => x.param === 'isicDivision').map(x => x.value),
      isicSection: form.filters?.isics.filter(x => x.param === 'isicSection').map(x => x.value),
      cpcSubClass: form.filters?.cpcs.filter(x => x.param === 'cpcSubClass').map(x => x.value),
      cpcMainClass: form.filters?.cpcs.filter(x => x.param === 'cpcMainClass').map(x => x.value),
      cpcGroup: form.filters?.cpcs.filter(x => x.param === 'cpcGroup').map(x => x.value),
      cpcDivision: form.filters?.cpcs.filter(x => x.param === 'cpcDivision').map(x => x.value),
      cpcSection: form.filters?.cpcs.filter(x => x.param === 'cpcSection').map(x => x.value),
    };
  }

  patchRequest(originalRequest: DatasetSearchRequest, requestUpdate: DatasetSearchRequestPatch): DatasetSearchRequest {
    const newRequest = {
      searchTerm: requestUpdate.searchTerm !== undefined ? requestUpdate.searchTerm : originalRequest.searchTerm,
      filters: {
        ...originalRequest.filters
      }
    };
    if (requestUpdate.filters) {
      for (const [key, value] of Object.entries(requestUpdate.filters)) {
        newRequest.filters[key as keyof DatasetSearchFilters] = value;
      }
    }
    return newRequest;
  }
}


function criteriaFromParams(queryParams: ParamMap, criteriaParam: CriteriaParam[]): CriteriaValue[] {
  const criteria: CriteriaValue[] = [];
  for(const param of criteriaParam) {
    const paramCriteria: CriteriaValue[] = queryParams.getAll(param).map(x => ({param: param, value: x}));
    criteria.push(...paramCriteria)
  }
  return criteria;
}
