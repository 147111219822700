import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import {
  loadCharacterizationFactorsFilters,
  loadCharacterizationFactorsFiltersError,
  loadCharacterizationFactorsFiltersSuccess,
  searchCharacterizationFactorsError,
  searchCharacterizationFactorsSuccess,
  updateCharacterizationFactorsFilterOptionsSuccess,
  updateCharacterizationFactorsSearchQuery,
} from './characterization-factor-search.actions';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import * as fromCharacterizationFactors from './characterization-factor-search.selectors';
import { IndicatorGroupService } from '@app/modules/reference-data/indicator-group/services/indicator-group-service';
import {
  CharacterizationFactorSearchService
} from "@app/services/characterization-factors/characterization-factor-search.service";
import {FilterOptionsUpdateService} from "@app/services/characterization-factors/filter-options-update.service";
import {SubstancesService} from "@app/modules/reference-data/substance/substance/services/substances.service";

@Injectable({
  providedIn: 'root',
})
export class CharacterizationFactorSearchEffects {
  loadFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCharacterizationFactorsFilters),
      switchMap(() =>
        combineLatest([
          this.indicatorGroupService.getIndicatorGroups(),
          this.compartmentService.getCompartments(),
          this.store.select(fromCharacterizationFactors.selectSelectedFilters),
        ]).pipe(
          map(([allIndicatorGroups, allCompartments, selectedFilters]) => ({
            allIndicatorGroups: allIndicatorGroups,
            allCompartments,
            selectedFilters,
          })),
          map(({ allIndicatorGroups, allCompartments, selectedFilters }) => ({
            filters: this.filterOptionsService.updateAndMapFilterOptions(
              selectedFilters,
              allIndicatorGroups,
              allCompartments
            ),
            allIndicatorGroups,
            allCompartments,
          })),
          map(({ filters, allIndicatorGroups, allCompartments }) =>
            loadCharacterizationFactorsFiltersSuccess({
              filters,
              allIndicatorGroups,
              allCompartments,
            })
          )
        )
      ),
      catchError((err) =>
        of(
          loadCharacterizationFactorsFiltersError({
            message:
              'An error occurred when loading the characterization factors filters',
            error: err,
          })
        )
      )
    )
  );

  updateFiltersOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCharacterizationFactorsSearchQuery),
      map((action) => action.searchForm),
      withLatestFrom(
        this.store.select(fromCharacterizationFactors.selectIndicatorGroups),
        this.store.select(fromCharacterizationFactors.selectCompartments)
      ),
      switchMap(([search, methods, compartments]) => {
        return of(
          updateCharacterizationFactorsFilterOptionsSuccess({
            filterOptions: this.filterOptionsService.updateAndMapFilterOptions(
              search.filters,
              methods,
              compartments
            ),
          })
        );
      })
    )
  );

  searchCharacterizationFactors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCharacterizationFactorsSearchQuery),
      map((action) => action.searchForm),
      switchMap((search) => {
        return this.characterizationFactorSearchService
          .searchCharacterizationFactors(
            search.searchTerm,
            [],
            search.filters.indicatorGroups,
            search.filters.indicators,
            search.filters.compartments,
            search.filters.subCompartments
          )
          .pipe(
            map((searchResults) =>
              searchCharacterizationFactorsSuccess({ results: searchResults })
            ),
            catchError((err) => {
              return of(
                searchCharacterizationFactorsError({
                  message:
                    'An error occurred when executing the characterization factors search query',
                  error: err,
                })
              );
            })
          );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private characterizationFactorSearchService: CharacterizationFactorSearchService,
    private indicatorGroupService: IndicatorGroupService,
    private compartmentService: SubstancesService,
    private filterOptionsService: FilterOptionsUpdateService,
    private store: Store<AppState>
  ) {}
}
