<div>
  <app-copiable-id
    [copiableId]="dataset.id"
    [copiableName]="dataset.name">
    <a [routerLink]="['/datasets', dataset.id]">
      <h2
        [ngClass]="{ 'header-obsolete': dataset.description.database.obsolete }"
      >
        {{ dataset.name }}
        <app-dataset-warning
          [datasetDescription]="dataset.description"
          [displayText]="false"
        ></app-dataset-warning>
      </h2>
    </a>
  </app-copiable-id>
  <ng-container *ngIf="showImpactIndicator">
    <app-impact-factor-sample-list
      [factorSamples]="dataset.impactIndicatorSamples"
    ></app-impact-factor-sample-list>
  </ng-container>
  <div>
    <div class="row-1">
      <ng-container *ngIf="showDetails">
        <span class="label">Geography</span>
        <span class="value">{{
          dataset.description.geography! | displayGeography
        }}</span>
      </ng-container>

      <ng-container *ngIf="showDetails">
        <span class="label">Database</span>
        <span class="value">{{
          dataset.description.database.displayName
        }}</span>
      </ng-container>

      <ng-container *ngIf="showDetails">
        <span class="label">Activity type</span>
        <span class="value">{{
          dataset.description.activityType | activityTypeEnumToName
        }}</span>
      </ng-container>

      <ng-container *ngIf="showDetails">
        <span class="label">Unit</span>
        <span class="value">
          {{ dataset.unit | formatUnit }}
        </span>
      </ng-container>

      <ng-container *ngIf="showDetails">
        <span class="label">Isic</span>
        <span class="value">
          {{ dataset.description.isic }}
        </span>
      </ng-container>
    </div>
    <div *ngIf="showGeneralComment">
      <div *ngIf="dataset.description.generalComment">
        <div class="label">General comment</div>
        <span class="value">
          <app-text-toggle
            [text]="dataset.description.generalComment"
          ></app-text-toggle
        ></span>
      </div>
    </div>
  </div>
</div>
