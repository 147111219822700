import {CompartmentDto} from "@app/api/__generated__/model/compartmentDto";
import {SubCompartmentDto} from "@app/api/__generated__/model/subCompartmentDto";
import {Contribution} from "@app/model/dataset/contribution.model";
import {CoProduct} from "@app/model/dataset/dataset.model";
import {EmbeddedProduct} from "@app/model/dataset/embedded.product.model";

export interface ExchangesWithoutProduct {
  fromBiosphere: BioExchange[];
  toBiosphere: BioExchange[];
  fromTechnosphere: TechnoExchange[];
  toTechnosphere: TechnoExchange[];
  embeddedProducts: EmbeddedProduct[];
}
export interface Exchanges extends ExchangesWithoutProduct{
  toProduct: ProductExchange;
}

export interface Exchange {
  displayId: string;
  name: string;
  unit: string;
  amount: number;
  comment?: string;
  contributions: Contribution[];
  flattenContributions: Contribution[];
  displayedContributions: Array<Contribution>;
}

export interface ProductExchange extends Exchange {
  id: string;
  allocation: number;
  coProducts: CoProduct[];
}

export interface TechnoExchange extends Exchange {
  id?: string;
  type?: string;
  navigable?: boolean;
  embeddedProducts: EmbeddedProduct[];
  warnings: string[];
}

export interface BioExchange extends Exchange {
  id?: string;
  type?: string;
  compartment: CompartmentDto;
  subCompartment: SubCompartmentDto;
}

export enum AllocationMode {
  WHOLE_ACTIVITY = 'WHOLE_ACTIVITY',
  ONE_PRODUCT_UNIT = 'ONE_UNIT_PRODUCT'
}


export interface ExchangeRecord {
  notDataset?: boolean;
  id: string;
  navigable: boolean;
  name: string;
  compartment?:CompartmentDto;
  subCompartment?:SubCompartmentDto;
  allocation?: number;
  amount: number;
  unit: string;
  comment?: string;
  warnings: string[];
  assessments: Assessment[];
  showBar: boolean;
}

export interface Assessment {
  displayName : string;
  key: string;
  amount: number | undefined;
}

export interface ExchangeHeader {
  title: string;
  detail1?:string;
  detail2?:string;
  hasQuantity: boolean;
  assessments: string[];
  embedded: string[];
}

export interface ProductHeader {
  detail2:string;
  hasQuantity: boolean;
}

export interface TechnosphereHeader {
  hasQuantity: boolean;
}
export interface BiosphereHeader {
  detail1: string;
  detail2: string;
  hasQuantity: boolean;
}

export const productHeaderTemplate: ProductHeader = {
  detail2: "Allocation",
  hasQuantity: true,
};

export const technosphereHeaderTemplate: TechnosphereHeader = {
  hasQuantity: true,
};

export const biosphereHeaderTemplate: BiosphereHeader = {
  detail1:"Compartment",
  detail2:"Subcompartment",
  hasQuantity: true,
};
