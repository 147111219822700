import {Component, inject, Input} from "@angular/core";
import {Dataset} from "@app/model/dataset/dataset.model";
import {ImpactSplit} from "@app/services/datasets/compare/compare.model";
import {CompareService} from "@app/services/datasets/compare/compare.service";
import {DomainStore} from "@app/store/domain.store";
import {WaterfallRecord} from "@app/pages/dataset-details/components/waterfall-table/waterfall-table.model";

@Component({
  selector: 'app-dataset-compare-view',
  templateUrl: './dataset-compare-view.component.html',
  styleUrl: './dataset-compare-view.component.scss',
})
export class DatasetCompareViewComponent {
  readonly domainStore = inject(DomainStore);

  _currentDataset?: Dataset;
  currentImpact?: ImpactSplit
  _otherDataset?: Dataset;
  otherImpact?: ImpactSplit;

  constructor(
    private compareService: CompareService
  ) {
  }

  @Input()
  set currentDataset(currentDataset: Dataset) {
    this._currentDataset = currentDataset;
    this.currentImpact = this.mapToImpactSplit(currentDataset);
    this.update();
  }

  @Input()
  set otherDataset(otherDataset: Dataset) {
    this._otherDataset = otherDataset;
    this.otherImpact = this.mapToImpactSplit(otherDataset);
    this.update();
  }

  startRecord?: WaterfallRecord;
  splitRecords: WaterfallRecord[] = [];
  endRecord?: WaterfallRecord;
  showPercentages: boolean = true;

  selectedIndicator = 'Climate change';

  update(): void {
    this.startRecord = undefined;
    this.splitRecords = [];
    this.endRecord = undefined;

    if(this.currentImpact) {
      this.startRecord = {
        label: labelFor(this._currentDataset),
        amount: this.currentImpact.amount || 0
      };
    }

    if(this.otherImpact) {
      this.endRecord = {
        label: labelFor(this._otherDataset),
        amount: this.otherImpact?.amount || 0
      };
    }

    if(
      this._currentDataset === undefined ||
      this._otherDataset === undefined ||
      this.currentImpact === undefined ||
      this.otherImpact == undefined ||
      this.endRecord == undefined ||
      this.startRecord == undefined) {
      return;
    }

    this.splitRecords = []
    if(this.currentImpact.split.length === this.otherImpact.split.length && this.currentImpact.split.length > 0) {
      for (let i = 0; i < this.currentImpact.split.length; i++) {
        const current = this.currentImpact.split[i];
        const other = this.otherImpact.split[i];
        const label = this.domainStore.activityCategories().labels.get(current.name) || current.name;
        const diff = other.amount - current.amount;
        const record: WaterfallRecord = {
          label: label,
          amount: diff
        };

        this.splitRecords.push(record);
      }
    } else {
      this.splitRecords.push({
        label: "Difference",
        amount: (this.otherImpact.amount || 0) - (this.currentImpact.amount || 0)
      })
    }
  }

  private mapToImpactSplit(dataset: Dataset): ImpactSplit | undefined {
    return this.compareService.mapToImpactSplit(dataset, this.selectedIndicator, 'ACTIVITY_CATEGORY', 'LEAVES');
  }
}

function labelFor(dataset?: Dataset) {
  if(dataset) {
    return dataset.description.database.name + " " + dataset.description.database.version;
  } else {
    return "";
  }
}
