import {Component, Input} from '@angular/core';
import {EmbeddedProduct, EmbeddedProductType} from "@app/model/dataset/embedded.product.model";
import {Dataset, getEmbeddedProductTypes, getExchangeForAllocation} from "@app/model/dataset/dataset.model";
import {Exchanges, AllocationMode} from "@app/model/dataset/exchange.model";

@Component({
  selector: 'app-embedded-product-tab',
  templateUrl: './embedded-product-tab.component.html',
  styleUrls: [
    '../../../common.filters.scss'
  ]
})
export class EmbeddedProductTabComponent  {
  protected readonly AllocationMode = AllocationMode;

  _dataset!: Dataset;
  _allocationMode: AllocationMode = AllocationMode.ONE_PRODUCT_UNIT;
  embeddedProductTypes: EmbeddedProductType[] = [];
  selectedProductType: EmbeddedProductType | undefined;
  showPercentages = false;

  @Input()
  get dataset(): Dataset {
    return this._dataset;
  }

  set dataset(dataset: Dataset) {
    this._dataset = dataset;
    this.embeddedProductTypes = getEmbeddedProductTypes(this._dataset);
    this.selectedProductType = this.embeddedProductTypes?.[0];
  }

  get allocationMode(): AllocationMode {
    return this._allocationMode;
  }

  set allocationMode(newMode: AllocationMode) {
    this._allocationMode = newMode;
  }

  get selectedProduct(): EmbeddedProduct | undefined {
    return this.exchanges.embeddedProducts.find(e => e.type === this.selectedProductType)
  }

  get exchanges(): Exchanges {
    return getExchangeForAllocation(this.dataset, this._allocationMode)
  }

}
