import {Injectable} from "@angular/core";
import {GeographyService} from "@app/modules/reference-data/geography/services/geography.service";
import {UnitService} from "@app/modules/reference-data/unit/services/unit.service";
import {
  ClassificationService
} from "@app/modules/reference-data/classification/services/classification.service";
import {combineLatest, Observable} from "rxjs";
import {map, take} from "rxjs/operators";
import {
  ACTIVITY_TYPES,
  ActivityType
} from "@app/modules/reference-data/activity-type/models/activity-types.model";
import {Database} from "@app/modules/reference-data/database/models/database.model";
import {DropDownMenuItem} from "@app/modules/ui/components/drop-down-menu/drop-down-menu.model";
import {Unit} from "@app/modules/reference-data/unit/models/unit.model";
import {Classification} from "@app/modules/reference-data/classification/models/classification.model";
import {DatabaseService} from "@app/modules/reference-data/database/services/database.service";
import {criteriaKey, DatasetSearchDropDowns} from "@app/pages/datasets-search/datasets-search.model";
import {GeographyWithDisplayName} from "@app/model/dataset/dataset-search.model";

@Injectable({
  providedIn: 'root',
})
export class DatasetDropdownService {
  constructor(
    private databaseService: DatabaseService,
    private geographyService: GeographyService,
    private unitService: UnitService,
    private classificationService: ClassificationService
  ) {

  }

  getDropdowns(): Observable<DatasetSearchDropDowns> {
    return combineLatest(
      [
        this.databaseService.findSortedDatabases(),
        this.geographyService.findGeographiesWithDisplayNameAndSort(),
        this.unitService.findUnitsAndSort(),
        this.classificationService.findIsicClassifications(),
        this.classificationService.findCpcClassifications()
      ]
    ).pipe(
      take(1), // We only fetch this data once
      map(([databases, geographies, units, isics, cpcs]) => {
        return {
          databases: databases.map(menuItemOfDatabase),
          geographies: geographies.map(menuItemOfGeography),
          activityTypes: ACTIVITY_TYPES.map(menuItemOfActivityType),
          units: units.map(menuItemOfUnit),
          isics: isics.map(menuItemOfClassification),
          cpcs: cpcs.map(menuItemOfClassification)
        } satisfies DatasetSearchDropDowns;
      }));
  }
}

function menuItemOfDatabase(item: Database): DropDownMenuItem {
  return {
    group: item.status,
    label: item.displayName,
    value: criteriaKey({ param:"database", value:item.key}),
    search: item.simpleDisplayName
  };
}

function menuItemOfGeography(item: GeographyWithDisplayName): DropDownMenuItem {
  return {
    label: item.displayName,
    value: criteriaKey(item.criteria),
    search: item.displayName
  };
}

function menuItemOfActivityType(a : ActivityType): DropDownMenuItem {
  return {
    label: a.name,
    value: criteriaKey({ param:"activityType", value:a.value}),
    search: a.name
  }
}

function menuItemOfUnit(item: Unit): DropDownMenuItem {
  return {
    label: item.formattedName,
    value: criteriaKey({ param:"unit", value:item.name}),
    search: item.formattedName
  };
}

function menuItemOfClassification(item: Classification): DropDownMenuItem {
  return {
    label: item.description,
    value: criteriaKey(item.criteria),
    search: item.displayDescription
  };
}
