import { NgModule } from '@angular/core';
import { ActivityTypeModule } from '@app/modules/reference-data/activity-type/activity-type.module';
import { DatabaseModule } from '@app/modules/reference-data/database/database.module';
import { GeographyModule } from '@app/modules/reference-data/geography/geography.module';
import { MethodModule } from '@app/modules/reference-data/method/method.module';
import { UnitModule } from '@app/modules/reference-data/unit/unit.module';
import { SubstanceModule } from '@app/modules/reference-data/substance/substance/substance.module';
import { ClassificationModule } from '@app/modules/reference-data/classification/classification.module';

@NgModule({
  exports: [
    ActivityTypeModule,
    DatabaseModule,
    GeographyModule,
    MethodModule,
    UnitModule,
    SubstanceModule,
    ClassificationModule,
  ],
})
export class ReferenceDataModule {}
