import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {Filter} from "@app/modules/ui/legacy/filters-group/filters-group.model";

@Component({
  selector: 'app-filter[filter][form]',
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss',
})
export class FilterComponent<K extends string> {
  @Input()
  filter!: Filter<K>;

  @Input()
  form!: UntypedFormGroup;
  @Input()
  refreshOnValueChange = true;

  @Output()
  filterChange = new EventEmitter<void>();

  @Output()
  openedChange = new EventEmitter<boolean>();

  get control(): UntypedFormControl {
    return this.form.get(this.filter.key) as UntypedFormControl;
  }

  onValueChange(): void {
    const selection = this.control.value as Array<string>;
    const selectedOptions = this.filter.options$.value.filter((filter) =>
      selection.includes(filter.key),
    );
    this.filter.value$.next(selectedOptions);
    if (this.refreshOnValueChange) {
      this.filterChange.emit();
    }
  }

  onOpenedChange(): void {
    this.filterChange.emit();
  }
}
