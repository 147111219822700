import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CharacterizationFactorSearchStoreModule } from '@app/store/characterization-factors/characterization-factor-search-store.module';
import { MaterialModule } from '@app/modules/material/material.module';
import { UiModule } from '@app/modules/ui/ui.module';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from '@env/environment';
import {FlexLayoutModule, FlexModule} from '@ngbracket/ngx-layout';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { buildSpecificModules } from '../builds/build';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from '@app/shared/app/app.component';
import { BasketStoreModule } from '@app/store/basket/basket.module';
import { CoreModule } from './modules/core/core.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {PagesModule} from "@app/pages/pages.module";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {FormsModule} from "@angular/forms";
import {SharedModule} from "@app/shared/shared.module";
import {NgIf} from "@angular/common";
import {LandingPageModule} from "@app/pages/landing-page/landing-page.module";
import {UnauthorizedInterceptor} from "@app/modules/auth/services/unauthorized.interceptor";

@NgModule({
  declarations: [],
  imports: [
    MatRadioGroup,
    MatRadioButton,
    MatSlideToggle,
    FormsModule,
    NgIf,
    FlexModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    FlexLayoutModule,
    CoreModule,
    UiModule,
    SharedModule,
    AuthModule.forRoot({
      errorPath: '/access-denied',
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: window.location.origin,
        scope: 'openid profile email',
        audience: environment.auth0.audience,
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.apiUrl}/*`,
            tokenOptions: {
              authorizationParams: {
                audience: environment.auth0.audience,
              },
            },
          },
        ],
      },
    }),
    LandingPageModule,
    PagesModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    BasketStoreModule,
    CharacterizationFactorSearchStoreModule,
    ...buildSpecificModules,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { position: 'above' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
