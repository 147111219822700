import {ImpactAssessmentSample} from "@app/model/dataset/dataset.model";

export class History {
  constructor(
    public parentDatabase: string,
    public parents: Array<HistoryDetail>,
    public childDatabase: string,
    public children: Array<HistoryDetail>,
    public equivalentVersions: Array<HistoryDetail>
  ) {}

  get hasHistory(): boolean {
    return this.parentDatabase !== null || this.childDatabase !== null;
  }
}

export interface HistoryDetail {
  database: string;
  version: string,
  product: string;
  productId: string;
  impactIndicatorSamples: Array<ImpactAssessmentSample>;
  status: HistoryStatus;
  label: string;
}

export enum HistoryStatus {
  INHERIT = 'INHERIT',
  MERGE = 'MERGE',
  SPLIT = 'SPLIT',
}

export const PARENT_HISTORY_STATUS_LABELS = new Map([
  [HistoryStatus.INHERIT, 'From'],
  [HistoryStatus.MERGE, 'Merged from' ],
  [HistoryStatus.SPLIT, 'Split from']
]);

export const CHILD_HISTORY_STATUS_LABELS = new Map([
  [HistoryStatus.INHERIT, 'To'],
  [HistoryStatus.MERGE, 'Merged into' ],
  [HistoryStatus.SPLIT, 'Split to']
]);
