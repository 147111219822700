<h2>Dataset history</h2>
<table>
  <colgroup>
    <col />
    <col class="title" />
    <col class="factor" />
    <col class="unit" />
    <col class="method" />
  </colgroup>
  <thead>
  <th>Version</th>
  <th>Dataset name</th>
  <th>Climate Change</th>
  <th>Unit</th>
  <th>Method</th>
  <th></th>
  </thead>
  <tbody>
  <tr *ngFor="let detail of historyDetails">
    <td [class.label]="true" [class.current-version]="isCurrent(detail)">
      {{ detail.label }}
    </td>

    <td [class.current-version]="isCurrent(detail)"
        [matTooltip]="detail.product"
        class="dataset-name">
      <a [routerLink]="['/datasets', detail.productId]"
      >{{ detail.product }}
      </a>
    </td>
    <td
    >
      <div>
        <app-progress-bar
          [matTooltip]="tooltip(detail)"
          matTooltipPosition="after"
          [style.font-weight]="'medium'"
          [class.current-version]="isCurrent(detail)"
          [progress]="this.getAssessmentSampleAmount(detail) / maxAmount"
        >
          {{ this.getAssessmentSample(detail)?.amount | number }}
        </app-progress-bar>
      </div>
    </td>
    <td>{{ this.getAssessmentSample(detail)?.unitName | formatUnit }}</td>
    <td>{{ detail.impactIndicatorSamples[0].methodName }}</td>
    <td>
      <button *ngIf="!isCurrent(detail)"
              (click)="triggerCompareWith(detail.productId)"
              class="compare"
              matTooltipPosition="after"
              [matTooltip]="'Compare with ' + this.dataset.description.database.version">
        <mat-icon class="mat-icon-small">waterfall_chart</mat-icon>
      </button>
      <button *ngIf="isCurrent(detail)"
              class="compare-hidden">
      </button>
    </td>
  </tr>
  </tbody>
</table>
