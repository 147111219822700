import {Injectable} from "@angular/core";
import {LocalStorageService} from "@app/shared/local-storage.service";
import {Announcement, ANNOUNCEMENTS} from "@app/services/announcement/announcement.model";

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  constructor(
    private localStorageService: LocalStorageService
  ) {
  }

  getAnnouncements(includeInternal: boolean, includeExternal: boolean): Announcement[] {
    return ANNOUNCEMENTS
      .filter(a => a.expiration >= new Date())
      .filter(a => includeInternal || a.target != "internal")
      .filter(a => includeExternal || a.target != "external");
  }

  isSeen(a: Announcement): boolean {
    return this.localStorageService.getItem(a.id) !== null;
  }

  markSeen(announcements: Announcement[]): void {
    announcements.forEach(a => this.localStorageService.setItem(a.id, "seen"));
  }
}
