import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AllocationMode} from "@app/model/dataset/exchange.model";

@Component({
  selector: 'app-datasets-toggles',
  templateUrl: './datasets-toggles.component.html',
  styleUrl: './datasets-toggles.component.scss'
})
export class DatasetsTogglesComponent {

  @Input()
  allocationMode: AllocationMode = AllocationMode.WHOLE_ACTIVITY;
  @Output()
  allocationModeChange =  new EventEmitter<AllocationMode>();

  @Input()
  showPercentages: boolean = false;
  @Output()
  showPercentagesChange =  new EventEmitter<boolean>();


  @Input()
  withAllocationModeSelection!: boolean;

  @Input()
  withPercentageToggle!: boolean;

  @Input()
  referenceAmount: number = 1;

  @Input()
  referenceUnit: string = "";

  protected readonly AllocationMode = AllocationMode;
}
