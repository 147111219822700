import { Injectable } from '@angular/core';
import { GeographyWithDisplayName } from '@app/model/dataset/dataset-search.model';
import { GeographyApiService } from '@app/modules/reference-data/geography/api/geography-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeographyMapperService } from '../mappers/geography-mapper.service';
import { Geography } from '../models/geography.model';

@Injectable({
  providedIn: 'root',
})
export class GeographyService {
  constructor(
    private geographyApiService: GeographyApiService,
    private mapper: GeographyMapperService
  ) {}

  findGeographies(): Observable<Geography[]> {
    return this.geographyApiService
      .getGeographies()
      .pipe(map((geographies) => this.mapper.fromList(geographies.results)));
  }

  findGeographiesWithDisplayNameAndSort(): Observable<GeographyWithDisplayName[]> {
    return this.findGeographies().pipe(
      map((geographies) =>
        this.mapper.toGeographiesWithDisplayName(geographies)
      ),
      map((geographies) =>
        geographies.sort((a, b) => a.displayName.localeCompare(b.displayName))
      )
    );
  }
}
