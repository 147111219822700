import {Injectable} from '@angular/core';
import {DatasetDto} from '@app/api/__generated__/model/datasetDto';
import {DatasetFacetDto} from '@app/api/__generated__/model/datasetFacetDto';
import {FacetBucketDto} from '@app/api/__generated__/model/facetBucketDto';
import {DatasetSearchResultDto} from '@app/api/__generated__/model/datasetSearchResultDto';
import {DatasetSearchResultsDto} from '@app/api/__generated__/model/datasetSearchResultsDto';
import {AbstractMapper} from '@app/modules/core/mappers/abstract-mapper';
import {BucketKey, CriteriaParam, DatasetSearchFacets} from "@app/pages/datasets-search/datasets-search.model";
import {
  DatasetSearchResponse,
  DatasetSearchResult,
  Facet,
  FacetBucket,
  Suggestion
} from "@app/model/dataset/dataset-search.model";
import {
  DatasetDescriptionMapperService
} from "@app/services/datasets/dataset-description/dataset-description-mapper.service";
import {AssessmentMapperService} from "@app/services/datasets/assessment/assessment-mapper.service";
import {IndicatorMapperService} from "@app/services/datasets/indicator/indicator-mapper.service";
import {Dataset} from "@app/model/dataset/dataset.model";

@Injectable({
  providedIn: 'root',
})
export class DatasetSearchResultMapperService extends AbstractMapper<
  DatasetSearchResultDto,
  DatasetSearchResult
> {
  constructor(
    private readonly ddMapper: DatasetDescriptionMapperService,
    private readonly assessmentMapper: AssessmentMapperService,
    private readonly indMapper: IndicatorMapperService
  ) {
    super();
  }

  fromResults(dto: DatasetSearchResultsDto): DatasetSearchResponse {
    const results = this.fromList(dto.results);
    const facets = this.rotateFacets(dto.facets);
    const suggestions: Suggestion[] = dto.suggestions;
    const totalDocCount = dto.totalDocCount;
    const maxDocCount = dto.maxDocCount;

    const filteredSuggestion = suggestions
      .find((s) => s.suggestions.length > 0);
    const mapped = filteredSuggestion ? {
      originalText: filteredSuggestion.originalText,
      suggestions: filteredSuggestion.suggestions
    } satisfies Suggestion : undefined;
    return {
      results, facets, totalDocCount, maxDocCount,
      suggestion: mapped
    };
  }

  from(dto: DatasetSearchResultDto): DatasetSearchResult {
    const { id, name, unit, description, groups, impactIndicatorSamples } = dto;
    return new DatasetSearchResult(
      id,
      name,
      unit,
      this.ddMapper.from(description),
      groups,
      this.indMapper.mapIndicatorSamples(impactIndicatorSamples)
    );
  }

  fromDataset(dataset: Dataset): DatasetSearchResult {
    return new DatasetSearchResult(
      dataset.id,
      dataset.referenceProduct.name,
      dataset.referenceProduct.unit,
      dataset.description,
      dataset.referenceProduct.assessmentGroups.map((g) => g.name),
      dataset.impactIndicatorSamples
    );
  }

  fromDatasets(datasets: Dataset[]): DatasetSearchResult[] {
    return datasets.map(d => this.fromDataset(d));
  }

  fromDatasetDtoList(datasets: DatasetDto[]): DatasetSearchResult[] {
    return datasets.map((d) => this.fromDatasetDto(d));
  }

  private fromDatasetDto(dataset: DatasetDto): DatasetSearchResult {
    return new DatasetSearchResult(
      dataset.id,
      dataset.referenceProduct.name,
      dataset.referenceProduct.unit,
      this.ddMapper.from(dataset.description),
      this.assessmentMapper.asGroups(dataset.referenceProduct.allocatedQuantities.contributions).map((g) => g.name),
      dataset.impactIndicatorSamples
    );
  }

  private rotateFacets(dtos: DatasetFacetDto[]): DatasetSearchFacets {
    const databases = fixDatabaseBuckets(this.rotateFacet(BucketKey.DATABASE, dtos));
    const geographies = this.rotateFacet(BucketKey.GEOGRAPHY, dtos);
    const activityTypes = this.rotateFacet(BucketKey.ACTIVITY_TYPE, dtos);
    const units = this.rotateFacet(BucketKey.UNIT, dtos);
    const isics = this.rotateFacet(BucketKey.ISIC, dtos);
    const cpcs = this.rotateFacet(BucketKey.CPC, dtos);
    return {
      databases,
      geographies,
      activityTypes,
      units,
      isics,
      cpcs
    }
  }

  private rotateFacet(key: BucketKey, dtos: DatasetFacetDto[]): Facet {
    const mapFacet = (x:DatasetFacetDto) => this.mapFacet(x);
    const buckets = dtos.filter(f => f.key === key as string).map(mapFacet).shift() || [];
    return {
      key,
      buckets
    }
  }

  private mapFacet(facetDto: DatasetFacetDto): FacetBucket[] {
      return this.mapFacetBuckets(facetDto.key as CriteriaParam, facetDto.buckets);
  }

  private mapFacetBuckets(key: CriteriaParam, buckets: Array<FacetBucketDto>): Array<FacetBucket> {
    return buckets.map(dtoBucket => { return {
      key: key,
      bucketName: dtoBucket.bucketName,
      docCount: dtoBucket.docCount,
      buckets: dtoBucket.facets && dtoBucket.facets.length > 0 ? this.mapFacet(dtoBucket.facets[0]) : undefined
    }});
  }
}

function fixDatabaseBucketName(bucketName: string) {
  const lastSpace = bucketName.lastIndexOf(' ');
  return bucketName.substring(0, lastSpace) + ':' + bucketName.substring(lastSpace+1);
}

function fixDatabaseBuckets(databaseFacet: Facet):  Facet {
  const buckets = databaseFacet.buckets.map((b) => ({...b, bucketName: fixDatabaseBucketName(b.bucketName)}));
  return {
    key: databaseFacet.key,
    buckets
  }
}
