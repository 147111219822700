import {Component, EventEmitter, inject, Output} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Announcement} from "@app/services/announcement/announcement.model";

@Component({
  selector: 'app-announcement-dialog',
  templateUrl: './announcement-dialog.component.html'
})
export class AnnouncementDialogComponent {
  announcements: Announcement[] = inject(MAT_DIALOG_DATA) as Announcement[];

  @Output()
  doClose = new EventEmitter<Array<string>>();
}
