import { IndicatorGroup } from '@app/modules/reference-data/indicator-group/models/indicator-group.model';
import {MethodIndicator} from "@app/model/dataset/method.model";

export const getDistinctSortedIndicatorsFromGroups = (
  groups: IndicatorGroup[]
): MethodIndicator[] => {
  return groups
    .flatMap((ig) => ig.indicators)
    .filter(
      (mi, pos, self) => self.findIndex((mi2) => mi2.name === mi.name) === pos
    )
    .sort((a, b) => a.name.localeCompare(b.name));
};
