import { Component, Input } from '@angular/core';
import {DatasetSearchResult} from "@app/model/dataset/dataset-search.model";

@Component({
  selector: 'app-dataset-summary',
  templateUrl: './dataset-summary.component.html',
  styleUrl: './dataset-summary.component.scss',
})
export class DatasetSummaryComponent {
  @Input()
  dataset!: DatasetSearchResult;
  @Input()
  showDetails = true;
  @Input()
  showImpactIndicator = true;
  @Input()
  showGeneralComment = true;
}
