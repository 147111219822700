import {Injectable} from '@angular/core';
import {ImpactIndicatorDto} from '@app/api/__generated__/model/impactIndicatorDto';
import {AbstractMapper} from '@app/modules/core/mappers/abstract-mapper';
import {ImpactIndicatorSampleDto} from '@app/api/__generated__/model/impactIndicatorSampleDto';
import {ImpactAssessmentSample, ImpactIndicator} from "@app/model/dataset/dataset.model";

@Injectable({
  providedIn: 'root',
})
export class IndicatorMapperService extends AbstractMapper<
  ImpactIndicatorDto,
  ImpactIndicator
> {
  from(dto: ImpactIndicatorDto): ImpactIndicator {
    const { id, categoryName, unitName, amount, groups } = dto;
    return {
      id,
      categoryName,
      unitName,
      amount,
      groups,
    };
  }

  mapIndicatorSample(dto: ImpactIndicatorSampleDto): ImpactAssessmentSample {
    const { methodName, indicator } = dto;
    return {
      methodName : methodName.replace(' QUANTIS DEFAULT',''),
      indicator: this.from(indicator),
    };
  }

  mapIndicatorSamples(
    dto: ImpactIndicatorSampleDto[] | undefined
  ): Array<ImpactAssessmentSample> {
    if (dto == undefined) {
      return [];
    }
    return dto.map((sample) => this.mapIndicatorSample(sample));
  }
}
