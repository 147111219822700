import {patchState, signalStore, withComputed, withMethods, withState} from "@ngrx/signals";
import {computed, inject} from "@angular/core";
import {rxMethod} from "@ngrx/signals/rxjs-interop";
import {pipe, switchMap} from "rxjs";
import {tap} from "rxjs/operators";
import {AuthUserService} from "@app/modules/auth/services/auth-user.service";
import {hasCustomAccess, isExternal, isInternal, UserInfo} from "@app/modules/auth/models/user.model";
import {AnnouncementService} from "@app/services/announcement/announcement.service";
import {Announcement} from "@app/services/announcement/announcement.model";

type UserState = {
  userInfo: UserInfo,
  announcements: Announcement[] | undefined,
  highlightedAnnouncement: Announcement | undefined,
}

const initialState: UserState = {
  userInfo: {
    initials: "",
    company: "",
    isExternal: false,
    isInternal: false,
    hasCustomAccess: false
  },
  announcements: undefined,
  highlightedAnnouncement: undefined
}

export const UserStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((
    store,
    announcementService = inject(AnnouncementService)
  ) => ({
    announcements: computed(() => announcementService.getAnnouncements(store.userInfo().isInternal, store.userInfo().isExternal)),
    highlightedAnnouncement: computed(() => {
      const announcements = store.announcements();
      return announcements?.find(a => a.highlighted);
    })
  })),
  withMethods((
    store,
    userService=inject(AuthUserService),
    announcementService = inject(AnnouncementService)
  ) =>
    ({
      loadUser: rxMethod<void>(
        pipe(switchMap(() => {
          return userService.getUser().pipe(tap((user) => {
            const announcements = announcementService.getAnnouncements(store.userInfo().isInternal, store.userInfo().isExternal);
            const highlightedAnnouncement = announcements.find(a => a.highlighted);
            const update = {
              userInfo: {
                initials: user.initials,
                company: user.company,
                isExternal: isExternal(user),
                isInternal: isInternal(user),
                hasCustomAccess: hasCustomAccess(user)
              },
              announcements: announcements,
              highlightedAnnouncement: highlightedAnnouncement
            };
            patchState(store, update);
          }))})
        )
      )
    }))
);
