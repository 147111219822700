export interface User {
  initials: string;
  connection: UserConnection;
  company: string;
  roles: string[];
}

export type UserInfo = {
  initials: string,
  company: string;
  isExternal: boolean,
  isInternal: boolean,
  hasCustomAccess: boolean
}

export enum UserConnection {
  BCG = 'bcg-sso',
  KLEIS = 'kleis-sso',
  EXTERNAL = 'External',
}

export enum UserRoles {
  EQOTERRA_USER = 'eqoterra_user',
  EQOSPHERE_USER = 'eqosphere_user',

}

export function isInternal(user: User): boolean {
  return [UserConnection.BCG, UserConnection.KLEIS].includes(user.connection);
}

export function isExternal(user: User): boolean {
  return !isInternal(user);
}
export function hasCustomAccess(user: User): boolean {
  return user.roles.includes(UserRoles.EQOTERRA_USER);
}
export function hasNoCustomAccess(user: User): boolean {
  return !hasCustomAccess(user);
}
