import { Injectable } from '@angular/core';
import { ContributionDto } from '@app/api/__generated__/model/contributionDto';
import {ContributionMapperService} from "@app/services/contribution/contribution-mapper.service";
import {AssessmentGroup} from "@app/model/dataset/assessment.model";

@Injectable({
  providedIn: 'root',
})
export class AssessmentMapperService {
  constructor(private readonly contributionMapper: ContributionMapperService) {}

  asGroups(dtos: ContributionDto[]): AssessmentGroup[] {
    const contributions = this.contributionMapper.fromList(dtos);
    const flattenContributions = this.contributionMapper.flattenFromList(dtos);
    const groupNames = [
      ...new Set(
        contributions.map((c) => c.indicator).flatMap((i) => i.groups)
      ),
    ];

    return groupNames.map((name) => ({
      name,
      contributions: contributions.filter((c) =>
        c.indicator.groups.includes(name)
      ),
      flattenContributions: flattenContributions.filter((c) =>
        c.indicator.groups.includes(name)
      ),
    }));
  }
}
