import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { Subject } from 'rxjs';
import * as fromSearch from '@app/store/characterization-factors';
import {
  selectIndicatorGroups,
  updateCharacterizationFactorsSearchQuery,
} from '@app/store/characterization-factors';
import { skip, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  CharacterizationFactorSearchQuery,
  CharacterizationFactorSearchResult,
  CharacterizationFactorsSearchFilters,
} from '@app/model/characterization-factors/characterization-factor-search.model';
import { MatTableDataSource } from '@angular/material/table';
import { DownloadService } from '@app/modules/core/services/download-service';
import { PageComponent } from '@app/modules/core/components/page-component';
import {
  CharacterizationFactorSearchService
} from "@app/services/characterization-factors/characterization-factor-search.service";
import {FiltersGroupComponent} from "@app/modules/ui/legacy/filters-group/filters-group.component";
import {Filter} from "@app/modules/ui/legacy/filters-group/filters-group.model";

@Component({
  templateUrl: './characterization-factors-search.component.html',
  styleUrl: './characterization-factors-search.component.scss',
})
export class CharacterizationFactorsSearchComponent
  implements PageComponent, OnInit, AfterViewInit, OnDestroy
{
  readonly pageName = 'characterization-factors-search';

  searchForm!: UntypedFormGroup;
  resultsLimit = 500;

  @ViewChild(FiltersGroupComponent, { static: true })
  filtersGroup!: FiltersGroupComponent<keyof CharacterizationFactorSearchQuery>;

  readonly filters: Array<Filter<keyof CharacterizationFactorsSearchFilters>> =
    [
      new Filter('indicatorGroups', 'Indicator group'),
      new Filter('indicators', 'Indicator'),
      new Filter('compartments', 'Compartment'),
      new Filter('subCompartments', 'Subcompartments'),
    ];

  dataSource = new MatTableDataSource<CharacterizationFactorSearchResult>();

  displayedColumns: string[] = [
    'method',
    'indicator',
    'substance',
    'compartment',
    'subCompartment',
    'factor',
    'unit',
  ];

  get resultsAmount(): string {
    return this.dataSource.data.length >= this.resultsLimit
      ? this.resultsLimit.toString() + '+'
      : this.dataSource.data.length.toString();
  }

  get resultsString(): string {
    return this.dataSource.data.length > 1 ? 'results' : 'result';
  }

  firstQuerySent = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private characterizationFactorSearchService: CharacterizationFactorSearchService,
    private downloadService: DownloadService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(fromSearch.loadCharacterizationFactorsFilters());

    this.searchForm = this.formBuilder.group({
      searchTerm: [''],
      filters: this.filtersGroup.getForm(),
    });

    this.store
      .select(fromSearch.selectFiltersOptions())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((results) =>
        this.filters.map((filter) => {
          const options = results[filter.key];
          filter.options$.next(options);
        }),
      );

    this.store
      .select(fromSearch.selectResults())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((results) => (this.dataSource.data = results));

    this.store
      .select(fromSearch.selectResults())
      .pipe(skip(1), take(1))
      .subscribe(() => (this.firstQuerySent = true));
  }

  ngAfterViewInit(): void {
    this.store
      .select(fromSearch.selectForm())
      .pipe(take(1))
      .subscribe((formState: CharacterizationFactorSearchQuery) =>
        this.searchForm.setValue(formState, { emitEvent: false }),
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSubmit(): void {
    this.store.dispatch(
      updateCharacterizationFactorsSearchQuery({
        searchForm:
          this.searchForm.getRawValue() as CharacterizationFactorSearchQuery,
      }),
    );
  }

  export(): void {
    const searchQuery =
      this.searchForm.getRawValue() as CharacterizationFactorSearchQuery;
    this.store
      .select(selectIndicatorGroups)
      .pipe(
        switchMap((allIndicatorGroups) =>
          this.characterizationFactorSearchService.exportSearchResults(
            searchQuery.searchTerm,
            [],
            searchQuery.filters.indicatorGroups.map(
              (group) => allIndicatorGroups.find((m) => m.name === group)!.name,
            ),
            searchQuery.filters.indicators,
            searchQuery.filters.compartments,
            searchQuery.filters.subCompartments,
          ),
        ),
        take(1),
      )
      .subscribe((excelData) => {
        const blob = new Blob([excelData], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        this.downloadService.download(
          'characterization-search-export.xlsx',
          blob,
        );
      });
  }
}
