<button
  [matBadge]="unseen > 0 ? unseen : ''"
  mat-mini-fab
  class="basic"
  [color]="undefined"
  aria-label="Notifications"
  matTooltip="Notifications"
  [disabled]="announcements.length <= 0"
  (click)="showAnnouncements()"
>
  <mat-icon>notifications</mat-icon>
</button>
