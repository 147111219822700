
  <app-history-table
    [dataset]="dataset"
    [historyDetails]="historyDetails"
    (compareDataset)="doCompareWith($event)"
  ></app-history-table>


<app-dataset-compare-view *ngIf="compareDataset"
                          [currentDataset]="dataset"
                          [otherDataset]="compareDataset"
></app-dataset-compare-view>
