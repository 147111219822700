import {Component, Input} from '@angular/core';
import {Dataset, defaultSectionOptions, ExchangeTable, SectionOptions} from "@app/model/dataset/dataset.model";
import {Exchanges, ProductExchange} from "@app/model/dataset/exchange.model";
import {EmbeddedProduct} from "@app/model/dataset/embedded.product.model";
import {ExchangeEmbeddedProduct} from "@app/pages/dataset-details/embedded-product/models/embedded-product.model";

@Component({
  selector: 'app-embedded-product-exchanges',
  templateUrl: './embedded-product-exchanges.component.html',
  styleUrl: './embedded-product-exchanges.component.scss',
})
export class EmbeddedProductExchangesComponent {
  @Input() dataset!: Dataset;
  @Input() exchanges!: Exchanges;
  @Input() selectedProduct!: EmbeddedProduct;
  @Input() showPercentages = false;
  @Input() showActivity = false;
  @Input() embeddedExchanges!: ExchangeEmbeddedProduct[] | null;
  @Input() productExchange!: ProductExchange;
  @Input() datasetId!: string;
  expandedCommentId: string | undefined;


  get options(): SectionOptions {
    return {
      ...defaultSectionOptions,
      totalAssessment: this.selectedProduct.amount,
      displayedKey: 'embedded-' + this.selectedProduct.type,
      expandedCommentId: this.expandedCommentId,
    }
  }

  get optionsWithPercentage(): SectionOptions {
    return {...this.options, showPercentages: this.showPercentages};
  }

  get optionsWithPercentageAndGreyed(): SectionOptions {
    return {...this.optionsWithPercentage, isGreyed: true};
  }

  get optionsWithUnit(): SectionOptions {
    return {...this.options, unit: this.selectedProduct.unit, isGreyed: false};
  }

  get hasCoProducts(): boolean {
    return this.dataset.referenceProduct.coProductSection.records.length > 0;
  }

  onExpandedCommentIdChange(expandedCommentId: string): void {
    this.expandedCommentId = expandedCommentId;
  }

  get activeProduct(): ExchangeTable {
    if (this.showActivity && this.dataset.activity) return this.dataset.activity;
    return this.dataset.referenceProduct;
  }


}
