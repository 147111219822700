import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Dataset, ImpactIndicator} from '@app/model/dataset/dataset.model';
import {HistoryDetail} from "@app/model/dataset/history.models";
import {CompareService} from "@app/services/datasets/compare/compare.service";

@Component({
  selector: 'app-history-table',
  templateUrl: './history-table.component.html',
  styleUrl: './history-table.component.scss',
})
export class HistoryTableComponent {
  _dataset!: Dataset;
  _historyDetails: HistoryDetail[] = [];
  maxAmount: number = 0;
  currentAmount: number = 0;

  @Input()
  set historyDetails(details: HistoryDetail[]) {
    this._historyDetails = details;
  }
  @Input()
  set dataset(dataset: Dataset) {
    this._dataset = dataset;
    this.currentAmount = this.compareService.getAssessmentSampleAmount(this._dataset.impactIndicatorSamples)
    this.maxAmount = this.compareService.getMaxSampleAmount(dataset);
  }

  get dataset(): Dataset {
    return this._dataset;
  }

  get historyDetails(): HistoryDetail[] {
    return this._historyDetails;
  }

  @Output()
  compareDataset = new EventEmitter<string>;

  constructor(
    private compareService: CompareService
  ) {
  }

  isCurrent(detail: HistoryDetail): boolean {
    return detail.productId === this.dataset.id;
  }

  triggerCompareWith(datasetId: string): void {
    this.compareDataset.emit(datasetId);
  }

  tooltip(detail: HistoryDetail): string {
    if (this.isCurrent(detail)) {
      return this.percentage(detail).toFixed(0) + '%';
    }
    const relativePercentage = this.percentage(detail) - 100;
    const stringPercentage = relativePercentage.toFixed(0) + "%";
    return (relativePercentage > 0) ? "+" + stringPercentage : "" + stringPercentage;
  }

  percentage(detail: HistoryDetail): number {
    return (this.getAssessmentSampleAmount(detail) / this.currentAmount) * 100;
  }

  getAssessmentSampleAmount(detail: HistoryDetail): number {
    return this.getAssessmentSample(detail)?.amount ?? 0;
  }

  getAssessmentSample(detail: HistoryDetail): ImpactIndicator| undefined {
    return this.compareService.getAssessmentSample(detail.impactIndicatorSamples);
  }
}
