import {Component, EventEmitter, inject, OnDestroy, OnInit, Output} from '@angular/core';
import {
  DATABASE_GROUPS,
  DatasetSearchRequestPatch,
  NO_FILTERS,
  SuggestionSelection,
} from '@app/model/dataset/dataset-search.model';
import {Subject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {DatasetSearchStore} from "@app/store/search.store";
import {
  FilterSelectionChange
} from "@app/pages/datasets-search/datasets-search.model";

@Component({
  selector: 'app-datasets-search-view',
  templateUrl: './datasets-search-view.component.html',
  styleUrl: './datasets-search-view.component.scss',
})
export class DatasetsSearchViewComponent implements OnDestroy, OnInit {
  readonly store = inject(DatasetSearchStore);
  sidebarOpened: boolean = true;
  showResultsDetails = true;

  @Output()
  requestUpdate = new EventEmitter<DatasetSearchRequestPatch>();

  searchForm!: UntypedFormGroup;

  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchTerm: ['']
    });
  }

  onSuggestionSelection(userQuery: string, suggestionSelection: SuggestionSelection): void {
    const currentSearchTerm = userQuery.toLowerCase();
    const updatedSearchTerm = currentSearchTerm.replace(
      suggestionSelection.originalText.toLowerCase(),
      suggestionSelection.suggestion
    ) ?? '';
    this.onUserQueryUpdate(updatedSearchTerm);
  }

  onFilterSelectionChangeEvent({field, selection}: FilterSelectionChange): void {
    this.requestUpdate.emit({
      filters: {
        [field]: selection
      }
    });
  }

  onClearFilters(): void {
    this.requestUpdate.emit({
      filters: NO_FILTERS
    })
  }

  onUserQueryUpdate($event: string): void {
    this.requestUpdate.emit({
      searchTerm: $event,
    });
  }

  onShowResultsDetailsChange(showResultsDetails: boolean): void {
    this.showResultsDetails = showResultsDetails;
  }

  protected readonly DATABASE_GROUPS = DATABASE_GROUPS;
}
