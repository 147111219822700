import { Component, Input } from '@angular/core';
import { ImpactAssessmentSample } from '@app/model/dataset/dataset.model';

@Component({
  selector: 'app-impact-factor-sample-list[factorSamples]',
  templateUrl: './impact-factor-sample-list.component.html',
  styleUrl: './impact-factor-sample-list.component.scss',
})
export class ImpactFactorSampleListComponent {
  @Input()
  factorSamples!: Array<ImpactAssessmentSample>;
}
