import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '@env/environment';
import {AwsRum, AwsRumConfig, PageAttributes} from 'aws-rum-web';
import {map, Observable, take} from 'rxjs';
import {AuthUserService} from '../auth/services/auth-user.service';
import {
  RumBasketExport,
  RumBasketImport,
  RumBasketUpdate,
  RumContribution,
  RumContributionView,
  RumDataset,
  RumDatasetSearch,
  RumEnriched,
  RumFilterByFacetView,
  RumIndicatorView,
  RumPayLoad,
  RumTabChange
} from './rum.model';
import {DatasetService} from "@app/services/datasets/dataset.service";
import {BasketExportOptions} from "@app/model/basket/basket.model";
import {ContributionSelection} from "@app/model/contribution-selection/contribution-selection.model";
import {
  findCategoryOf,
  findCpcOf,
  findIsicOf
} from "@app/modules/reference-data/classification/models/classification.model";
import {Dataset} from "@app/model/dataset/dataset.model";
import {DatasetSearchRequest, DatasetSearchResult} from "@app/model/dataset/dataset-search.model";
import {FilterSelectionChange} from "@app/pages/datasets-search/datasets-search.model";

@Injectable({
  providedIn: 'root',
})
export class RumService {
  private readonly rumClient: AwsRum | undefined;
  constructor(
    private readonly router: Router,
    private readonly userService: AuthUserService,
    private readonly datasetService: DatasetService,
  ) {
    this.rumClient = setupAwsRum(environment);
  }

  rumContributionOf(options: ContributionSelection): RumContribution {
    return {
      name: options.contribution!.name,
      indicator: options.indicator!.name,
      type: options.contributionType as string,
      method: options.indicator!.method,
      indicatorGroup: options.group?.name
    };
  }

  rumDatasetOf(dataset: Dataset): RumDataset {
    const category = findCategoryOf(dataset)?.description || "";
    const isic =  findIsicOf(dataset)?.description || "";
    const cpc =  findCpcOf(dataset)?.description || "";
    return {
      uuid: dataset.id,
      dataset: dataset.referenceProduct.name,
      database: dataset.description.database.simpleDisplayName,
      isic: isic,
      cpc: cpc,
      category: category
    }
  }

  triggerContributionsView(dataset: Dataset, selection: ContributionSelection): void {
    const rumDataset = this.rumDatasetOf(dataset);
    const rumContribution = this.rumContributionOf(selection);
    const payload = {
      action: 'contribution-view',
      ...rumDataset,
      ...rumContribution,
    } as RumContributionView ;
    this.triggerCustomEvent(payload);
  }

  triggerIndicatorsView(dataset: Dataset, indicators: string[]): void {
    const rumDataset = this.rumDatasetOf(dataset);
    const payload = {
      action: 'indicators-view',
      indicators: indicators,
      ...rumDataset,
    } as RumIndicatorView ;
    this.triggerCustomEvent(payload);
  }

  triggerDatasetViewEvent(dataset: Dataset): void {
    const rumDataset = this.rumDatasetOf(dataset);
    this.triggerPageViewEvent({
      action: 'dataset',
      ...rumDataset
    },
    this.router.url);
  }

  triggerDatasetCustomizeEvent(dataset: Dataset, url: string): void {
    const rumDataset = this.rumDatasetOf(dataset);
    this.triggerPageViewEvent({
      action: 'dataset-customize',
      ...rumDataset
    },
    url);
  }

  triggerTabChangeEvent(dataset: Dataset, tabLabel: string): void {
    const rumDataset = this.rumDatasetOf(dataset);
    this.triggerPageViewEvent({
      action: 'dataset-tab',
      tab: tabLabel,
      ...rumDataset
    } as RumTabChange,
      this.router.url.replaceAll("#.*$","") + "#" + encodeURIComponent(tabLabel)
    );
  }

  triggerFacetUseEvent(selectionChange: FilterSelectionChange): void {
    this.triggerCustomEvent({
        action: 'filter-by-facet',
        facetName: selectionChange.field,
        selection: selectionChange.selection.map(x => x.value)
      } as RumFilterByFacetView,
    );
  }

  triggerSearchEvent(searchForm: DatasetSearchRequest): void {
    const datasetSearch : RumDatasetSearch = {
      action: 'dataset-search',
      dataset: searchForm.searchTerm, // deprecated => searchTerm
      searchTerm: searchForm.searchTerm,
      database: searchForm.filters.databases.join(', '),
    }
    this.triggerPageViewEvent(datasetSearch, this.router.url);
  }

  triggerBasketImportEvent(options: DatasetSearchResult[]): void {
    options.forEach((result) => {
        this.datasetService.getDataset(result.id).subscribe(
          (dataset) => {
            const rumDataset = this.rumDatasetOf(dataset);
            const payload = {
              ...rumDataset,
              action: "basket-import",
              indicatorGroups: result.groups,
            } as RumBasketImport;
            this.triggerCustomEvent(payload)
          }
        );
      }
    )
  }

  triggerBasketExportEvent(options: BasketExportOptions): void {
    options.datasetIds.forEach((datasetId) => {
        // TODO refactor BasketExportOptions to include full object and not just ids
        this.datasetService.getDataset(datasetId).subscribe(
          (dataset) => {
            const rumDataset = this.rumDatasetOf(dataset);
            const payload = {
              ...rumDataset,
              action: "basket-export",
              indicatorGroups: options.groups,
            } as RumBasketExport;
            this.triggerCustomEvent(payload)
          }
        );
      }
    )
  }

  triggerBasketUpgradeEvent(datasets: Array<Dataset>): void {
    datasets.forEach((dataset) => {
      const rumDataset = this.rumDatasetOf(dataset);
      const payload = {
        ...rumDataset,
        action: "basket-update"
      } as RumBasketUpdate;
      this.triggerCustomEvent(payload);
    });
  }

  triggerCustomEvent(payload: RumPayLoad, url: string | null = null): void {
    this.enrichEventPayload(payload, url).subscribe((enriched) => {
      if (environment.enableRum) {
        this.rumClient?.recordEvent(payload.action, enriched)
      }
    });
  }

  triggerPageViewEvent(payload: RumPayLoad, url: string): void {
    this.enrichEventPayload(payload, url).subscribe((enriched) => {
      const pageAttributes = {
        pageId: url,
        pageTags: [ payload.action ],
        pageAttributes: {
          ...enriched
        }
      } as PageAttributes;
      if (environment.enableRum) {
        this.rumClient?.recordPageView(pageAttributes)
      }
    });
  }

  private enrichEventPayload(payload: RumPayLoad, url: string | null): Observable<RumEnriched> {
    const eventUrl = url ?? this.router.routerState.snapshot.url;
    return this.userService
      .getUserCompany()
      .pipe(
        take(1),
        map((company) => {
          return {
            eventUrl,
            company,
            ...payload
          } as RumEnriched;
        }),
      )
  }
}

const setupAwsRum = (env: {
  rum: { identityPoolId: string; appMonitorId: string };
}): AwsRum | undefined => {
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      disableAutoPageView: true,
      identityPoolId: env.rum.identityPoolId,
      endpoint: 'https://dataplane.rum.eu-west-1.amazonaws.com',
      allowCookies: false,
      enableXRay: false,
    };

    return new AwsRum(env.rum.appMonitorId, '1.0.0', 'eu-west-1', config);
  } catch (error) {
    console.error('aws-rum', error);
    return undefined;
  }
};
