import {Geography} from '@app/modules/reference-data/geography/models/geography.model';
import {
  BucketKey, CriteriaParam,
  CriteriaValue,
  DatasetSearchFacets
} from "@app/pages/datasets-search/datasets-search.model";
import {DatasetDescription, ImpactAssessmentSample} from "@app/model/dataset/dataset.model";
import {FacetItemNode} from "@app/modules/ui/components/facet-tree/facet-tree.model";

export type DatasetQueryParams = {
  q: string;
  database: Array<string>;
  geography: Array<string>;
  geoContinent: Array<string>;
  geoCountry: Array<string>;
  geoProvince: Array<string>;
  activityType: Array<string>;
  unit: Array<string>;
  isicCode: Array<string>;
  isicGroup: Array<string>;
  isicDivision: Array<string>;
  isicSection: Array<string>;
  cpcSubClass: Array<string>;
  cpcMainClass: Array<string>;
  cpcGroup: Array<string>;
  cpcDivision: Array<string>;
  cpcSection: Array<string>;
};

export type DatasetSearchRequest = {
  searchTerm: string;
  filters: DatasetSearchFilters;
};

export type DatasetSearchRequestPatch = {
  searchTerm?: string;
  filters?: Partial<DatasetSearchFilters>;
};

export type DatasetSearchFilters = {
  databases: CriteriaValue[];
  geographies: CriteriaValue[];
  activityTypes: CriteriaValue[];
  units: CriteriaValue[];
  isics: CriteriaValue[];
  cpcs: CriteriaValue[];
};

export const NO_FILTERS: DatasetSearchFilters  = {
  databases: [],
  geographies: [],
  activityTypes: [],
  units: [],
  isics: [],
  cpcs: [],
};

export type FacetBucket = {
  key: CriteriaParam,
  bucketName: string;
  docCount: number;
  buckets?: FacetBucket[];
};


export type Facet = {
  key: BucketKey;
  buckets: FacetBucket[],
};

export type FacetTree = {
  key: BucketKey,
  tree: FacetItemNode[],
}

export type DatasetSearchResponse = {
  results: DatasetSearchResult[];
  facets: DatasetSearchFacets;
  suggestion?: Suggestion;
  totalDocCount: number;
  maxDocCount: number;
};

export type Suggestion = {
  originalText: string;
  suggestions: string[];
};

export type SuggestionSelection = {
  originalText: string;
  suggestion: string;
};

export type GeographyWithDisplayName = Geography & {
  displayName: string;
};

export class DatasetSearchResult {
  id: string;
  name: string;
  unit: string;
  description: DatasetDescription;
  groups: string[];
  impactIndicatorSamples: Array<ImpactAssessmentSample>;
  isInBasket: boolean;

  constructor(
    id: string,
    name: string,
    unit: string,
    description: DatasetDescription,
    groups: string[],
    impactIndicatorSamples: Array<ImpactAssessmentSample>,
  ) {
    this.id = id;
    this.name = name;
    this.unit = unit;
    this.description = description;
    this.groups = groups;
    this.impactIndicatorSamples = impactIndicatorSamples;
    this.isInBasket = false;
  }
}

export const DATABASE_GROUPS = [
  { key: 'LIVE', label: 'Default' },
  { key: 'LATEST', label: 'Latest' } ,
  { key: 'DEPRECATED', label: 'Obsolete' }
];
