import { NgModule } from '@angular/core';
import {
  ActivityTypeEnumToNamePipe
} from "@app/modules/reference-data/activity-type/pipes/activity-type-enum-to-name.pipe";

@NgModule({
  declarations: [ActivityTypeEnumToNamePipe],
  exports: [ActivityTypeEnumToNamePipe],
  providers: [ActivityTypeEnumToNamePipe],
})
export class ActivityTypeModule {}
