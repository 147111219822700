<div class="search" fxLayout="column" fxLayoutAlign="stretch" *ngIf="store.searchRequest() as searchRequest">
  <div class="search-form">

    <app-datasets-search-form
      [userQuery]="searchRequest.searchTerm"
      (userQueryUpdate)="onUserQueryUpdate($event)"
      (showResultsDetailsChange)="onShowResultsDetailsChange($event)"
    ></app-datasets-search-form>

    <div *ngIf="store.dropDowns() as dropDowns">
      <div class="search-bar">
        <app-datasets-search-dropdown
          criteriaCategory="geographies"
          [menuItems]="dropDowns.geographies"
          [selection]="searchRequest.filters.geographies"
          (changed)="onFilterSelectionChangeEvent($event)"
        >
        </app-datasets-search-dropdown>

        <app-datasets-search-dropdown
          criteriaCategory="databases"
          [groups]="DATABASE_GROUPS"
          [menuItems]="dropDowns.databases"
          [selection]="searchRequest.filters.databases"
          (changed)="onFilterSelectionChangeEvent($event)"
        >
        </app-datasets-search-dropdown>

        <app-datasets-search-dropdown
          criteriaCategory="activityTypes"
          [menuItems]="dropDowns.activityTypes"
          [selection]="searchRequest.filters.activityTypes"
          (changed)="onFilterSelectionChangeEvent($event)"
        >
        </app-datasets-search-dropdown>

        <app-datasets-search-dropdown
          criteriaCategory="units"
          [menuItems]="dropDowns.units"
          [selection]="searchRequest.filters.units"
          (changed)="onFilterSelectionChangeEvent($event)"
        >
        </app-datasets-search-dropdown>

        <app-datasets-search-dropdown
          criteriaCategory="isics"
          [menuItems]="dropDowns.isics"
          [selection]="searchRequest.filters.isics"
          (changed)="onFilterSelectionChangeEvent($event)"
        >
        </app-datasets-search-dropdown>
      </div>
    </div>

    <app-chips-container *ngIf="store.chipGroups() as chipGroups"
                         [chipGroups]="chipGroups"
                         (filterSelectionChange)="onFilterSelectionChangeEvent($event)"
                         (clearFilters)="onClearFilters()"
    ></app-chips-container>

  </div>

  <div class="search-toolbar">
    <button mat-icon-button [matTooltip]="sidebarOpened ? 'Hide filters' : 'Show filters'" (click)="sidebarOpened = !sidebarOpened">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div [class]="sidebarOpened ? 'sidebar-container opened' : 'sidebar-container closed'">
    <div class="sidebar">
      <app-datasets-search-sidebar
        [facets]="store.facets()"
        [searchRequest]="searchRequest"
        (selectionChange)="onFilterSelectionChangeEvent($event)"
      >
      </app-datasets-search-sidebar>
    </div>
    <div class="sidebar-content">
      <app-datasets-search-results fxFlex="100" fxFlexFill
                                   [searchResponse]="store.searchResponse()"
                                   [showResultsDetails]="showResultsDetails"
                                   [isLoading]="store.responseLoading()"
                                   (suggestionSelection)="onSuggestionSelection(searchRequest.searchTerm, $event)"
      ></app-datasets-search-results>
    </div>
  </div>
</div>
