import {AuthGuard, AuthService, IdToken} from "@auth0/auth0-angular";
import {ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment} from "@angular/router";
import {filter, map, Observable, switchMap} from "rxjs";
import {Injectable} from "@angular/core";
import {UserRoles} from "@app/modules/auth/models/user.model";

type EqosphereToken = { user_roles: Array<UserRoles> } & IdToken


@Injectable({
  providedIn: 'root',
})
export class Auth0Guard extends AuthGuard {

  private authService: AuthService

  constructor(auth: AuthService) {
    super(auth);
    this.authService = auth;
  }

  private isAuthenticatedWithRole(authenticated: boolean = true): Observable<boolean> {
    return this.authService.idTokenClaims$
      .pipe(filter((token: IdToken | null | undefined): token is EqosphereToken => !!token),
        map((token: EqosphereToken): boolean => token.user_roles.includes(UserRoles.EQOSPHERE_USER)))
      .pipe(map(hasRole => authenticated && hasRole));
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return super.canLoad(route, segments).pipe(switchMap(authenticated => this.isAuthenticatedWithRole(authenticated)));
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return super.canActivate(next, state).pipe(switchMap(authenticated => this.isAuthenticatedWithRole(authenticated)));
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return super.canActivateChild(childRoute, state).pipe(switchMap(authenticated => this.isAuthenticatedWithRole(authenticated)));
  }
}
