import { Injectable } from '@angular/core';
import { UnitDto } from '@app/api/__generated__/model/unitDto';
import { AbstractMapper } from '@app/modules/core/mappers/abstract-mapper';
import { Unit } from '../models/unit.model';
import { FormatUnitPipe } from '@app/modules/reference-data/unit/pipes/format-unit.pipe';

@Injectable({
  providedIn: 'root',
})
export class UnitMapperService extends AbstractMapper<UnitDto, Unit> {
  constructor(private formatUnitPipe: FormatUnitPipe) {
    super();
  }

  from(dto: UnitDto | undefined): Unit {
    if (dto === undefined) {
      throw new Error('unit dto is undefined');
    }
    const { name, description } = dto;
    const formattedName = this.formatUnitPipe.transform(name) ?? '';
    return { name, formattedName, description };
  }
}
