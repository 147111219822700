import {Injectable} from "@angular/core";
import {Facet, FacetBucket, FacetTree} from "@app/model/dataset/dataset-search.model";
import {
  CriteriaCategory,
  CRITERIA_CATEGORY_LABELS,
  CriteriaName,
  DatasetSearchDropDowns,
  DatasetSearchFacets, DatasetSearchFacetTrees, CriteriaValue, criteriaKey
} from "@app/pages/datasets-search/datasets-search.model";
import {DropDownMenuItem} from "@app/modules/ui/components/drop-down-menu/drop-down-menu.model";
import {Chip, ChipGroup} from "@app/modules/ui/components/chips-container/chips-container.model";
import {FacetItemNode} from "@app/modules/ui/components/facet-tree/facet-tree.model";

@Injectable({
  providedIn: 'root',
})
export class DatasetSearchMapperService {

  enrichDropdownsWithFacets(dropdowns: DatasetSearchDropDowns, facets: DatasetSearchFacets): DatasetSearchDropDowns {
    return {
      databases: this.enrichMenuWithFacet(dropdowns.databases, facets.databases?.buckets),
      geographies: this.enrichMenuWithFacet(dropdowns.geographies, facets.geographies ? this.flattenBuckets(facets.geographies.buckets) : []),
      activityTypes: this.enrichMenuWithFacet(dropdowns.activityTypes, facets.activityTypes?.buckets),
      units: this.enrichMenuWithFacet(dropdowns.units, facets.units?.buckets),
      isics: this.enrichMenuWithFacet(dropdowns.isics, facets.isics ? this.flattenBuckets(facets.isics.buckets) : []),
      cpcs: [] // CPCs are only used as facet
    };
  }

  enrichMenuWithFacet(menu: DropDownMenuItem[], buckets: FacetBucket[] | undefined): DropDownMenuItem[] {
    if (!buckets) {
      return menu;
    }
    const countMap = new Map(buckets.map(b => [criteriaKey({param: b.key, value: b.bucketName}), b.docCount]));
    return menu.map(item => {
      const newCount = countMap.get(item.value);
      const newStyle = newCount ? "font-weight: bold" : undefined;
      return {
        ...item,
        count: newCount,
        style: newStyle
      }
    }).sort(compareMenuItems);
  }

  makeChipGroup(criteria: CriteriaName, values: CriteriaValue[], labelMap: Map<string,string>): ChipGroup {
    const chips: Chip[] = values.map(v => {
      const label = (labelMap.has(criteriaKey(v)) ? labelMap.get(criteriaKey(v)) : labelMap.get(v.value)) || v.value;
      return {
        value: v,
        label: label
      }
    });
    return {
      key: criteria,
      label: CRITERIA_CATEGORY_LABELS[criteria],
      items: chips
    };
  }

  enrichFacetsWithLabels(facets: DatasetSearchFacets, dropDowns: DatasetSearchDropDowns): DatasetSearchFacetTrees {
    const isicKeyMap = new Map(dropDowns.isics.map(menu => [menu.value, menu.label]));
    const geographiesKeyMap = new Map(dropDowns.geographies.map(menu => [menu.value, menu.label]));
    const cpcKeyMap = new Map(dropDowns.cpcs.map(menu => [menu.value, menu.label]));
    return {
      isics: this.labeledTreeOfFacet(facets.isics, isicKeyMap),
      geographies: this.labeledTreeOfFacet(facets.geographies, geographiesKeyMap),
      cpcs: this.labeledTreeOfFacet(facets.cpcs, cpcKeyMap)
    }
  }

  private labeledTreeOfFacet(f: Facet | undefined, labelMap: Map<string, string>): FacetTree | undefined {
    return f ? {
        key: f.key,
        tree: this.labeledTreeOfBucket(f.buckets, labelMap)
      } : undefined
  }

  private labeledTreeOfBucket(buckets: FacetBucket[], labelMap: Map<string, string>): FacetItemNode[] {
    const totalDocs = buckets.reduce((sum, bucket) => sum + bucket.docCount, 0);
    return buckets.map((b) => this.facetItemNodeOf(b, totalDocs, labelMap));
  }

  private flattenBuckets(buckets:  FacetBucket[]):  FacetBucket[] {
    return buckets.flatMap(bucket => {
      if (bucket.buckets) {
        const descendantBuckets = this.flattenBuckets(bucket.buckets);
        return [bucket].concat(descendantBuckets);
      } else {
        return [bucket]
      }
    });
  }

  private facetItemNodeOf(b: FacetBucket, totalDocs: number, labelMap: Map<string, string>): FacetItemNode {
    const children = b.buckets ? b.buckets.map(f => this.facetItemNodeOf(f, totalDocs, labelMap)) : []
       const criteria = { param: b.key, value: b.bucketName } satisfies CriteriaValue;
       return {
        item: criteria,
        label: labelMap.get(criteriaKey(criteria)) || labelMap.get(b.bucketName) || b.bucketName,
        count: b.docCount,
        percent: b.docCount / totalDocs,
        children: children
      }
  }

  makeLabelMaps(rawDropDowns: DatasetSearchDropDowns): CriteriaCategory<Map<string, string>> {
    return {
      activityTypes: this.labelMapOf(rawDropDowns.activityTypes),
      databases: this.labelMapOf(rawDropDowns.databases),
      geographies: this.labelMapOf(rawDropDowns.geographies),
      isics: this.labelMapOf(rawDropDowns.isics),
      units: this.labelMapOf(rawDropDowns.units),
      cpcs: this.labelMapOf(rawDropDowns.cpcs)
    }
  }

  private labelMapOf(menu: DropDownMenuItem[]): Map<string, string> {
    return new Map(menu.map(x => [ x.value, x.label]));
  }
}

function compareMenuItems(a: DropDownMenuItem, b: DropDownMenuItem): number {
  const aCount = a.count || 0;
  const bCount = b.count || 0;
  return aCount == bCount ? 0 : aCount > bCount ? -1 : 1;
}

