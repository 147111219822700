export type AnnouncementTarget = "internal" | "external" | "all";

export type Announcement = {
  id: string;
  target: AnnouncementTarget,
  expiration: Date,
  highlighted: boolean
}

export const ANNOUNCEMENTS: Announcement[] = [
  {
    id: "april-2025-webinar-2",
    target: "external",
    expiration: new Date("2025-04-08"),
    highlighted: true
  }
]
