import { TreeDto } from '@app/api/__generated__/model/treeDto';
import { NodeDto } from '@app/api/__generated__/model/nodeDto';
import { LinkDto } from '@app/api/__generated__/model/linkDto';
import { assert } from '@app/modules/core/utils/assert-utils';
import {SankeyLink, SankeyNode} from "@app/pages/dataset-details/exchange/modules/sankey/model/sankey.model";

export function hasData(tree: TreeDto | undefined): tree is {
  nodes?: Array<NodeDto>;
  links?: Array<LinkDto>;
} {
  return (
    tree !== undefined &&
    tree.nodes !== undefined &&
    tree.links !== undefined &&
    tree.nodes.length > 0 &&
    tree.links.length > 0
  );
}

export function targetNode(link: SankeyLink): SankeyNode {
  assert(typeof link.target !== 'number');
  return link.target;
}
