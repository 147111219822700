import {Component, EventEmitter, Input, Output,} from '@angular/core';
import {
  DatasetSearchFacetTrees,
  FilterSelectionChange
} from "@app/pages/datasets-search/datasets-search.model";
import {DatasetSearchRequest, FacetTree} from "@app/model/dataset/dataset-search.model";

@Component({
  selector: 'app-datasets-search-sidebar',
  templateUrl: './datasets-search-sidebar.component.html',
  styleUrl: './datasets-search-sidebar.component.scss',
})
export class DatasetsSearchSidebarComponent
{
  @Input()
  set facets(facets: DatasetSearchFacetTrees) {
    this.isicFacet = facets.isics;
    this.geographyFacet = facets.geographies;
    this.cpcFacet = facets.cpcs;
  }

  @Input()
  searchRequest!: DatasetSearchRequest;

  @Output()
  selectionChange = new EventEmitter<FilterSelectionChange>

  isicFacet: FacetTree | undefined;
  geographyFacet: FacetTree | undefined;
  cpcFacet: FacetTree | undefined;

}
