import {Component, EventEmitter, Input, Output} from "@angular/core";
import {
  DropDownMenuGroup,
  DropDownMenuItem
} from "@app/modules/ui/components/drop-down-menu/drop-down-menu.model";
import {
  CRITERIA_CATEGORY_LABELS, CriteriaCategory,
  CriteriaCategoryKeys, criteriaKey, criteriaOfKey, CriteriaValue,
  FilterSelectionChange
} from "@app/pages/datasets-search/datasets-search.model";
import {MatFormFieldAppearance} from "@angular/material/form-field";

@Component({
  selector: 'app-datasets-search-dropdown',
  templateUrl: './datasets-search-dropdown.component.html',
  //styleUrl: './datasets-search-dropdown.component.scss',
})
export class DatasetsSearchDropdownComponent {
  @Input() groups?: DropDownMenuGroup[];
  @Input() criteriaCategory!: CriteriaCategoryKeys<unknown>;
  @Input() menuItems!: DropDownMenuItem[];
  @Input()
  set selection(selection: CriteriaValue[]) {
     this._selection = selection.map(criteriaKey);
  }
  @Output() changed = new EventEmitter<FilterSelectionChange>();
  _selection: string[] = [];

  @Input()
  appearance?: MatFormFieldAppearance;

  onFilterSelectionChange($event: string[]): void {
    const selection = $event.map(criteriaOfKey);
    this.changed.emit({ field: this.criteriaCategory, selection: selection})
  }

  labelFor(criteria: keyof CriteriaCategory<string>): string {
    return CRITERIA_CATEGORY_LABELS[criteria];
  }
}
