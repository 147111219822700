import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatabaseApiService } from '@app/modules/reference-data/database/api/database-api.service';
import { map } from 'rxjs/operators';
import { DatabaseMapperService } from '../mappers/database-mapper.service';
import { Database } from '../models/database.model';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  constructor(
    private databaseApiService: DatabaseApiService,
    private mapper: DatabaseMapperService
  ) {}

  findSortedDatabases(excludeObsolete: boolean = false): Observable<Database[]> {
    return this.databaseApiService.getDatabases().pipe(
      map((response) => {
        const databases = this.mapper
          .fromList(response.results)
          .sort((a, b) => a.displayName.localeCompare(b.displayName))

        const liveDatabases: Database[] = [];
        const latestDatabases: Database[] = [];
        const obsoleteDatabases: Database[] = [];
        for(const db of databases) {
          this.sortBackgroundDatabases(db);
          switch (db.status) {
            case "LIVE":
              liveDatabases.push(db);
              break;
            case "LATEST":
              latestDatabases.push(db);
              break;
            case "DEPRECATED":
              !excludeObsolete && obsoleteDatabases.push(db);
              break;
          }
        }

        return liveDatabases
          .concat(latestDatabases)
          .concat(obsoleteDatabases);
      })
    );
  }

  private sortBackgroundDatabases(db: Database): Database {
    db.background.sort((a: Database, b: Database) => {
      const nameComparison = a.name.localeCompare(b.name);
      return nameComparison !== 0
        ? nameComparison
        : a.version.localeCompare(b.version);
    });

    return db;
  }

  findDefaultDatabase(): Observable<Database[]> {
    return this.databaseApiService.getDefaultDatabases().pipe(
      map((response) => this.mapper.fromList(response.results)));
  }
}
