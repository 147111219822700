import {DropDownMenuItem} from "@app/modules/ui/components/drop-down-menu/drop-down-menu.model";
import {Facet, FacetTree} from "@app/model/dataset/dataset-search.model";

export type CriteriaCategory<T> = {
  databases: T,
  geographies: T,
  activityTypes: T,
  units: T,
  isics: T,
  cpcs: T
}
export type CriteriaCategoryKeys<T> = keyof CriteriaCategory<T>;

export type CriteriaParam =
  "geography" | "geoProvince" | "geoCountry" | "geoContinent" |
  "database" |
  "isicSection" | "isicDivision" | "isicGroup" | "isicCode" |
  "activityType" |
  "unit" |
  "cpcSection" | "cpcDivision" | "cpcGroup" | "cpcMainClass" | "cpcSubClass"


export type CriteriaValue = {
  param: CriteriaParam;
  value: string;
}

export type CriteriaName = CriteriaCategoryKeys<unknown>;
export const CRITERIA_CATEGORIES: CriteriaCategoryKeys<Facet>[] = [
  "geographies",
  "databases",
  "activityTypes",
  "units",
  "isics",
  "cpcs"
]

export const CRITERIA_CATEGORY_LABELS: CriteriaCategory<string> = {
  databases: "Database",
  geographies: "Geography",
  activityTypes: "Activity Type",
  units: "Unit",
  isics: "Economic Activity (ISIC)",
  cpcs: "Product Category (CPC)"
};

export const enum BucketKey {
  DATABASE = 'database',
  GEOGRAPHY = 'geoContinent',
  ACTIVITY_TYPE = 'activityType',
  UNIT = 'unit',
  ISIC = 'isicSection',
  CPC = 'cpcSection'
}

export type DatasetSearchDropDowns = CriteriaCategory<DropDownMenuItem[]>;
export type DatasetSearchFacets = Partial<CriteriaCategory<Facet>>
export type DatasetSearchFacetTrees = Partial<CriteriaCategory<FacetTree>>

export type FilterSelectionChange = {
  field: CriteriaName,
  selection: CriteriaValue[]
}

export function criteriaKey(criteria: CriteriaValue): string {
  return criteria.param+CRITERIA_SEPARATOR+ criteria.value;
}

export function criteriaOfKey(key: string): CriteriaValue {
  const [ param, value ] = key.split(CRITERIA_SEPARATOR)
  return {
    param: param as CriteriaParam,
    value: value
  }
}

export const CRITERIA_SEPARATOR = "=";
