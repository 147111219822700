import {Component, Input} from '@angular/core';
import {ExchangeHeader} from "@app/model/dataset/exchange.model";

@Component({
  selector: '[exchange-line-header][header]',
  template: `
    <div class="spacer"></div>
    <tr>
      <th [colSpan]="getTitleColSpan()"><h2>{{ header.title }}</h2></th>
      <ng-container *ngIf="header.detail1">
        <th>{{ header.detail1 }}</th>
      </ng-container>
      <ng-container *ngIf="header.detail2">
        <th>{{ header.detail2 }}</th>
      </ng-container>
      <ng-container *ngIf="header.hasQuantity">
        <th>Amount</th>
        <th>Unit</th>
      </ng-container>
      <ng-container *ngIf="quantityHeader">
        <th
          [ngClass]="[
        'contribution',
      ]"
        >
          {{ quantityHeader }}
        </th>
      </ng-container>
      <ng-container *ngIf="!quantityHeader">
        <th
          *ngFor="let emissionFactor of header.assessments"
          [ngClass]="[
        'contribution',
        'header'
      ]"
        >
          {{ emissionFactor }}
        </th>
      </ng-container>
      <td colspan="2"></td>
    </tr>
  `,
  styleUrl: './exchange-line-header.component.scss',
})
export class ExchangeLineHeaderComponent {
  @Input() header!: ExchangeHeader;
  @Input() quantityHeader!: string;

  getTitleColSpan(): number {
    if (this.header.detail1) { return 1}
    if (this.header.detail2) { return 2}
    if (this.header.hasQuantity) { return 3}
    return 5;
  }
}


