import {criteriaKey, CriteriaValue} from "@app/pages/datasets-search/datasets-search.model";
import {FacetTree} from "@app/model/dataset/dataset-search.model";

export type FacetItemNode = {
  children: FacetItemNode[];
  item: CriteriaValue;
  label: string;
  percent: number;
  count: number;
}

export type FacetItemFlatNode = {
  item: CriteriaValue;
  label: string;
  level: number;
  percent: number;
  count: number;
  expandable: boolean;
}

export type SelectionStatus = 'EXPLICITLY_SELECTED' | 'IMPLICITLY_SELECTED' | 'NOT_SELECTED';

export class FacetTreeState {
  compactedSelection: Map<string, CriteriaValue> = new Map();
  expandedSelection: Map<string, CriteriaValue> = new Map();
  parentMap: Map<string, CriteriaValue> = new Map();
  childMap: Map<string, CriteriaValue[]> = new Map();

  isSelected(item: CriteriaValue): boolean {
    return this.expandedSelection.has(criteriaKey(item));
  }

  ancestorsOrSelf(item: CriteriaValue): CriteriaValue[] {
    const ancestors = new Array<CriteriaValue>();
    let ancestor: CriteriaValue | undefined = item;
    while(ancestor) {
      ancestors.push(ancestor);
      ancestor = this.parentMap.get(criteriaKey(ancestor));
    }
    return ancestors;
  }

  ancestorsOf(item: CriteriaValue): CriteriaValue[] {
    const key = criteriaKey(item);
    if(this.parentMap.has(key)) {
      return this.ancestorsOrSelf(this.parentMap.get(key)!);
    } else {
      return [];
    }
  }
  private childrenOf(item: CriteriaValue): CriteriaValue[] {
    return this.childMap.get(criteriaKey(item)) || [];
  }

  descendantsOf(item: CriteriaValue): CriteriaValue[] {
    return this.childrenOf(item).concat(this.childrenOf(item).flatMap((x) => this.descendantsOf(x)));
  }

  private siblingsOf(item: CriteriaValue): CriteriaValue[] {
    const parent = this.parentMap.get(criteriaKey(item));
    if(parent === undefined) {
      return []
    }
    const children = this.childrenOf(parent);
    return children.filter(s => criteriaKey(s) !== criteriaKey(item));
  }

  getSelectionState(item: CriteriaValue): SelectionStatus {
    const key = criteriaKey(item);
    if (this.compactedSelection.has(key)) {
      return "EXPLICITLY_SELECTED"
    } else {
      if (this.expandedSelection.has(key)) {
        return "IMPLICITLY_SELECTED"
      } else {
        return "NOT_SELECTED"
      }
    }
  }

  toggleItem(item: CriteriaValue): CriteriaValue[] {
    const state = this.getSelectionState(item);
    switch(state) {
      case "EXPLICITLY_SELECTED":
        this.unselectExplicit(item);
        break;

      case "IMPLICITLY_SELECTED":
        this.unselectImplicit(item);
        break;

      case "NOT_SELECTED":
        this.select(item);
        break;
    }
    return [...this.compactedSelection.values()];
  }

  isPartiallySelected(item: CriteriaValue): boolean {
    return !this.expandedSelection.has(criteriaKey(item)) && this.descendantsOf(item).some(d => this.expandedSelection.has(criteriaKey(d))) || false;
  }

  setFacet(facet: FacetTree): void {
    this.parentMap.clear();
    this.childMap.clear();

    facet.tree.forEach(node => this.fillRelationMaps(node, undefined));

    const oldSelection = new Set(this.expandedSelection.keys());
    this.expandedSelection.clear();
    this.compactedSelection.clear();
    facet.tree.forEach(root => this.projectSelection(root.item, oldSelection))

  }

  setSelection(values: CriteriaValue[]): void {
    this.expandedSelection.clear();
    this.compactedSelection.clear();
    values.forEach(v => {
      this.compactedSelection.set(criteriaKey(v), v);
      this.expandedSelection.set(criteriaKey(v), v);
      this.descendantsOf(v).forEach(d => this.expandedSelection.set(criteriaKey(d),d));
    });
  }

  private fillRelationMaps(node: FacetItemNode, parent: CriteriaValue | undefined) {
    const nodeKey = criteriaKey(node.item);
    if(parent !== undefined) {
      this.parentMap.set(nodeKey, parent);
    }
    const childNodes = node.children;
    this.childMap.set(nodeKey, childNodes.map(c => c.item));
    childNodes.forEach(c => this.fillRelationMaps(c, node.item));
  }

  private projectSelection(item: CriteriaValue, oldSelection: Set<string>) {
    const key = criteriaKey(item);
    const children = this.childrenOf(item);
    if(children.length > 0) {
      children.forEach(child => this.projectSelection(child, oldSelection));
      if(children.every(child => this.expandedSelection.has(criteriaKey(child)))) {
        this.compactedSelection.set(key, item);
        this.expandedSelection.set(key, item);
        children.forEach(child => this.compactedSelection.delete(criteriaKey(child)));
      }
    } else {
      if(oldSelection.has(key)) {
        this.compactedSelection.set(key, item);
        this.expandedSelection.set(key, item);
      }
    }
  }

  private unselectExplicit(item: CriteriaValue) {
    const key = criteriaKey(item);
    this.compactedSelection.delete(key);
    this.expandedSelection.delete(key);
    this.descendantsOf(item).forEach(d => this.expandedSelection.delete(criteriaKey(d)));
  }

  private unselectImplicit(item: CriteriaValue) {
    const ancestorsOrSelf = this.ancestorsOrSelf(item);
    ancestorsOrSelf.forEach( x => {
      const ancestorKey = criteriaKey(x);
      if(this.expandedSelection.has(ancestorKey)) {
        this.expandedSelection.delete(ancestorKey);
        this.compactedSelection.delete(ancestorKey);
        const siblings = this.siblingsOf(x);
        siblings.forEach(s => {
          const siblingKey = criteriaKey(s);
          if(this.expandedSelection.has(siblingKey)) {
            this.compactedSelection.set(siblingKey, s);
          }
        });
      }
    });
  }

  private select(item: CriteriaValue) {
    this.expandedSelection.set(criteriaKey(item), item);
    this.descendantsOf(item).forEach(d => this.expandedSelection.set(criteriaKey(d),d));
    const explicitlySelected = this.refreshAncestorsOfNewlySelected(item);
    this.compactedSelection.set(criteriaKey(explicitlySelected), explicitlySelected);
    this.expandedSelection.set(criteriaKey(explicitlySelected), explicitlySelected);
    this.descendantsOf(explicitlySelected).forEach(d => this.compactedSelection.delete(criteriaKey(d)));
  }

  private refreshAncestorsOfNewlySelected(item: CriteriaValue) {
    let explicitlySelected = item;
    for (const ancestor of this.ancestorsOf(item)) {
      this.expandedSelection.delete(criteriaKey(ancestor));
      if (this.childrenOf(ancestor).every(c => this.isSelected(c))) {
        explicitlySelected = ancestor;
      }
    }
    return explicitlySelected;
  }
}
