import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '@app/store';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {selectDatasetsIds} from "@app/store/basket/basket-dataset.selectors";
import {addAllDatasets, addDataset, removeAllDatasets, removeDataset} from "@app/store/basket/basket-dataset.actions";
import {DatasetSearchResponse, DatasetSearchResult, SuggestionSelection} from "@app/model/dataset/dataset-search.model";

@Component({
  selector: 'app-datasets-search-results',
  templateUrl: './datasets-search-results.component.html',
  styleUrl: './datasets-search-results.component.scss',
})
export class DatasetsSearchResultsComponent implements OnInit, OnDestroy {

  @Input()
  searchResponse!: DatasetSearchResponse;

  @Input()
  showResultsDetails: boolean = false;

  @Input()
  isLoading: boolean = true;

  @Output()
  suggestionSelection = new EventEmitter<SuggestionSelection>();

  onDestroy$ = new Subject<void>();

  basketIds = new Set();

  get resultAmountDisplay(): string {
    if (this.searchResponse.totalDocCount === 1) {
      return '1 result found';
    } else {
      if (this.searchResponse.totalDocCount > this.searchResponse.maxDocCount) {
        return `${this.searchResponse.totalDocCount} results found (${this.searchResponse.maxDocCount} displayed)`;
      } else {
        return `${this.searchResponse.totalDocCount} results found`;
      }
    }
  }

  get areAllResultsInBasket(): boolean {
    return this.searchResponse.results.every(dataset => this.basketIds.has(dataset.id));
  }
  set areAllResultsInBasket(toggle: boolean) {
    if (toggle) {
      this.addAllToBasket(this.searchResponse.results);
    } else {
      this.removeAllFromBasket(this.searchResponse.results);
    }
  }

  constructor(private store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
      this.store.select(selectDatasetsIds()).pipe(takeUntil(this.onDestroy$))
      .subscribe((ids) => {
        this.basketIds = new Set(ids);
      })
  }

  onDatasetToggled(dataset: DatasetSearchResult): void {
    if (!this.basketIds.has(dataset.id)) {
      this.addToBasket(dataset);
    } else {
      this.removeFromBasket(dataset);
    }
  }

  private addToBasket(dataset: DatasetSearchResult): void {
    this.store.dispatch(addDataset({ dataset }));
  }

  private addAllToBasket(datasets: DatasetSearchResult[]): void {
    this.store.dispatch(addAllDatasets({ datasets }));
  }

  private removeFromBasket(dataset: DatasetSearchResult): void {
    this.store.dispatch(removeDataset({ dataset }));
  }

  private removeAllFromBasket(datasets: DatasetSearchResult[]): void {
    this.store.dispatch(removeAllDatasets({ datasets }));
  }

  onSuggestionSelection(suggestionSelection: SuggestionSelection): void {
    this.suggestionSelection.emit(suggestionSelection);
  }
}
