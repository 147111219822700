import { SubCompartmentDto } from '@app/api/__generated__/model/subCompartmentDto';
import { SUB_COMPARTMENTS } from '../models/sub-compartment.model';
import {FilterOption} from "@app/modules/ui/legacy/filters-group/filters-group.model";

export const mapSubCompartmentToFilterOption = (
  sc: SubCompartmentDto
): FilterOption => ({
  key: sc,
  value: SUB_COMPARTMENTS.get(sc) ?? sc,
});
