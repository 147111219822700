import { Pipe, PipeTransform } from '@angular/core';
import {
  contributionTypeLabel,
  ContributionTypeName
} from "@app/model/dataset/contribution.model";


@Pipe({
  name: 'contributionTypeLabel',
})
export class ContributionTypeLabelPipe implements PipeTransform {
  transform(value: ContributionTypeName): string {
    return contributionTypeLabel(value);
  }
}
