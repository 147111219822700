import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {DatasetSearchApiService} from "@app/services/datasets/api/dataset-search-api.service";
import {
  DatasetSearchResultMapperService
} from "@app/services/datasets/dataset-search-result/dataset-search-result-mapper.service";
import {DatasetSearchResponse} from "@app/model/dataset/dataset-search.model";
import {ParamMap} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class DatasetSearchService {
  constructor(
    private datasetSearchApiService: DatasetSearchApiService,
    private datasetSearchResultMapper: DatasetSearchResultMapperService
  ) {}

  searchDatasets(paramMap: ParamMap
  ): Observable<DatasetSearchResponse> {
    return this.datasetSearchApiService
      .searchDatasets(
        paramMap.get("q") || undefined,
        paramMap.getAll("geography"),
        paramMap.getAll("geoContinent"),
        paramMap.getAll("geoCountry"),
        paramMap.getAll("geoProvince"),
        paramMap.getAll("database"),
        paramMap.getAll("activityType"),
        paramMap.getAll("unit"),
        paramMap.getAll("isicSection"),
        paramMap.getAll("isicDivision"),
        paramMap.getAll("isicGroup"),
        paramMap.getAll("isicCode"),
        paramMap.getAll("cpcSection"),
        paramMap.getAll("cpcDivision"),
        paramMap.getAll("cpcGroup"),
        paramMap.getAll("cpcMainClass"),
        paramMap.getAll("cpcSubClass"))
      .pipe(
        map((datasets) => this.datasetSearchResultMapper.fromResults(datasets))
      );
  }
}
