import * as uuid from 'uuid';
import {Injectable} from '@angular/core';
import {
  ExchangeWithContributionMapperService
} from "@app/pages/dataset-details/exchange/mappers/exchange-with-contribution-mapper.service";
import {EmbeddedProduct} from "@app/model/dataset/embedded.product.model";
import {
  Assessment,
  BioExchange,
  Exchange,
  ExchangeRecord,
  ProductExchange,
  TechnoExchange
} from "@app/model/dataset/exchange.model";
import {CoProduct, mapContributionToAssessment, mapEmbeddedToAssessment} from "@app/model/dataset/dataset.model";

@Injectable({
  providedIn: 'root',
})
export class ExchangeRecordMapperService {
  constructor(private readonly exchangeExchangeWithContributionMapperService: ExchangeWithContributionMapperService) {
  }

  public getReferenceProductRecord(product: ProductExchange,
                                   methodIndicatorId: string | undefined, embeddedProducts: EmbeddedProduct[]): ExchangeRecord {
    return {
      ...this.mapExchangeToRecord(product, methodIndicatorId, embeddedProducts),
      allocation: product.allocation,
      warnings: [],
      showBar: false
    };
  }

  public getCoProductRecord(product: CoProduct,
                            methodIndicatorId: string | undefined, embeddedProducts: EmbeddedProduct[]): ExchangeRecord {
    const allocatedEmbedded = embeddedProducts.map(ep => {
      const allocation = product.allocation || 0;
      return {...ep, amount: ep.amount * allocation / 100}
    });
    return {
      ...this.mapExchangeToRecord(product, methodIndicatorId, allocatedEmbedded),
      id: product.id,
      allocation: product.allocation,
      warnings: [],
      showBar: true
    };
  }

  public getActivityCoProductRecords(product: ProductExchange | undefined, methodIndicatorId: string | undefined, embeddedProducts: EmbeddedProduct[]): ExchangeRecord[] {
    if (!product) return [];
    const coproducts = this.getCoproducts(product, methodIndicatorId, embeddedProducts);
    const allocatedEmbedded = embeddedProducts.map(ep => {
      const allocation = product.allocation || 0;
      return {...ep, amount: ep.amount * allocation / 100}
    });
    return [{
      ...this.getReferenceProductRecord(product, methodIndicatorId, allocatedEmbedded),
      showBar: true
    }].concat(coproducts);
  }

  public getCoproducts(product: ProductExchange, methodIndicatorId: string | undefined, embeddedProducts: EmbeddedProduct[]): ExchangeRecord[] {
    return product.coProducts.map(cp => this.getCoProductRecord(cp, methodIndicatorId, embeddedProducts));
  }

  getTechnosphereRecords(fromTechnosphere: TechnoExchange[], methodIndicatorId: string | undefined): ExchangeRecord[] {
    return fromTechnosphere.map(te => this.getTechnosphereRecord(te, methodIndicatorId));
  }

  getBiosphereRecords(bioExchanges: BioExchange[], methodIndicatorId: string | undefined): ExchangeRecord[] {
    return bioExchanges.map(bioExchange => this.getBiosphereRecord(bioExchange, methodIndicatorId));
  }

  getTechnosphereRecord(technoExchange: TechnoExchange, methodIndicatorId: string | undefined): ExchangeRecord {
    return {
      ...this.mapExchangeToRecord(technoExchange, methodIndicatorId, technoExchange.embeddedProducts),
      id: technoExchange.id || uuid.v4(),
      navigable: !!technoExchange.id,
      warnings: technoExchange.warnings,
      showBar: true
    };
  }

  private getBiosphereRecord(bioExchange: BioExchange, methodIndicatorId: string | undefined): ExchangeRecord {
    return {
      ...this.mapExchangeToRecord(bioExchange, methodIndicatorId, []),
      notDataset: true,
      compartment: bioExchange.compartment,
      subCompartment: bioExchange.subCompartment,
      warnings: [],
      showBar: true
    };
  }

  private mapExchangeToRecord(exchange: Exchange, methodIndicatorId: string | undefined, embeddedProducts: EmbeddedProduct[]) {
    return {
      id: exchange.displayId, // TODO check id vs displayId
      navigable: true,
      name: exchange.name,
      amount: exchange.amount,
      unit: exchange.unit,
      comment: exchange.comment,
      assessments: this.getAssessments(exchange, methodIndicatorId, embeddedProducts),
    }
  }

  private getAssessments(exchange: Exchange,
                         methodIndicatorId: string | undefined,
                         embeddedProducts: EmbeddedProduct[]): Assessment[] {
    if (!methodIndicatorId) return [];
    const contributions = this.exchangeExchangeWithContributionMapperService.mapToDisplayedDirectContributions(exchange.contributions, methodIndicatorId);
    return [...contributions.map(c => mapContributionToAssessment(c, methodIndicatorId)), ...embeddedProducts.map(ep => mapEmbeddedToAssessment(ep))];
  }
}
