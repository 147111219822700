import {Component, Input} from '@angular/core';
import {Dataset} from '@app/model/dataset/dataset.model';
import {HistoryDetail, HistoryStatus} from "@app/model/dataset/history.models";
import {take} from "rxjs";
import {DatasetService} from "@app/services/datasets/dataset.service";

@Component({
  selector: 'app-history-tab[dataset]',
  templateUrl: './history-tab.component.html',
  styles: [],
})
export class HistoryTabComponent {
  private _dataset!: Dataset;
  historyDetails: HistoryDetail[] = [];

  @Input()
  set dataset(dataset: Dataset) {
    this._dataset = dataset;
    this.historyDetails = this.getHistoryDetails()
    this.compareDataset = undefined;
  }

  get dataset(): Dataset {
    return this._dataset;
  }

  compareDataset?: Dataset = undefined;

  constructor(
    private datasetService: DatasetService
  ) {
  }

  getHistoryDetails(): HistoryDetail[] {
    let historyDetails:HistoryDetail[] = [];
    if (this.hasNoEquivalentChild) {
      historyDetails = historyDetails.concat(this.dataset.history.children);
    }
    historyDetails = historyDetails.concat(this.dataset.history.equivalentVersions.reverse());
    if (this.hasNoEquivalentParent) {
      historyDetails= historyDetails.concat(this.dataset.history.parents);
    }
    return historyDetails;
  }

  get hasNoEquivalentParent(): boolean {
    return this.hasNoEquivalentRelations(this.dataset.history.parents);
  }

  get hasNoEquivalentChild(): boolean {
    return this.hasNoEquivalentRelations(this.dataset.history.children);
  }

  private hasNoEquivalentRelations(details : HistoryDetail[]): boolean {
    return details
      .map(p => p.status)
      .filter(s => s !== HistoryStatus.INHERIT).length > 0;
  }


  doCompareWith(datasetId: string): void {
    this.datasetService
      .getDataset(datasetId).pipe(take(1))
      .subscribe(dataset => this.compareDataset = dataset);
  }
}
